import React, { Component } from 'react'
import { connect } from 'react-redux'
import { removeone,emptycart } from '../redux/actions';
import { NavLink, withRouter } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
import SweetAlert from 'sweetalert2-react';
import ReactPlayer from "react-player";
class Cart extends Component {
  constructor () {
    super()
    this.state = {
        nowDate : ''
    }
  }

  componentDidMount() {
    let no = new Date()  
    let codeD = "AVN"+no.getFullYear()+no.getMonth()+no.getDay()+"-"+no.getHours()+no.getMinutes()+no.getSeconds()
    this.setState({nowDate:codeD})  
    document.title = "Avinet | Cart"
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.setAttribute("id", "naon");
    script2.src = "/js/main.js";
    script2.async = true;
    document.body.appendChild(script2);
    // console.log(this.props.cart)
}

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();
    var todelete2 = document.getElementById('naon');
    todelete2.remove();
}

handleRemoveOne=(par)=>{
   this.props.removeone(JSON.stringify(this.props.cart) , JSON.stringify(par))
}

handleDownload = async ()=>{
    var imageIds='';
    if(this.props.cart.length > 0){
        var imageIds = await this.getImageId(this.props.cart)
        var videoIds = await this.getVideoId(this.props.cart)
        // console.log(imageIds)
        // console.log('==========')
        // console.log(videoIds)
        // var valueArr = await this.props.cart.map((item) => (item.imageid));
        window.location.href = `${global.apiUrl}image/imagefordownload?id=${imageIds}&uid=${this.props.userid}&code=${this.state.nowDate}&arsipsId=${imageIds}&videosId=${videoIds}`
       this.props.emptycart()
    }else{
        this.setState({isEmptyCart:true})
    }
}

getVideoId=(arr)=>{
    var hasil = arr.filter((r)=>{
        if(r.isVideo == true){
          return r
        }
      })
    return hasil.map((item) => (item.imageid));
}
getImageId=(arr)=>{
    var hasil = arr.filter((r)=>{
        if(r.isVideo == false){
          return r
        }
      })
    return hasil.map((item) => (item.imageid));
}

  render () {
    return (
      <div>
        {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
       
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                      
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><NavLink to="index.html">Home</NavLink><span> -</span> &nbsp;</li>
                            <li>Save</li>
                        </ul>
                    </div>
                </div>  
            </div> 


            <div className="cart-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="cart-page-top table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <td className="cart-form-heading"></td>
                                            <td className="cart-form-heading">Image</td>
                                            {/* <td className="cart-form-heading">Price</td> */}
                                            {/* <td className="cart-form-heading">Quantity</td> */}
                                            {/* <td className="cart-form-heading">Total</td> */}
                                            <td className="cart-form-heading"></td>
                                        </tr>
                                    </thead>
                                    <tbody id="quantity-holder">
                                            {
                                                this.props.cart.map((r,i)=>{
                                                    if(r.isVideo){
                                                        return(
                                                            <tr key={i}>
                                                            <td className="cart-img-holder">
                                                                <NavLink to="#">
                                                                <ReactPlayer
                                                                    width="100%"
                                                                    height="100%"
                                                                    url={r.imageurl}
                                                                    // controls
                                                                    playing={this.props.isPlay}
                                                                    />
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                <h3><NavLink to="#">{r.imagename}</NavLink></h3>
                                                            </td>
                                                            {/* <td className="amount">$59.00</td> */}
                                                            {/* <td className="quantity">
                                                                <div className="input-group quantity-holder">
                                                                    <input type="text" name='quantity' className="form-control quantity-input" value="1" placeholder="1"/>
                                                                    <div className="input-group-btn-vertical">
                                                                        <button className="btn btn-default quantity-plus" type="button"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                                        <button className="btn btn-default quantity-minus" type="button"><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                            {/* <td className="amount">$59.00</td> */}
                                                            <td className="dismiss"><NavLink to="#"><i className="fa fa-times" aria-hidden="true" onClick={()=>this.handleRemoveOne(r)}></i></NavLink></td>
                                                        </tr>
                                                        )

                                                        
                                                    }else{
                                                        return(
                                                            <tr key={i}>
                                                            <td className="cart-img-holder">
                                                                <NavLink to="#"><img src={r.imageurl} alt="cart" className="img-responsive"/></NavLink>
                                                            </td>
                                                            <td>
                                                                <h3><NavLink to="#">{r.imagename}</NavLink></h3>
                                                            </td>
                                                            {/* <td className="amount">$59.00</td> */}
                                                            {/* <td className="quantity">
                                                                <div className="input-group quantity-holder">
                                                                    <input type="text" name='quantity' className="form-control quantity-input" value="1" placeholder="1"/>
                                                                    <div className="input-group-btn-vertical">
                                                                        <button className="btn btn-default quantity-plus" type="button"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                                        <button className="btn btn-default quantity-minus" type="button"><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                            {/* <td className="amount">$59.00</td> */}
                                                            <td className="dismiss"><NavLink to="#"><i className="fa fa-times" aria-hidden="true" onClick={()=>this.handleRemoveOne(r)}></i></NavLink></td>
                                                        </tr>
                                                        )
                                                            
                                                        
                                                    }
                                                })
                                            }
                                       
                                       
                                    </tbody>
                                </table>
                                <div className="update-button">
                                     {/* <span style={{marginRight:200}}>Kode Download {this.state.nowDate}</span>  */}
                                     <button onClick={this.handleDownload} className="btn-apply-coupon disabled" type="submit" value="Login">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="cart-page-bottom-left">
                                <h2>Coupon</h2>
                                <form method="post">
                                    <input type="text" id="coupon" name="coupon" placeholder="Enter your coupon code if you have one"/>
                                    <button value="Coupon" type="submit" className="btn-apply-coupon disabled">Apply Coupon</button>
                                </form>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="cart-page-bottom-right">
                                <h2>Total</h2>
                                <h3>Subtotal<span>$ 118.00</span></h3>
                                <h3>Total<span>$ 118.00</span></h3>
                                <div className="proceed-button">
                                     <button className="btn-apply-coupon disabled" type="submit" value="Login">Proceed To Checkout</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <SweetAlert
                show={this.state.isEmptyCart}
                title="Gagal"
                confirmButtonText="Close"
                type="warning"
                text='Keranjang Anda Masih Kosong !'
                showCancelButton={false}
                onConfirm={()=>this.setState({isEmptyCart:false})}/>

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile
  const { cart } = state.cart
  return { userName, isLogin, cart, userid }
}

export default connect(
  mapStateToProps,
  {removeone,emptycart}
)(withRouter(Cart))
// export default withRouter(Detail);
// export default withRouter(Cart)
