import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import MainBanner from "../components/MainBanner";
// import TrendingProductArea from "../components/TrendingProductArea";
// import Author from "../components/Author";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import AllPicture from "../components/AllPicture";
import Card370 from "../components/Card370";
import Card370two from "../components/Card370two";
import Card370twoVideo from "../components/Card370twoVideo";
import SearchInputHome from "../components/SearchInputHome";
import { Animated } from "react-animated-css";
import Modal from "react-modal";
import Login from "./Login";
import { closeswallogin } from "../redux/actions";
import SweetAlert from "sweetalert2-react";
import VideoPlayerPopUp from "../components/VideoPlayerPopUp";
import FilterPopUp from '../components/FilterPopUp';
import Loader from "../components/Loader";
import { slide as Menu } from "react-burger-menu";
import Dropdown from "react-dropdown-modal";
import "react-dropdown-modal/lib/dropdown.css";





class Home extends Component {
  constructor() {
    super();
    this.state = {
      listImage: [],
      listByCat: [],
      listByCat2: [],
      listCat: [],
      activeCat: 2,
      listAuthorImage: [],
      searchtext: "",
      totalImage: 0,
      loading: false,
      isAnimated: true,
      modalIsOpen: true,
      numRand: 1,
      showPopUp: true,
      myrandombg: "",
      openFilter: false,
      tooltip: false,
      xpos: 0,
      ypos: 0,
      listTags: [],
      catFoto:false,
      catVideo:false,
      catDesign:false,
      searchFilterText:'',
      searchFilterTags:'',
      startdate:'',
      enddate:'',
      txtSort:'',
      toShow:'foto',
      catVideo:'',
      ww:0,
      ml:0,
      totalPage:0,
      page:1
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    window.scrollTo(0, 0);
    this.fetchListByCat();
    this.fetchListCat();
    this.fetchListPhoto();
    this.handleRandom();
    this.fetchListTag();
    document.title = "Avinet | Home";
    const script = document.createElement("script");
    script.setAttribute("id", "naon");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    // const script2 = document.createElement('script')
    // script2.setAttribute('id', 'geser')
    // script2.src = '/js/maingeser.js'
    // script2.async = true
    // document.body.appendChild(script2)

    //console.log(global.apiUrl)

    //setTimeout(()=>{this.setState({loading:false})},4000)
  }

  componentWillUnmount() {
    var todelete = document.getElementById("naon");
    todelete.remove();

    // var todelete2 = document.getElementById("geser");
    // todelete2.remove();
  }

  shouldComponentUpdate(np, ns) {
    if (this.state.listImage !== ns.listImage) {
      return true;
    }
    if (this.state.totalPage !== ns.totalPage) {
      return true;
    }
    return true;
  }

  handleOpenFilter = () => {
    this.setState({ openFilter: !this.state.openFilter,
      ml:280
    });

    if(this.state.ml > 0){
      this.setState({ml:0})
    }

    this.handleClosePopUpSatu()
    // if (this.state.openFilter) {
    //   this.setState({ openFilter: false });
    // } else {
    //   this.setState({ openFilter: true });
    // }
  };

  // fetchListImage = async () => {
  //   try {
  //     const response = await fetch(`${global.apiUrl}image/listimage`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const data = await response.json();
  //     if (data.success == true) {
  //       this.setState({ listImage: data.detail });
  //       this.forceUpdate();
  //     } else {
  //     }
  //   } catch (e) {
  //     console.log("error: ", e);
  //   }
  // };

  fetchListByCat = async () => {
    this.setState({ toShow:'foto' });
    try {
      // var urlFetch = `${global.apiUrl}image/listimage?l=20&uid=${this.props.userid}&foto=${this.state.catFoto}&video=${this.state.catVideo}&design=${this.state.catDesign}`
      const response = await fetch(
        `${global.apiUrl}image/listimage?c=${this.state.activeCat}&p=${this.state.page}&l=20&uid=${this.props.userid}&foto=${this.state.catFoto.toString()}&video=${this.state.catVideo.toString()}&design=${this.state.catDesign.toString()}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}&so=${this.state.txtSort}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        // console.log(data)
        this.setState({
          listByCat: data.detail,
          listByCat2: data.detail,
          totalImage: data.totalImage,
          totalPage:data.pagetotal
        });
        this.setState({ loading: false, showPopUp: true });
        //console.log(data.detail);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchListImageBasedOnSort = async () => {
    try {
      // var urlFetch = `${global.apiUrl}image/listimage?l=20&uid=${this.props.userid}&foto=${this.state.catFoto}&video=${this.state.catVideo}&design=${this.state.catDesign}`
      const response = await fetch(
        `${global.apiUrl}image/listimage?p=${this.state.page}&l=20&uid=${this.props.userid}&so=${this.state.txtSort}&c=${this.state.activeCat}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        // console.log(data)
        this.setState({
          listByCat: data.detail,
          listByCat2: data.detail,
          totalImage: data.totalImage,
          totalPage:data.pagetotal
        });
        this.setState({ loading: false, showPopUp: true });
        //console.log(data.detail);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectPage =  async page => {
    // this.setState({ page: page, activePage: page },()=>console.log(this.state.page))
    try {
      if(this.state.toShow == 'video'){
        await this.setState({ page: page, activePage: page },this.handleFetchVideo)
      }else{
        await this.setState({ page: page, activePage: page },this.fetchListByCat)
      }
      
    
    } catch (e) {
      console.log('error: ', e)
    }
  }

  fetchListCat = async () => {
    try {
      const response = await fetch(`${global.apiUrl}category/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listCat: data.data });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectCat = async cat => {
    // alert(cat)
    try {
      await this.setState({ isAnimated: false, totalPage:2, page:1 });
      await this.setState({ activeCat: cat, toShow:'foto' });
      const response = await fetch(
        `${global.apiUrl}image/listimage?c=${this.state.activeCat}&l=20&uid=${
          this.props.userid
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        // this.setState({ listByCat2: data.detail });
        setTimeout(() => {
          this.setState({ listByCat2: data.detail });
        }, 500);
        setTimeout(() => {
          this.setState({ isAnimated: true });
        }, 500);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleFetchVideo = async () => {
    try {
      let url = `${global.apiUrl}video/listvideo?p=${this.state.page}&uid=${this.props.userid}&c=${this.state.catVideo}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}&sort=${this.state.txtSort}`;
    
      // let url = `${global.apiUrl}video/listvideo?uid=${this.props.userid}&c=${this.state.catVideo}&sort=${this.state.txtSort}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        // console.log(data)
        this.setState({
          listByCat2: data.detail,
          totalPage:data.pagetotal
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  }

  fetchListPhoto = async () => {
    try {
      const response = await fetch(`${global.apiUrl}author/listimageauthor`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listAuthorImage: data.detail });
        // console.log(data.detail);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchListTag = async () => {
    try {
      const response = await fetch(`${global.apiUrl}image/alltagsfix`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listTags: data.data });
        // console.log(data.detail);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  listItemTag = (tag) => {
    return (
      tag.map((r,i)=>(
        <li onClick={()=>{
          console.log(r.tagName)
          // this.setState({tooltip:true})
        }} style={{cursor:'pointer'}}>{r.tagName}</li>
      ))
    )
  }

  handleAfterLike = async iid => {
    const myArray = this.state.listByCat;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listByCat: myArray });
  };

  handleAfterLike2 = async iid => {
    const myArray = this.state.listByCat2;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listByCat2: myArray });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleRandom = async () => {
    // this.setState({ numRand: Math.ceil(Math.random() * 3) });
    try {
      const response = await fetch(`${global.apiUrl}image/randombg`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ myrandombg: data.cover });
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handelClickVideoCategory = (catid) => {
    this.setState({
      toShow:'video', catVideo:catid,activeCat: catid,},this.handleFetchVideo)
  }

  handleFetchOnCheckBokFoto = (parfoto) =>{
    // this.setState({catFoto:parfoto},this.fetchListByCat)
    this.fetchListByCat()
  }
  handleFetchOnCheckBokVideo = (parfoto) =>{
    // this.setState({catVideo:parfoto},this.fetchListByCat)
    this.setState({toShow:'video'},this.handleFetchVideo)
  }
  handleFetchOnCheckBokDesign = (parfoto) =>{
    this.setState({catDesign:parfoto},this.fetchListByCat)
  }
  handleInput=(searchFilterText)=>{
    if(this.state.toShow == 'foto'){
      this.setState({searchFilterText},this.fetchListByCat)
    }else{
      this.setState({searchFilterText},this.handleFetchVideo)
    }    
    
  }
  handleConsoleTags = (searchFilterTags) => {
    if(this.state.toShow == 'foto'){
      this.setState({searchFilterTags},this.fetchListByCat)
    }else{
      this.setState({searchFilterTags},this.handleFetchVideo)
    }    
    // this.setState({searchFilterTags},this.fetchListByCat)
  }
  handleChangeDateStart = (startdate) => {
    // console.log(startdate)
    if(this.state.toShow == 'foto'){
      this.setState({startdate},this.fetchListByCat)
    }else{
      this.setState({startdate},this.handleFetchVideo)
    }    
    // this.setState({startdate},this.fetchListByCat)
  }
  handleChangeDateEnd = (enddate) => {
    if(this.state.toShow == 'foto'){
      this.setState({enddate},this.fetchListByCat)
    }else{
      this.setState({enddate},this.handleFetchVideo)
    }    
    // console.log(enddate)
    //this.setState({enddate},this.fetchListByCat)
  }

  handleSort=(txtSort)=>{
    if(this.state.toShow == 'foto'){
      this.setState({txtSort},this.fetchListImageBasedOnSort)
    }else{
      this.setState({txtSort},this.handleFetchVideo)
    }    
  }

  handleClosePopUpSatu = () => {
    this.setState({ showPopUp: false })
  }

  render() {
    // console.log(this.state.openFilter)
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpedd: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            //dots: true
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let pagePrev = this.state.page - 1
    let curPage = this.state.page
    let nextPage = this.state.page + 1
    let afterNextPage = nextPage + 1
    let beforePagePrev = pagePrev - 1

    return (
      <div style={{marginRight:this.state.ml,position:'relative',transition: 'all 0.5s ease'}}>
        {
          !this.props.isLogin && (
          <div style={{ position: "fixed", width: "100%", zIndex: 9999999 }}>
            <Login />
          </div>
        )
        }



       

       

       <FilterPopUp 
        openProps={this.state.openFilter} 
        closeFilter={()=>this.setState({openFilter:!this.state.openFilter,ml:0})}
        cekBokFoto={(par)=>this.handleFetchOnCheckBokFoto(par)}
        cekBokVideo={(par)=>this.handleFetchOnCheckBokVideo(par)}
        cekBokDesign={(par)=>this.handleFetchOnCheckBokDesign(par)}
        inputSearch={(txt)=>this.handleInput(txt)}
        tagsinput={(txt)=>this.handleConsoleTags(txt)}
        changeDateStart={(dd)=>this.handleChangeDateStart(dd)}
        changeDateEnd={(dd)=>this.handleChangeDateEnd(dd)}
        />

        {/* <VideoPlayerPopUp
          useridprops={this.props.userid}
          showPop={this.state.showPopUp}
          onClose={this.handleClosePopUpSatu}
        /> */}

        <div
          className="main-banner1-area"
          style={{
            backgroundImage: `url(${this.state.myrandombg})`,
            marginTop: 0,
            height: 300,
          }}
        >
          <div className="container">
            <div className="main-banner1-wrapper">
              {/* <h1 className="homeheadertext" style={{fontSize:50,fontWeight:'bold'}}>
                Search Here
              </h1> */}

              <SearchInputHome
                onChange={e => this.setState({ searchtext: e.target.value })}
                vall={this.state.searchtext}
              />
              {/* <div className="banner-search-area input-group">
                <input
                  className="form-control"
                  placeholder="Search Your Keywords . . ."
                  type="text"
                />
                <span className="input-group-addon">
                  <button type="submit">
                    <span className="glyphicon glyphicon-search" />
                  </button>
                </span>
              </div> */}
              <span className="homeheadertext">
                {/* <span>Gain access to over </span>
                {this.state.totalImage} images */}
              </span>
            </div>
          </div>
        </div>
        <div
          className="isotope-classNamees-tab isotop-box-btn frutigerFont listcategory"
          style={{ backgroundColor: "white", width: "100%" }}
        >
          <div
            className="page-controls-sorting"
            style={{ 
              // marginTop: -5, 
              padding: 0 }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle frutigerFont"
                type="button"
                data-toggle="dropdown"
                style={{border: '1px solid lightgray',
                  padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                SORT BY
                <i className="fa fa-angle-down" aria-hidden="true" />
              </button>
              <ul className="dropdown-menu frutigerFont">
                <li>
                  <NavLink to="#" className="frutigerFont" onClick={() => this.handleSort("newest")}>
                    NEWEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="frutigerFont" onClick={() => this.handleSort("oldest")}>
                    OLDEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="frutigerFont" onClick={() => this.handleSort("favorite")}>
                    FAVORITE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="frutigerFont" onClick={() => this.handleSort("download")}>
                    MOST DOWNLOAD
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          {/* <Link to={{
              pathname: '/product',
              state: { catid:'anjay' }
            }}> TestParam </Link> */}
          {this.state.listCat.map((r, i) => {
            if(r.categoryId == 2 || r.categoryId == 3 || r.categoryId == 6){
              return (
                <NavLink
                  key={i}
                  to="#"
                  data-filter="*"
                  className={
                    this.state.activeCat == r.categoryId ? "current" : "no"
                  }
                  onClick={() => this.handleSelectCat(r.categoryId)}
                >
                  {r.categoryName}
                </NavLink>
              );
            }else if(r.categoryId == 4 || r.categoryId == 5){
              return (
                <NavLink
                  key={i}
                  to="#"
                  data-filter="*"
                  className={
                    this.state.activeCat == r.categoryId ? "current" : "no"
                  }
                  onClick={() => this.handelClickVideoCategory(r.categoryId)}
                >
                  {r.categoryName}
                </NavLink>
              );
            }
           
          })}
          {/* <NavLink to="#" data-filter="*" className="current">
                All
              </NavLink>
              <NavLink to="#" data-filter=".wordpress">
                WordPress
              </NavLink>
              <NavLink to="#" data-filter=".joomla">
                Joomla
              </NavLink>
              <NavLink to="#" data-filter=".plugins">
                Plugins
              </NavLink>
              <NavLink to="#" data-filter=".component">
                Component
              </NavLink>
              <NavLink to="#" data-filter=".psd">
                PSD
              </NavLink>{" "} */}

          <div
            onClick={this.handleOpenFilter}
            className="page-controls-sorting"
            style={{
              //  marginTop: -5, 
               float: "right" }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle frutigerFont"
                type="button"
                data-toggle="dropdown"
                style={{border: '1px solid lightgray',
                  padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                FILTER
              </button>
            </div>
          </div>
        </div>
        <Animated
          animationIn=""
          animationOut="fadeOut"
          isVisible={this.state.loading}
        >
          <Loader />
        </Animated>

        {
          //---------------
        }

        {/* <div className="trending-products-area bg-secondary section-space-default" style={{paddingBottom:30,paddingTop:20}}> */}
        {/* <div className="container">
            <h2 className="title-default frutigerFont">File Terbaru</h2>
          </div> */}
        {/* <div className="container"> */}
        {/* <div className="row"> */}
        {/* <div
                className="fox-carousel nav-control-middle margin-left-right"
                data-loop="true"
                data-items="3"
                data-margin="0"
                data-autoplay="true"
                data-autoplay-timeout="10000"
                data-smart-speed="2000"
                data-dots="false"
                data-nav="true"
                data-nav-speed="false"
                data-r-x-small="1"
                data-r-x-small-nav="true"
                data-r-x-small-dots="false"
                data-r-x-medium="2"
                data-r-x-medium-nav="true"
                data-r-x-medium-dots="false"
                data-r-small="2"
                data-r-small-nav="true"
                data-r-small-dots="false"
                data-r-medium="3"
                data-r-medium-nav="true"
                data-r-medium-dots="false"
                data-r-large="3"
                data-r-large-nav="true"
                data-r-large-dots="false"
              > */}

        {/* <Slider {...settings}>
                {this.state.listByCat.slice(0, 5).map((r, i) => {
                  return (
                    <Card370
                      key={i}
                      title={r.arsiTitle}
                      catName={r.catName}
                     // tags={r.tags}
                      // author={r.author}
                      download={r.download}
                      like={r.likeCount}
                      imgurl={r.cover}
                      urldetail={`detail/${r.arsiId}`}
                      userPict={r.authorPict}
                      toDetail={() =>
                        this.props.history.push("detail/" + r.arsiId)
                      }
                      imid={r.arsiId}
                      usid={this.props.userid}
                      actionAfterLike={() => this.handleAfterLike(r.arsiId)}
                      image_id={r.arsiCat}
                      youLike={r.youLike}
                    />
                  );
                })}
              </Slider> */}

        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}

        {
          //---------------
        }

        <div
          // className="newest-products-area section-space-default" 
          style={{ 
            paddingTop: 40,
            display:'flex',
            flexWrap:'wrap',
            flexDirection:'row',
          }}
          className='card-container'
        >
          {/* <div
           className="container"
          > */}
            {/* <h2 className="title-default">Let's Check Out Our Newest Release Prodcuts</h2> */}
          {/* </div> */}
          {/* <div 
          className='card-container'
          className="container" id="isotope-container"
          > */}
            {/* <Animated
              animationIn="bounceInLeft"
              animationOut="bounceOutRight"
              isVisible={this.state.isAnimated}
            > */}
              {/* <div 
              className="row featuredContainer"
              > */}
                {this.state.toShow == 'foto' && this.state.listByCat2.map((r, i) => {
                  return (
                    <Card370two
                      key={i}
                      title={r.arsiTitle}
                      catName={r.catName}
                      //author={r.author}
                      download={r.download}
                      like={r.likeCount}
                      urldetail={`detail/${r.arsiId}`}
                      // toDetail={() =>
                      //   this.props.history.push("detail/" + r.arsiId)
                      // }
                      userPict={r.authorPict}
                      imgurl={r.cover}
                      imid={r.arsiId}
                      usid={this.props.userid}
                      actionAfterLike={() => this.handleAfterLike2(r.arsiId)}
                      image_id={r.arsiCat}
                      youLike={r.youLike}
                    />
                  );
                })}

                {this.state.toShow == 'video' && this.state.listByCat2.map((r, i) => {
                  return (
                    <Card370twoVideo
                      key={i}
                      title={r.arsiTitle}
                      catName={r.catName}
                      //author={r.author}
                      download={r.download}
                      like={r.likeCount}
                      urldetail={`detail-video/${r.arsiId}`}
                      // toDetail={() =>
                      //   this.props.history.push("detail-video/" + r.arsiId)
                      // }
                      userPict={r.authorPict}
                      imgurl={r.cover}
                      imid={r.arsiId}
                      usid={this.props.userid}
                      actionAfterLike={() => this.handleAfterLike2(r.arsiId)}
                      image_id={r.arsiCat}
                      youLike={r.youLike}
                    />
                  );
                })}

              {/* </div> */}
            {/* </Animated> */}
            {/* <div className="container">
              <ul className="btn-area">
                <li className="hvr-bounce-to-right">
                  <NavLink to="#">All New Items</NavLink>
                </li>
                <li className="hvr-bounce-to-left">
                  <NavLink to="#">Popular Items</NavLink>
                </li>
              </ul>
            </div> */}
          {/* </div> */}
        </div>
        {
          //=========== author
        }

        <div className="">
          <div className="">
            <div id="" className="">
              <div className="">
                {/* <div style={{ flexDirection: "row" }}>
                  {this.state.listAuthorImage.map((r, i) => {
                    return (
                      <div key={i} style={{ display: "inline" }}>
                        <NavLink  to="#">
                          <img
                            style={{
                              height: 100,
                              width: 100,
                              padding: 1,
                              borderColor: "grey",
                              borderWidth: "1px",
                            }}
                            src={
                              r.userImageURL
                              //`r.userImageURL`
                            }
                            alt=""
                          />
                        </NavLink>
                      </div>
                    );
                  })} */}

                {/*   <li style={{float:'left'}}><NavLink to="#"><img src="img/banner/3.jpg" alt="" /></NavLink></li>
                          <li><NavLink to="#"><img src="img/banner/4.jpg" alt="" /></NavLink></li>
                         <li><NavLink to="#"><img src="img/banner/5.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/6.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/7.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/8.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/9.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/2.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/3.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/5.jpg" alt="" /></NavLink></li>
                            <li><NavLink to="#"><img src="img/banner/5.jpg" alt="" /></NavLink></li>
                           
                            <li><NavLink to="#"><img src="img/banner/5.jpg" alt="" /></NavLink></li>
                           
                            <li><NavLink to="#"><img src="img/banner/5.jpg" alt="" /></NavLink></li> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        {
          //==================
        }

        {/* <SweetAlert
          show={this.state.isLoginFailed}
          title="Login Gagal"
          confirmButtonText="Close"
          type="warning"
          text="Data Harus Disii Semua"
          showCancelButton={false}
          onConfirm={() => this.setState({ isLoginFailed: false })}
        /> */}





                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <ul className='mypaging'>
                              

                              {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    className="frutigerFont"
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {'<'}
                                  </NavLink>
                                </li>
                              )}

                              {
                                curPage > 3 && 
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    className="frutigerFont"
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(1)
                                    }
                                  >
                                    {'1'}
                                  </NavLink>
                                </li> 
                              }

                              {
                                curPage > 3 && "..."
                              }

                              {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                  className="frutigerFont"
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {pagePrev}
                                  </NavLink>
                                </li>
                              )}

                              <li style={{ color: 'red' }}>
                                <NavLink
                                  to='#'
                                  className='active frutigerFont'
                                  style={{
                                    fontSize:25,
                                    color:
                                      curPage == this.state.activePage && 'rgb(2,65,130)'
                                  }}
                                  onClick={() => this.handleSelectPage(curPage)}
                                >
                                  {curPage}
                                </NavLink>
                              </li>
                              {nextPage < this.state.totalPage && (
                                <li>
                                  <NavLink
                                  className="frutigerFont"
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {nextPage}
                                  </NavLink>
                                </li>
                              )}
                              
                              {
                                (this.state.totalPage !== curPage && (curPage + 1 !== this.state.totalPage)) && "..."
                              }
                              {
                                this.state.totalPage !== curPage && 
                                <li>
                                <NavLink
                                className="frutigerFont"
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(this.state.totalPage)
                                  }
                                >
                                  {this.state.totalPage}
                                </NavLink>
                              </li>
                              }

                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                  className="frutigerFont"
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {'>'}
                                  </NavLink>
                                </li>
                              )}
                              
                            </ul>
                          </div>
                        </div>


      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  const { showSwalLoginFailed } = state.freg;
  return { userName, isLogin, userid, showSwalLoginFailed };
};

export default connect(
  mapStateToProps,
  { closeswallogin }
)(withRouter(Home));
//export default withRouter(Home);
/**
<ScriptTag type="text/javascript" src="/js/jquery-2.2.4.min.js" />
<ScriptTag type="text/javascript" src="/js/main.js" />

handleSendLike = async (arId)=>{
  //   if(this.props.isLogin){
  //     try {
  //       const response = await fetch(`${global.apiUrl}image/sendlike`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           arsipImageId : arId, 
  //           arsipLikeUserId:this.props.userid  
  //           })
  //       })
  //       const data = await response.json()
  //       if (data.success == true) {
  //         alert('like berhasil')
         
  //       } else {
          
  //       }
  //     } catch (e) {
  //       console.log('error: ', e)
  //     }
  //   }else{
  //     alert('anda harus login dahulu')
  //   }
   
  // }


 */
