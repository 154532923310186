import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import SweetAlert from "sweetalert2-react";
class Footer extends Component {
    state = {
        nowdat : new Date(),
        email :'',
        showSwal:false,
        showSwalFailed:false,
        showSwalEmailEmpty:false,
        newImage:[]
    }
    componentDidMount() {
        this.fetchListNew()
    }

    handleSendSub= async () => {
        if(this.state.email !== ''){
            try {
                const response = await fetch(`${global.apiUrl}subscription/send?email=${this.state.email}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    email: this.state.email,
                  })
                })
                const data = await response.json()
                if (data.success == true) {
                    this.setState({showSwal:true , email:''})
                } else {
                    this.setState({showSwalFailed:true})
                }
              } catch (e) {
                console.log('error: ', e)
                this.setState({showSwalFailed:true})
              }
        }else{
            this.setState({showSwalEmailEmpty:true})
        }
       
    }

    fetchListNew = async () => {
        this.setState({ loading: true });
        try {
          const response = await fetch(
            `${global.apiUrl}image/listimage?l=3`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if (data.success == true) {
            this.setState({
              newImage: data.detail
            });
            this.setState({ loading: false, showPopUp:true });
            //console.log(data.detail);
          } else {
          }
        } catch (e) {
          console.log("error: ", e);
        }
      };
    

    render() {
        if (!this.props.isLogin) {
            return null
        }
        return (
            <footer>
               
                <div className="footer-area-bottom">
                    <div className="container">
                        <p>Copyright &copy; {this.state.nowdat.getFullYear()} Avinet - PT Bank Rakyat Indonesia (Persero) Tbk</p>
                    </div>
                </div>


                <SweetAlert
                show={this.state.showSwal}
                title="Success"
                confirmButtonText="Close"
                type="success"
                text='Subscription Berhasil'
                showCancelButton={false}
                onConfirm={()=>this.setState({showSwal:false})}/>

                <SweetAlert
                show={this.state.showSwalFailed}
                title="Failed"
                confirmButtonText="Close"
                type="error"
                text='Subscription Gagal'
                showCancelButton={false}
                onConfirm={()=>this.setState({showSwalFailed:false})}/>

                <SweetAlert
                show={this.state.showSwalEmailEmpty}
                title="Failed"
                confirmButtonText="Close"
                type="error"
                text='Email Harus Di isi'
                showCancelButton={false}
                onConfirm={()=>this.setState({showSwalEmailEmpty:false})}/>


            </footer>
        );
    }
}
const mapStateToProps = state => {
    const { isLogin } = state.profile
    return { isLogin }
}

export default connect(mapStateToProps,{})(withRouter(Footer));

// export default Footer;
/*

 <div className="footer-area-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="footer-box">
                                    <h3 className="title-bar-left title-bar-footer frutigerFont">Our Address</h3>
                                    <ul className="corporate-address">
                                        <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link className="frutigerFont" to="#">BRI I Lantai 15</Link></li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i>kirimfilekesini@gmail.com</li>
                                        {/* <li><i className="fa fa-fax" aria-hidden="true"></i>+61 3 8376 6284</li> 
                                        {/* <li><i className="fa fa-envelope-o" aria-hidden="true"></i>info@foxtar.com</li> 
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div className="footer-box">
                                            <h3 className="title-bar-left title-bar-footer frutigerFont">Menu </h3>
                                            <ul className="featured-links">
                                                <li>
                                                    <ul>
                                                        <li><NavLink activeStyle={{color:''}} className="frutigerFont" exact to="/photo">Photo</NavLink></li>
                                                        <li><NavLink activeStyle={{color:''}}  className="frutigerFont" exact to="/video">Video</NavLink></li>
                                                        <li><NavLink activeStyle={{color:''}} className="frutigerFont"  exact to="/arsip">Arsip</NavLink></li>
                                                        <li><NavLink activeStyle={{color:''}} className="frutigerFont" exact to="/about">About</NavLink></li>
                                                       
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        {/* <div className="" style={{display:'flex',flexDirection:'column'}}>
                                            <h3 className="title-bar-left title-bar-footer frutigerFont">Recent Upload</h3>
                                               
        
                                            {
                                                this.state.newImage.map((r,i)=>(
                                                    <div 
                                                    key={i}
                                                    className="footer-image-new"
                                                    onClick={()=>this.props.history.push("detail/" + r.arsiId)}
                                                    style={{cursor:'pointer',display:'flex',flexDirection:'row', flexWrap:'wrap', alignItems:'center' ,marginBottom:10, width:'80%'}}>
                                                    <img style={{height:60, marginRight:10}} src={r.cover}/>
                                                    <p className="frutigerFont" style={{wordBreak: 'break-all',fontSize:12}}>{r.arsiTitle}...</p>
                                                </div>
                                                ))
                                            }
        
                                               
                                               
                                                   
                                                        
                                              
                                        </div> 
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                        <div className="footer-box">
                                            <h3 className="title-bar-left title-bar-footer frutigerFont">Follow Us On</h3>
                                            <ul className="footer-social">
                                                {/* <li><Link to="#"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                <li style={{backgroundColor:'red'}}><a target="_blank" href="https://www.youtube.com/channel/UCLbJScqaSDeuBw7ppMjx21g"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                                <li><a target="_blank" href="https://www.instagram.com/avinetbri/"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                                {/* <li><Link to="#"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li> 
                                            </ul>
                                            <div className="newsletter-area">
                                                <h3>Newsletter Sign Up!</h3>
                                                <div className="input-group stylish-input-group">
                                                    <input 
                                                     value={this.state.email}
                                                     onChange={e =>
                                                       this.setState(
                                                         { email: e.target.value },
                                                            )
                                                     }
                                                    type="text" placeholder="Enter your e-mail here" className="form-control" /> <span className="input-group-addon">
                                                        <button type="submit" onClick={this.handleSendSub}>
                                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                        </button>
                                                    </span> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

*/