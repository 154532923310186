import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
import renderHTML from 'react-render-html';
import Card208 from "../components/Card208";
class About extends Component {
  constructor () {
    super()
    this.state = {
      about : '',
      listTerbaru:[]
    }
  }

  componentDidMount() {
    document.title = "Avinet | About"
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.fetchAbout()
    this.fetchListTerbaru()
    window.scrollTo(0, 0)
}

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();
}

fetchAbout = async () => {
  try {
     const response = await fetch(
      `${global.apiUrl}about`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    if (data.success == true) {
      this.setState({ about: data.detail.aboutDesc })
    } else {
    }
  } catch (e) {
    console.log('error: ', e)
  }
}


fetchListTerbaru = async () => {
  try {
    const response = await fetch(
      `${global.apiUrl}history/listimageforproductpage2?uid=${
        this.props.userid
      }&so=DESC`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.success == true) {
      this.setState({
        listTerbaru: data.detail,
      });
    } else {
    }
  } catch (e) {
    console.log("error: ", e);
  }
};

handleAfterLike3 = async iid => {
  const myArray = this.state.listTerbaru;
  let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
  myArray[objIndex].youLike = !myArray[objIndex].youLike;
  myArray[objIndex].likeCount = myArray[objIndex].youLike
    ? myArray[objIndex].likeCount + 1
    : myArray[objIndex].likeCount - 1;
  this.setState({ listTerbaru: myArray });
};

  render () {
    return (
      <div>
      {
        //!this.props.isLogin && <div style={{ marginTop: 80 }} />
      }
     
            <SearchInput
              onChange={e => this.setState({ searchtext: e.target.value })}
              vall={this.state.searchtext}
            />
         

     

      <div className="pagination-area bg-secondary">
        <div className="container">
          <div className="pagination-wrapper">
            {/* <ul>
              <li>
                <NavLink to="index.html">Home</NavLink>
                <span> -</span>&nbsp;
              </li>
              <li>About</li>
            </ul> */}
          </div>
        </div>
      </div>

      <div className="product-page-grid bg-secondary section-space-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="inner-page-main-body">
               
                  <div className="row">
                  <div className="about-page-area bg-secondary section-space-bottom">
                <div className="container">
                    <h2 className="title-section">To Know Who We Are</h2>
                    <div className="inner-page-details inner-page-padding aboutpage frutigerFont">
                        {
                        renderHTML(this.state.about)
                        /* <h3>What We Do</h3>
                        <p className="frutigerFont">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut a vfrrdyrtfglso survived  
                        but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth
                        leap into electronic typesetting, remaining essentially unchanged. when an unknown printer took a galley of type and scrambled it to make a type specimen 
                        book. It has survived not only five centurbut a vfrrdyrtfglso survived  but also the leap into electronic typesetting, remaining essentially unchanged. It was po
                        pularised in the 1960s with the releas survived not  rasethleap into electronic typesetting, remaining essentially unchanged.</p>
                        <h3>Our Mission</h3>
                        <p className="frutigerFont">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut a vfrrdyrtfglso survived  
                        but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth
                        leap into electronic typesetting, remaining essentially unchanged. when an unknown printer took a galley of type and scrambled it to make a type specimen 
                        book. It has survived not only five centurbut a vfrrdyrtfglso survived  but also the leap into electronic typesetting, remaining essentially unchanged. It was po
                        pularised in the 1960s with the releas survived not  rasethleap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>  
                </div>  
            </div> 
                  </div>
                
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane fade in active clear products-container"
                    id="gried-view"
                  >
                    <div className="product-grid-view padding-narrow">
                      <div className="row">
                       
                       
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                           
                           
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    className="tab-pane fade clear products-container"
                    id="list-view"
                  >
                    <div className="product-list-view">
                     

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 col-lg-pull-9 col-md-pull-8 col-sm-pull-8">
              <div className="fox-sidebar">
               
                <div className="sidebar-item">
                                  <div className="sidebar-item-inner">
                                      <h3 className="sidebar-item-title">Price Range</h3>
                                      <div id="price-range-wrapper" className="price-range-wrapper">
                                          <div id="price-range-filter"></div>
                                          <div className="price-range-select">
                                              <div className="price-range" id="price-range-min"></div>
                                              <div className="price-range" id="price-range-max"></div>
                                          </div>
                                          <button className="sidebar-full-width-btn disabled" type="submit" value="Login"><i className="fa fa-search" aria-hidden="true"></i>Search</button>
                                      </div>
                                  </div>
                              </div>
                <div className="sidebar-item">
                  <div className="sidebar-item-inner">
                    <h3 className="sidebar-item-title">Recent Image</h3>
                    {this.state.listTerbaru.map((r, i) => {
                        return (
                          <Card208
                            key={i}
                            imgurl={r.cover}
                            title={r.arsiTitle}
                            catName={r.catName}
                            author={r.author}
                            download={r.download}
                            like={r.likeCount}
                            urldetail={`detail/${r.arsiId}`}
                            imgurl={r.cover}
                            toDetail={() =>
                              this.props.history.push("detail/" + r.arsiId)
                            }
                            imid={r.arsiId}
                            usid={this.props.userid}
                            actionAfterLike={() =>
                              this.handleAfterLike3(r.arsiId)
                            }
                            youLike={r.youLike}
                            image_id={r.arsiCat}
                            userPict={r.authorPict}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  return { userName, isLogin, userid };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(About))
// export default withRouter(Detail);
// export default withRouter(About)
