import React, { Component } from "react";
import Img from "react-image";
import { NavLink,withRouter } from "react-router-dom";
import CardLoadeRec from "./CardLoaderRect";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
import Card269Pop from './Card269Pop'
import Card269VideoHomePop from './Card269VideoHomePop'
class VideoPlayerPopUp extends Component {
  state = {
    isSwal: false,
    isRight:-640,//-590
    isPlaying:false,
    urlV:'',
    isShowPlayer:true,
    namaV:'',
    idnya:'',
    isVideo:null,
    cat:'',
    catId:'',
    id:'',
    like:'',
    download:'',
    youLike:''
  };

  shouldComponentUpdate(np,ns){
    if(np.showPop !== this.props.showPop ){
      if(np.showPop == false){
        // console.log('sekali')
        this.scrollToTop2()
        return true
      }
     
    }
    return true
    
  }

  scrollToTop = () => {
    this.intervalId = setInterval(this.handleInc, 30);
  };
  scrollToTop2 = () => {
    this.intervalId2 = setInterval(this.handleInc2, 30);
  };

  handleInc=()=>{
    this.setState({ isRight: this.state.isRight + 20 },this.handleClear)
  }
  handleInc2=()=>{
    this.setState({ isRight: this.state.isRight - 20 },this.handleClear2)
  }

  handleClear=()=>{
    if(this.state.isRight > -60){
        clearInterval(this.intervalId)
    }
  }
  handleClear2=()=>{
    if(this.state.isRight < -590){
        clearInterval(this.intervalId2)
    }
  }

  componentDidMount(){
      this.fetchNew()
      this.scrollToTop()
      
  }

  // shouldComponentUpdate(np,ns){
  //   if(this.state.isPlaying !== ns.isPlaying){
  //     this.setState({isPlaying:false})
  //     return true
  //   }
  //   return true
  // }

  onClose=()=>{
    this.scrollToTop2()
    //this.setState({isPlaying:false},this.scrollToTop2)
    setTimeout(()=>{this.setState({isShowPlayer:false})},1000)
  }

  fetchNew = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}video/new_video?uid=${this.props.useridprops}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
         urlV:data.detail.cover,
         isPlaying:true,
         namaV:data.detail.name,
         idnya:data.detail.id,
         isVideo:data.detail.isVideo,
         cat:data.detail.cat,
         id:data.detail.id,
         catId : data.detail.catId,
         like:data.detail.like,
         download:data.detail.download,
         youLike:data.detail.youlike
        })
        setTimeout(()=>{this.setState({isPlaying:false})},5000)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };


  handleAfterLike = async => {

    if(this.state.youLike){
      this.setState({
        like:this.state.like - 1,
        youLike : !this.state.youLike
      })
    }else{
      this.setState({
        like:this.state.like + 1,
        youLike : !this.state.youLike
      })
    }
  
  };


  render() {
    return (
      <div className="video-pop" style={{right:this.state.isRight}}>
      <div onClick={this.onClose} style={{color:'white',position:'absolute', top:0, left:20, cursor:'pointer',zIndex:999}}><i className="fa fa-close"></i></div>
      <span style={{position:'absolute', top:0, left:40}}><span style={{color:'black'}}>{this.state.namaV}</span></span>
       {
         (this.state.isShowPlayer && this.state.isVideo) ?
         <Card269VideoHomePop 
        //  onHover={()=>this.handlePlayHover(r.arsiId)}
         isPlay={true}
         imgurl={this.state.urlV}
         title={this.state.namaV}
         catName={this.state.cat}
         //     author={r.author}
         download={this.state.download}
         like={this.state.like}
         urldetail={`detail-video/${this.state.id}`}
             toDetail={() =>
               this.props.history.push(`detail-video/${this.state.id}`)
             }
             image_id={this.state.catId}
             imid={this.state.id}
             usid={this.props.useridprops}
             youLike={this.state.youLike}
             actionAfterLike={this.handleAfterLike}
        //  userPict={r.authorPict}
        //  imid={r.arsiId}
        //  usid={this.props.userid}
        //      actionAfterLike={() =>
        //        this.handleAfterLike(r.arsiId)
        //      }
        //    youLike={r.youLike}
         />
         
         : 
        
         <Card269Pop
         imgurl={this.state.urlV}
         title={this.state.namaV}
         catName={this.state.cat}
         toDetail={() =>
          this.props.history.push("detail/" + this.state.id)
        }
        image_id={this.state.catId}
        like={this.state.like}
        download={this.state.download}
        youLike={this.state.youLike}
        actionAfterLike={this.handleAfterLike}
        imid={this.state.id}
        usid={this.props.useridprops}
         />
     
       }
       
      </div>
    );
  }
}

export default withRouter(VideoPlayerPopUp);
/*
 

 return (
      <div className="video-pop" style={{right:this.state.isRight}}>
      <div onClick={this.onClose} style={{position:'absolute', top:0, left:6, cursor:'pointer'}}><i className="fa fa-close"></i></div>
      <span style={{position:'absolute', top:0, left:40}}><span style={{color:'black'}}>{this.state.namaV}</span></span>
       {
         (this.state.isShowPlayer && this.state.isVideo) ?
         <ReactPlayer
         width="100%"
         height="100%"
         url={this.state.urlV}
         controls
         playing={this.state.isPlaying}
       />
         
         : <img 
         style={{height:'100%',width:'100%',cursor:'pointer'}}
         src={this.state.urlV }
         onClick={()=>this.props.history.push("detail/" + this.state.idnya)}
         />
     
       }
       
      </div>
    );


 */