import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import Card208 from '../components/Card208'
import Card269 from '../components/Card269'
import Card239 from '../components/Card239'
import SearchInput from '../components/SearchInput'
import { Animated } from 'react-animated-css'
import Loader from '../components/Loader'
import Dropdown from "react-dropdown-modal";
import Gallery from 'react-grid-gallery';
import CardOnlyFoto from '../components/CardOnlyFoto';
import FilteNoPopUp from '../components/FilterNoPopUp';
import { wrap } from 'module';
const IMAGES =
[{
        cover: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        // isSelected: true,
        caption: "After Rain (Jeshu John - designerspics.com)"
},
{
  cover: "https://media-cdn.tripadvisor.com/media/photo-s/10/e8/a1/d8/excellent-holiday-lovely.jpg",
  thumbnail: "https://media-cdn.tripadvisor.com/media/photo-s/10/e8/a1/d8/excellent-holiday-lovely.jpg",
  // thumbnailWidth: 100,
  // thumbnailHeight: 174,
  // isSelected: true,
  caption: "After Rain (Jeshu John - designerspics.com)"
},
{
  cover: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        caption: "Boats (Jeshu John - designerspics.com)"
},

{
  cover: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
}]


class ProductListCategory extends Component {
  constructor () {
    super()
    this.state = {
      listImage: [],
      listCat: [],
      topten: [],
      activeCat: '',
      activeShort: '',
      totalPage: 0,
      activePage: 1,
      page: 1,
      totalImage: 0,
      loading: true,
      namatag: '',
      catFotoDokumentasi:'',
      searchFilterText:'',
      searchFilterTags:'',
      startdate:'',
      enddate:'',
      rightCount:0,
      rightCount2:-320,
      mobileWidth:false
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    this.fetchListImage()
    this.fetchListCat()
    this.fetchTopTen()
    this.handleSetTagName()
    document.title = 'Avinet | Category'
    const script = document.createElement('script')
    script.setAttribute('id', 'forpriceslider')
    script.src = '/js/mainpriceslider.js'
    script.async = true
    document.body.appendChild(script)

    const script2 = document.createElement('script')
    script2.setAttribute('id', 'geser')
    script2.src = '/js/maingeser.js'
    script2.async = true
    document.body.appendChild(script2)
    window.addEventListener("resize", this.resize);
    this.resize()



    // console.log(this.props.location.state);
  }

  resize = ()=>{
        this.setState({mobileWidth: window.innerWidth <= 500})
        
    }

  componentWillUnmount () {
    var todelete = document.getElementById('forpriceslider')
    todelete.remove()

    var todelete2 = document.getElementById('geser')
    todelete2.remove()
  }

  handleSetTagName = async () => {
    if (this.props.location.state !== undefined) {
     this.setState({ namatag : this.props.location.state.image_tag_name}) 
    }
  }

  fetchListImage = async () => {
    // this.setState({loading:true})

    try {
      let url = `${global.apiUrl}image/listimage?uid=${
        this.props.userid
      }&c=${this.state.catFotoDokumentasi}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}`
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.image_id > 0) {
          url = `${global.apiUrl}image/listimage?uid=${
            this.props.userid
          }&c=${this.props.location.state.image_id}`
        } else if (this.props.location.state.image_tag > 0) {
          url = `${global.apiUrl}image/listimage?uid=${this.props.userid}&tag=${
            this.props.location.state.image_tag
          }`
        }
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false
        })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }



  fetchListImageTwo = async () => {
    // this.setState({loading:true})

    try {
      let url = `${global.apiUrl}image/listimage?uid=${
        this.props.userid
      }&c=${this.state.catFotoDokumentasi}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}&p=${this.state.page}`
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.success == true) {
        // console.log(data)
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false
        })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }



  silahkanReload = async (tagid) => {
    // alert(tagid)
    // window.scrollTo(300, 300)
    try {
      let url = `${global.apiUrl}image/imagetag?uid=${this.props.userid}&tag=${
        tagid
      }`
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false
        })
        window.scrollTo(300, 300)
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  fetchListCat = async () => {
    try {
      const response = await fetch(`${global.apiUrl}category/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.success == true) {
        this.setState({ listCat: data.data, totalImage: data.totalimage })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  handleSelectCat = async cat => {
    try {
      await this.setState({ activeCat: cat })
      const response = await fetch(
        `${global.apiUrl}image/listimageforproductpage?c=${
          this.state.activeCat
        }&uid=${this.props.userid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data.success == true) {
        this.setState({ listImage: data.detail })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  handleSelectCatAndShort = async so => {
    try {
      await this.setState({ activeShort: so })
      const response = await fetch(
        `${global.apiUrl}image/listimageforproductpage?c=${
          this.state.activeCat
        }&so=${this.state.activeShort}&uid=${this.props.userid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data.success == true) {
        this.setState({ listImage: data.detail })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  handleSelectPage =  async page => {
    // this.setState({ page: page, activePage: page },()=>console.log(this.state.page))
    try {
      await this.setState({ page: page, activePage: page },this.fetchListImageTwo)
      // const response = await fetch(
      //   `${global.apiUrl}image/listimageforproductpage?c=${
      //     this.state.activeCat
      //   }&so=${this.state.activeShort}&p=${this.state.page}&uid=${
      //     this.props.userid
      //   }`,
      //   {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     }
      //   }
      // )
      // const data = await response.json()
      // if (data.success == true) {
      //   this.setState({ listImage: data.detail })
      // } else {
      // }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  fetchTopTen = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}image/topten?uid=${this.props.userid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data.success == true) {
        this.setState({ topten: data.detail })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  handleAfterLike = async iid => {
    const myArray = this.state.listImage
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid)
    myArray[objIndex].youLike = !myArray[objIndex].youLike
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1
    this.setState({ listImage: myArray })
  }

  handleAfterLike2 = async iid => {
    const myArray = this.state.listImage
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid)
    myArray[objIndex].youLike = !myArray[objIndex].youLike
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1
    this.setState({ listImage: myArray })
  }

  handleAfterLike3 = async iid => {
    const myArray = this.state.topten
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid)
    myArray[objIndex].youLike = !myArray[objIndex].youLike
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1
    this.setState({ topten: myArray })
  }

   myTileViewportStyleFn=()=>{
    if (this.props.item.isSelected){
      console.log(this.props.item)
    }
  }

  myTileViewportStyleFn=()=>{
    if (this.props.item.isSelected){
      alert(this.props.item.isSelected)
    }
  }

  handleFetchOnCheckBokFoto = (parfoto) =>{
    if(parfoto == true){
      this.setState({catFotoDokumentasi:2},this.fetchListImageTwo)
    }else{
      this.setState({catFotoDokumentasi:''},this.fetchListImageTwo)
    }
    
  }
  handleFetchOnCheckBokVideo = (parfoto) =>{
    if(parfoto == true){
      this.setState({catFotoDokumentasi:3},this.fetchListImageTwo)
    }else{
      this.setState({catFotoDokumentasi:''},this.fetchListImageTwo)
    }
   
  }
  handleInput=(searchFilterText)=>{
    this.setState({searchFilterText},this.fetchListImageTwo)
  }
  handleConsoleTags = (searchFilterTags) => {
    this.setState({searchFilterTags},this.fetchListImageTwo)
  }
  handleChangeDateStart = (startdate) => {
    // console.log(startdate)
    this.setState({startdate},this.fetchListImageTwo)
  }
  handleChangeDateEnd = (enddate) => {
    // console.log(enddate)
    this.setState({enddate},this.fetchListImageTwo)
  }

  handleOpenFilter = () => {
    if(this.state.mobileWidth){
      this.setState({
        rightCount:280,
        rightCount2:-326
      });
    }else{
      this.setState({
        rightCount:280,
        rightCount2:-278
      });
    }
    

    if(this.state.rightCount > 0){
      this.setState({rightCount:0,rightCount2:-320})
    }
  };

  render () {
    let pagePrev = this.state.page - 1
    let curPage = this.state.page
    let nextPage = this.state.page + 1
    let afterNextPage = nextPage + 1
    let beforePagePrev = pagePrev - 1
    return (
      <div style={{marginRight:this.state.rightCount,position:'relative',transition: 'all 0.5s ease'}}>
        {
          //! this.props.isLogin && <div style={{ marginTop: 80 }} />
        }

        <div className="container-nopop" style={{right:this.state.rightCount2}}>
              <FilteNoPopUp 
              pressClose={this.handleOpenFilter}
                  openProps={this.state.openFilter} 
                  closeFilter={()=>this.setState({openFilter:!this.state.openFilter})}
                  cekBokFoto={(par)=>this.handleFetchOnCheckBokFoto(par)}
                  cekBokVideo={(par)=>this.handleFetchOnCheckBokVideo(par)}
                  //cekBokDesign={(par)=>this.handleFetchOnCheckBokDesign(par)}
                  inputSearch={(txt)=>this.handleInput(txt)}
                  tagsinput={(txt)=>this.handleConsoleTags(txt)}
                  changeDateStart={(dd)=>this.handleChangeDateStart(dd)}
                  changeDateEnd={(dd)=>this.handleChangeDateEnd(dd)}
                />
              </div>

        <SearchInput
          silahkanReloadLagi={(tagid)=>this.silahkanReload(tagid)}
          onChange={e => this.setState({ searchtext: e.target.value })}
          vall={this.state.searchtext}
        />

        <Animated
          animationIn=''
          animationOut='fadeOut'
          isVisible={this.state.loading}
        >
          <Loader />
        </Animated>


        <div
          className="isotope-classNamees-tab isotop-box-btn frutigerFont listcategory"
          style={{ backgroundColor: "white", width: "100%", height:50, display:'flex',justifyContent:'flex-end' }}
        >
          <div
            className="page-controls-sorting"
            style={{ marginTop: -5, padding: 0 }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                //  style={{border: '1px solid black',
                //   padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                {/* SORT BY */}
                {/* <i className="fa fa-angle-down" aria-hidden="true" /> */}
              </button>
              {/* <ul className="dropdown-menu">
                <li>
                  <NavLink to="#" onClick={() => console.log("DESC")}>
                    NEWEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    OLDEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    FAVORITE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    MOST DOWNLOAD
                  </NavLink>
                </li>
              </ul> */}
            </div>
            <div
            onClick={this.handleOpenFilter}
            className=""
            style={{
              //  marginTop: -5, 
               float: "right" }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle frutigerFont"
                type="button"
                data-toggle="dropdown"
                style={{border: '1px solid lightgray',
                  padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                FILTER
              </button>
            </div>
          </div>
          </div>
          {/* <Link to={{
              pathname: '/product',
              state: { catid:'anjay' }
            }}> TestParam </Link> */}
          
          {/* <NavLink to="#" data-filter="*" className="current">
                All
              </NavLink>
              <NavLink to="#" data-filter=".wordpress">
                WordPress
              </NavLink>
              <NavLink to="#" data-filter=".joomla">
                Joomla
              </NavLink>
              <NavLink to="#" data-filter=".plugins">
                Plugins
              </NavLink>
              <NavLink to="#" data-filter=".component">
                Component
              </NavLink>
              <NavLink to="#" data-filter=".psd">
                PSD
              </NavLink>{" "} */}

         
        </div>

        

        <div className='pagination-area bg-secondary'>
          <div className='container'>
            {/* <div className='pagination-wrapper'> */}
              {/* <ul>
                <li>
                  <NavLink to='index.html'>Home</NavLink>
                  <span> - </span>&nbsp;
                </li>
                <li>Photo</li>
                <li>
                &nbsp;<span style={{}}>{this.state.namatag !=='' && <span><span style={{color:'grey'}}> - </span><span style={{color:'black'}}>{this.state.namatag}</span></span> }</span>
                </li>
              </ul> */}
            {/* </div> */}
          </div>
        </div>

        <div className="row" style={{backgroundColor:'#f4f6f5',padding:20}}>
              {/* <div className="col-lg-3">
              <FilteNoPopUp 
                  openProps={this.state.openFilter} 
                  closeFilter={()=>this.setState({openFilter:!this.state.openFilter})}
                  cekBokFoto={(par)=>this.handleFetchOnCheckBokFoto(par)}
                  cekBokVideo={(par)=>this.handleFetchOnCheckBokVideo(par)}
                  //cekBokDesign={(par)=>this.handleFetchOnCheckBokDesign(par)}
                  inputSearch={(txt)=>this.handleInput(txt)}
                  tagsinput={(txt)=>this.handleConsoleTags(txt)}
                  changeDateStart={(dd)=>this.handleChangeDateStart(dd)}
                  changeDateEnd={(dd)=>this.handleChangeDateEnd(dd)}
                />
              </div> */}
              <div className="col-lg-12" style={{
                display:'flex',
                flexWrap:'wrap',
                flexDirection:'row',
                justifyContent:'center'
                }}>
              {this.state.listImage.map((r, i) => {
                            return (
                              <CardOnlyFoto
                                key={i}
                                imgurl={r.cover}
                                title={r.arsiTitle}
                                catName={r.catName}
                                author={r.author}
                                download={r.download}
                                like={r.likeCount}
                                urldetail={`detail/${r.arsiId}`}
                                imgurl={r.cover}
                                toDetail={() =>
                                  this.props.history.push('detail/' + r.arsiId)
                                }
                                userPict={r.authorPict}
                                imid={r.arsiId}
                                usid={this.props.userid}
                                actionAfterLike={() =>
                                  this.handleAfterLike(r.arsiId)
                                }
                                youLike={r.youLike}
                              />
                            )
                          })}
              </div>
        </div>

        <div className='product-page-grid bg-secondary section-space-bottom'>
          <div className='container'>
            <div className='row'>
              <div 
             // className='col-lg-9 col-md-8 col-sm-8 col-xs-12 col-lg-push-3 col-md-push-4 col-sm-push-4'
              className='col-lg-12 col-md-12 col-sm-12 col-xs-12'
              >
                <div className='inner-page-main-body'>
                 
                  <div className='tab-content'>
                    <div
                      role='tabpanel'
                      className='tab-pane fade in active clear products-container'
                      id='gried-view'
                    >
                      <div className='product-grid-view padding-narrow'>
                        <div className='row' style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          // justifyContent: 'center',
                        }}>
                    
                        </div>
                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <ul className='mypaging'>
                             {/* {
                               beforePagePrev > 0 &&
                               <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(1)
                                  }
                                >
                                  {beforePagePrev}
                                </NavLink>
                              </li>
                             } */}

                             {/* {
                               <li>
                               <NavLink
                                 to="#"
                                 onClick={() =>
                                   this.handleSelectPage(1)
                                 }
                               >
                                 {'1'}
                               </NavLink>
                             </li>
                             } */}
                              

                              {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {'<'}
                                  </NavLink>
                                </li>
                              )}

                              {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {pagePrev}
                                  </NavLink>
                                </li>
                              )}

                              <li style={{ color: 'red' }}>
                                <NavLink
                                  to='#'
                                  className='active'
                                  style={{
                                    color:
                                      curPage == this.state.activePage && 'rgb(2,65,130)'
                                  }}
                                  onClick={() => this.handleSelectPage(curPage)}
                                >
                                  {curPage}
                                </NavLink>
                              </li>
                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {nextPage}
                                  </NavLink>
                                </li>
                              )}
                              
                              {
                                this.state.totalPage !== curPage && "..."
                              }
                              {
                                this.state.totalPage !== curPage && 
                                <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(this.state.totalPage)
                                  }
                                >
                                  {this.state.totalPage}
                                </NavLink>
                              </li>
                              }

                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {'>'}
                                  </NavLink>
                                </li>
                              )}
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile
  return { userName, isLogin, userid }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(ProductListCategory))
// export default withRouter(ProductListCategory)
