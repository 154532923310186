import { combineReducers } from 'redux';
import ProfileReducer from './ProfileReducer';
import RegisterReducer from './RegisterReducer';
import CartReducer from './CartReducer';
import LoginReducer from './LoginReducer';
export default combineReducers({
  profile: ProfileReducer,
  freg: RegisterReducer,
  cart: CartReducer,
  lo:LoginReducer
});
