import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Card269 from "../components/Card269";
import Card181 from "../components/Card181";
import Card239 from "../components/Card239";
import { Animated } from "react-animated-css";
import SearchInput from "../components/SearchInput";
class ArsipImage extends Component {
  constructor() {
    super();
    this.state = {
      listImage: [],
      keySearch: "",
      isAnimated: true,
      numRand: 1,
      totalPage: 0,
      activePage: 1,
      page: 1,
    };
  }

  handleRandom = () => {
    this.setState({ numRand: Math.ceil(Math.random() * 3) });
  };

  componentDidMount() {
    this.handleRandom();
    //this.setState({keySearch : this.props.match.params.key})
    this.fetchListImage();
    document.title = "Avinet | Product";
    const script = document.createElement("script");
    script.setAttribute("id", "geser");
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({ keySearch: this.props.match.params.key });

    const script2 = document.createElement("script");
    script2.setAttribute("id", "naon");
    script2.src = "/js/main.js";
    script2.async = true;
    document.body.appendChild(script2);
  }

  componentWillUnmount() {
    var todelete = document.getElementById("geser");
    todelete.remove();

    var todelete2 = document.getElementById("naon");
    todelete2.remove();
  }

  fetchListImage = async () => {
    try {
      let url = `${global.apiUrl}history/listimageforproductpage?uid=${
        this.props.userid
      }&so=DESC`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false,
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectShort = async so => {
      try {
        let url = `${global.apiUrl}history/listimageforproductpage?uid=${
          this.props.userid
        }&so=${so}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (data.success == true) {
          this.setState({
            listImage: data.detail,
            totalPage: data.pagetotal,
            loading: false,
          });
        } else {
        }
      } catch (e) {
        console.log("error: ", e);
      }
  };

  handleAfterLike = async iid => {
    const myArray = this.state.listImage;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listImage: myArray });
  };

  render() {
    let pagePrev = this.state.page - 1;
    let curPage = this.state.page;
    let nextPage = this.state.page + 1;
    return (
      <div>
        {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        {/* <div
          className='inner-banner-area'
          style={{ backgroundImage: `url(/img/avinet/${this.state.numRand}.png)` , paddingTop:80}}
        >
          <div className='container'>
            <div className='inner-banner-wrapper'>
            <p>Download what you want, cancel when you want</p>
              <div className='banner-search-area input-group'>
                <input
                  onChange={e => this.setState({ keySearch: e.target.value },this.fetchListImage2)}
                  className='form-control'
                  placeholder='Search Your Keywords . . .'
                  type='text'
                  value={this.state.keySearch}
                />
                <span className='input-group-addon'>
                  <button type='submit'>
                    <span className='glyphicon glyphicon-search' />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <SearchInput />

        <div className="pagination-area bg-secondary">
          <div className="container">
            <div className="pagination-wrapper">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                  <span> -</span>
                </li>
                <li>Arsip</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="category-product-grid bg-secondary section-space-bottom">
          <div className="container">
            <div className="inner-page-main-body">
              <div className="page-controls">
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-10 col-xs-8">
                    {/* <div className="page-controls-sorting display-none-in-mobile">
                                        <div className="dropdown">
                                            <button className="btn sorting-btn dropdown-toggle" type="button" data-toggle="dropdown">Categories<i className="fa fa-angle-down" aria-hidden="true"></i></button>
                                            <ul className="product-categories-list dropdown-menu">
                                                <li><NavLink to="#">WordPress<span>(150)</span></NavLink></li>
                                                <li><NavLink to="#">Joomla <span>(100)</span></NavLink></li>
                                                <li><NavLink to="#">PSD<span>(50)</span></NavLink></li>
                                                <li><NavLink to="#">Plugins<span>(60)</span></NavLink></li>
                                                <li><NavLink to="#">Components<span>(40)</span></NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="page-controls-sorting display-none-in-mobile">
                                        <div className="dropdown">
                                            <button className="btn sorting-btn dropdown-toggle" type="button" data-toggle="dropdown">Price Range<i className="fa fa-angle-down" aria-hidden="true"></i> </button>
                                            <ul className="dropdown-menu">
                                                <li><NavLink to="#">Date</NavLink></li>
                                                <li><NavLink to="#">Best Sale</NavLink></li>
                                                <li><NavLink to="#">Rating</NavLink></li>
                                            </ul>
                                        </div>
                                    </div> */}
                    <div className="page-controls-sorting">
                      <div className="dropdown">
                        <button
                          className="btn sorting-btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                        >
                          {" "}
                          Sort by:
                          <i className="fa fa-sort" aria-hidden="true" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="#" onClick={()=>this.handleSelectShort('DESC')}>New</NavLink>
                          </li>
                          <li>
                            <NavLink to="#" onClick={()=>this.handleSelectShort('ASC')}>Older</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>



                    
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4">
                    <div className="layout-switcher">
                      <ul>
                        <li className="active">
                          <NavLink
                            to="#gried-view"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            <i className="fa fa-th-large" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#list-view"
                            data-toggle="tab"
                            aria-expanded="true"
                          >
                            <i className="fa fa-list" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane fade in active clear products-container"
                  id="gried-view"
                >
                  <div className="product-grid-view padding-narrow">
                    <div className="row">
                      {this.state.listImage.length == 0 && (
                        <div className="row">
                          <h3
                            className="text-center"
                            style={{ color: "#89C453" }}
                          >
                            No Result
                          </h3>
                        </div>
                      )}

                      {/* <Animated animationIn="bounceInLeft" animationOut="bounceOutRight" isVisible={this.state.isAnimated}> */}
                      {this.state.listImage.map((r, i) => {
                        return (
                          <Card269
                            key={i}
                            imgurl={r.cover}
                            title={r.arsiTitle}
                            catName={r.catName}
                            // author={r.author}
                            download={r.download}
                            like={r.likeCount}
                            urldetail={`detail/${r.arsiId}`}
                            imgurl={r.cover}
                            toDetail={() =>
                              this.props.history.push("detail/" + r.arsiId)
                            }
                            userPict={r.authorPict}
                            imid={r.arsiId}
                            usid={this.props.userid}
                            actionAfterLike={() =>
                              this.handleAfterLike(r.arsiId)
                            }
                            image_id={r.arsiCat}
                            youLike={r.youLike}
                          />
                        );
                      })}
                      {/* </Animated> */}

                      {/* <Card269 /> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ul className="pagination-align-center">
                          {curPage - 1 >= 1 && (
                            <li className="active" style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(pagePrev)}
                              >
                                {pagePrev}
                              </NavLink>
                            </li>
                          )}

                          <li style={{ color: "red" }}>
                            <NavLink
                              to="#"
                              className="active"
                              style={{
                                color:
                                  curPage == this.state.activePage && "red",
                              }}
                              onClick={() => this.handleSelectPage(curPage)}
                            >
                              {curPage}
                            </NavLink>
                          </li>
                          {nextPage <= this.state.totalPage && (
                            <li>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(nextPage)}
                              >
                                {nextPage}
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane fade clear products-container"
                  id="list-view"
                >
                  <div className="product-list-view">
                    {this.state.listImage.map((r, i) => {
                      return (
                        <Card239
                          key={i}
                          imgurl={r.cover}
                          title={r.arsiTitle}
                          catName={r.catName}
                          // author={r.author}
                          download={r.download}
                          like={r.likeCount}
                          imgurl={r.cover}
                          userPict={r.authorPict}
                          urldetail={`detail/${r.arsiId}`}
                          toDetail={() =>
                            this.props.history.push("detail/" + r.arsiId)
                          }
                          imid={r.arsiId}
                          usid={this.props.userid}
                          actionAfterLike={() => this.handleAfterLike(r.arsiId)}
                          image_id={r.arsiCat}
                          youLike={r.youLike}
                        />
                      );
                    })}

                    {/* <Card181 /> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ul className="pagination-align-center">
                          {curPage - 1 >= 1 && (
                            <li className="active" style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(pagePrev)}
                              >
                                {pagePrev}
                              </NavLink>
                            </li>
                          )}

                          <li style={{ color: "red" }}>
                            <NavLink
                              to="#"
                              className="active"
                              style={{
                                color:
                                  curPage == this.state.activePage && "red",
                              }}
                              onClick={() => this.handleSelectPage(curPage)}
                            >
                              {curPage}
                            </NavLink>
                          </li>
                          {nextPage <= this.state.totalPage && (
                            <li>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(nextPage)}
                              >
                                {nextPage}
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  return { userName, isLogin, userid };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(ArsipImage));
//export default withRouter(Detail);
// export default withRouter(ProductList)
