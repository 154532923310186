import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
class Loader extends Component {
    constructor(){
        super()
        this.state = {
            percentage:100
        }
        this.intervalId=''
    }
  
  componentDidMount() {
      this.handleIncrement()
      setTimeout(()=>{
        clearInterval(this.intervalId)
      },20000)
  }
  handleIncrement=()=>{
    this.intervalId = setInterval(this.handleInc, 100);
  }
  handleInc=()=>{
     if(this.state.percentage <= 100 ){
        this.setState({ percentage: this.state.percentage - 10 })
      }
      if(this.state.percentage == 0){
        this.setState({ percentage: 100 })
      }
  }
  render() {
    if (!this.props.isLogin) {
      return null;
    }
    return (
      <div
        id=""
        style={{
          background: "white",
          backgroundImage: `url(/img/avinet/logo-a.png)`,
          backgroundSize:'200px 180px',
          filter:`grayscale(${this.state.percentage}%)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          //position: 'absolute',
          overflow: "visible",
          position: "fixed",
          zIndex: 9999999,
        }}
      />
    );
  }
}
const mapStateToProps = state => {
  const { isLogin } = state.profile;
  return { isLogin };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(Loader));

// export default Footer;
