import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import SearchInputHome from '../components/SearchInput';
import renderHTML from 'react-render-html';
class About extends Component {
  constructor () {
    super()
    this.state = {
      about : ''
    }
  }

  componentDidMount() {
    document.title = "Avinet | About"
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.fetchAbout()
}

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();
}

fetchAbout = async () => {
  try {
     const response = await fetch(
      `${global.apiUrl}about`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    if (data.success == true) {
      this.setState({ about: data.detail.aboutDesc })
    } else {
    }
  } catch (e) {
    console.log('error: ', e)
  }
}

  render () {
    return (
      <div>
        {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
       
                            <SearchInputHome 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                            {/* <div className="banner-search-area input-group">
                                <input className="form-control" placeholder="Search Your Keywords . . ." type="text" />
                                <span className="input-group-addon">
                                    <button type="submit">
                                        <span className="glyphicon glyphicon-search"></span>
                                    </button>
                                </span>
                            </div> */}
                    
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><a href="index.html">Home</a><span> -</span></li>
                            <li>About US</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            <div className="about-page-area bg-secondary section-space-bottom">
                <div className="container">
                    <h2 className="title-section">To Know Who We Are</h2>
                    <div className="inner-page-details inner-page-padding">
                        {
                        renderHTML(this.state.about)
                        /* <h3>What We Do</h3>
                        <p className="frutigerFont">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut a vfrrdyrtfglso survived  
                        but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth
                        leap into electronic typesetting, remaining essentially unchanged. when an unknown printer took a galley of type and scrambled it to make a type specimen 
                        book. It has survived not only five centurbut a vfrrdyrtfglso survived  but also the leap into electronic typesetting, remaining essentially unchanged. It was po
                        pularised in the 1960s with the releas survived not  rasethleap into electronic typesetting, remaining essentially unchanged.</p>
                        <h3>Our Mission</h3>
                        <p className="frutigerFont">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut a vfrrdyrtfglso survived  
                        but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth
                        leap into electronic typesetting, remaining essentially unchanged. when an unknown printer took a galley of type and scrambled it to make a type specimen 
                        book. It has survived not only five centurbut a vfrrdyrtfglso survived  but also the leap into electronic typesetting, remaining essentially unchanged. It was po
                        pularised in the 1960s with the releas survived not  rasethleap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>  
                </div>  
            </div> 

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  return { userName, isLogin }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(About))
// export default withRouter(Detail);
// export default withRouter(About)
