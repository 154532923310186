import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import {register,registervariable,closeswalregister} from '../redux/actions';
import SweetAlert from 'sweetalert2-react';
import SearchInput from '../components/SearchInput';
class Register extends Component {
  constructor () {
    super()
    this.state = {
        nama:'',
        nomorpegawai:'',
        nomorhandphone:'',
        email:'',
        password:'',
        photo:''
    }
  }

  componentDidMount() {
    document.title = "Avinet | Register"
    const script = document.createElement("script");
    script.setAttribute('id', 'forselect2');
    script.src = "/js/mainselect2.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.setAttribute('id', 'geser');
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);
}

componentWillUnmount(){
    var todelete = document.getElementById('forselect2');
    todelete.remove();

    var todelete2 = document.getElementById('geser');
    todelete2.remove();
}

handleRegister=(e)=>{
    e.preventDefault()
    let datareg = {
        nama:this.props.fnama,
        nomorpegawai:this.props.fnomorpegawai,
        nomorhandphone:this.props.fnomorhandphone,
        email:this.props.femail,
        password:this.props.fpassword,
    }
    this.props.register(datareg)
}

/*
this.props.registervariable({ name, phone, shift: shift || 'Monday' });
*/

  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        <div className="inner-banner-area" style={{ backgroundImage: `url(/img/avinet/bg-asset-bl.png)` , paddingTop:80}}>
                    <div className="container">
                        <div className="inner-banner-wrapper">
                        <p>Download what you want, cancel when you want</p>
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                        </div>
                    </div>
                </div>
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><a href="index.html">Home</a><span> -</span></li>
                            <li>Registration</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            <div className="registration-page-area bg-secondary section-space-bottom">
                <div className="container">
                    <h2 className="title-section">Registration</h2>
                    <div className="registration-details-area inner-page-padding">
                        <form id="personal-info-form">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="first-name">Nama *</label>
                                        <input value={this.props.fnama} onChange={e => this.props.registervariable({ prop: 'fnama', value: e.target.value })} type="text" id="first-name" className="form-control"/>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="last-name">Nomor Pegawai *</label>
                                            <input value={this.props.fnomorpegawai} onChange={e => this.props.registervariable({ prop: 'fnomorpegawai', value: e.target.value })} type="text" id="last-name" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                           
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="company-name">Nomor Handphone *</label>
                                        <input value={this.props.fnomorhandphone} onChange={e => this.props.registervariable({ prop: 'fnomorhandphone', value: e.target.value })} type="text" id="company-name" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="email">E-mail *</label>
                                        <input value={this.props.femail} onChange={e => this.props.registervariable({ prop: 'femail', value: e.target.value })} type="text" id="email" className="form-control"/>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="phone">Phone *</label>
                                        <input type="text" id="phone" className="form-control"/>
                                    </div>
                                </div> */}
                            </div>                                  
                            {/* <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                           
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="country">Country</label>
                                        <div className="custom-select">
                                            <select id="country" className='select2'>
                                                <option value="0">Select your country</option>
                                                <option value="1">Bangladesh</option>
                                                <option value="2">Spain</option>
                                                <option value="3">Belgium</option>
                                                <option value="3">Ecuador</option>
                                                <option value="3">Ghana</option>
                                                <option value="3">South Africa</option>
                                                <option value="3">India</option>
                                                <option value="3">Pakistan</option>
                                                <option value="3">Thailand</option>
                                                <option value="3">Malaysia</option>
                                                <option value="3">Italy</option>
                                                <option value="3">Japan</option>
                                                <option value="4">Germany</option>
                                                <option value="5">USA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                           
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="town-city">Town / City</label>
                                        <input type="text" id="town-city" className="form-control"/>
                                        
                                    </div>
                                </div> 
                            </div> */}
                            {/* <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                    <div className="form-group">
                                        <label className="control-label">Address line 1</label>
                                        <input type="text" id="address-line1" className="form-control"/>
                                        <label className="control-label">Address line 2</label>
                                        <input type="text" id="address-line2" className="form-control"/>
                                    </div>
                                </div>                                      
                            </div> */}
                            <div className="row">
                                {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="district">District *</label>
                                        <div className="custom-select">
                                            <select id="district" className='select2'>
                                                <option value="0">Select Your District</option>
                                                <option value="1">Dhaka</option>
                                                <option value="2">Rajshahi</option>
                                                <option value="4">Chittagong</option>
                                                <option value="5">GAZIPUR</option>
                                                <option value="6">GOPALGANJ</option>
                                                <option value="7">JAMALPUR</option>
                                                <option value="8">KISHOREGONJ</option>
                                                <option value="9">MADARIPUR</option>
                                                <option value="10">MANIKGANJ</option>
                                                <option value="11">MUNSHIGANJ</option>
                                                <option value="12">MYMENSINGH</option>
                                                <option value="13">NARAYANGANJ</option>
                                                <option value="14">NARSINGDI</option>
                                                <option value="15">NETRAKONA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="postcode">Password</label>
                                        <input value={this.props.fpassword} onChange={e => this.props.registervariable({ prop: 'fpassword', value: e.target.value })} type="text" id="postcode" className="form-control"/>
                                    </div>
                                </div>
                                </div> 

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12"> 
                                    <label className="control-label" htmlFor="postcode">Photo</label>                                         
                                    <div className="file-upload-area">
                                        <div className="upload-btn-wrapper" style={{position: 'relative',
                                                                                overflow: 'hidden',
                                                                                display: 'inline-block'}}>
                                            <button className="btn" style={{
                                                 borderWidth: '1px', 
                                                 //borderColor: 'gray',
                                                 backgroundColor:'white',
                                            }}>Upload Photo</button>
                                            <input style={{
                                                fontSize: 100,
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                opacity: 0,
                                            }} type="file" name="myfile" />
                                        </div>
                                    {/* <NavLink to="#">Choose File</NavLink>No File Choosen */}
                                    </div>
                                    </div>
                                </div>
                                


                                <div className="row">
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                    <div className="pLace-order">
                                        <button onClick={this.handleRegister} className="update-btn disabled" type="submit" value="Login">Submit</button>
                                    </div>
                                </div>
                                </div>
                        </form>                      
                    </div> 
                </div>
            </div>

            <SweetAlert
                show={this.props.showSwalRegisterFailed}
                title="Register Berhasil"
                confirmButtonText="Close"
                type="success"
                text='Silahkan Login'
                showCancelButton={false}
                onConfirm={()=>this.props.closeswalregister()}/>

            <SweetAlert
                show={this.props.showSwalRegisterFailed2}
                title="Register Gagal"
                confirmButtonText="Close"
                type="warning"
                text='Data Harus Disii Semua'
                showCancelButton={false}
                onConfirm={()=>this.props.closeswalregister()}/>
          



      </div>
    )
  }
}//this.props.showSwalRegisterFailed
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  const {   fnama,
            fnomorpegawai,
            fnomorhandphone,
            femail,
            fpassword,
            showSwalRegisterFailed,
            showSwalRegisterFailed2 } = state.freg
  return { userName, isLogin, fnama,
            fnomorpegawai,
            fnomorhandphone,
            femail,
            fpassword,
            showSwalRegisterFailed ,
            showSwalRegisterFailed2}
}

export default connect(
  mapStateToProps,
  {register,registervariable,closeswalregister}
)(withRouter(Register))
// export default withRouter(Detail);
// export default withRouter(Register)
