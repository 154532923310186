import React, { Component } from 'react'
import Img from 'react-image'
import CardLoadeRec from './CardLoaderRect'
import { NavLink } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
class Card370two extends Component {
  static defaultProps = {
    imgurl : '/img/product/1.jpg',
    title :'',
    catName:'',
    author:'',
    download:'',
    like:''
  }
  state = {
    isSwal : false
  }
  handleSendLike = async ()=>{
    // usid imid
    if(this.props.usid){
      try {
        const response = await fetch(`${global.apiUrl}image/sendlike`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            arsipImageId : this.props.imid, 
            arsipLikeUserId:this.props.usid  
            })
        })
        const data = await response.json()
        if (data.success == true) {
          this.props.actionAfterLike()
        } else {
          
        }
      } catch (e) {
        console.log('error: ', e)
      }
    }else{
      this.setState({isSwal:true})
      // alert('anda harus login dahulu')
    }
  }


  render () {
    return (
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 wordpress joomla plugins">
      <div className='single-item-grid' style={{}}>
        <div className='item-img' style={{height:220, overflow:'hidden', backgroundColor:'white', padding:10}}>
          {' '}
          <Img
            loader={<CardLoadeRec />}
            src={this.props.imgurl}
            alt='product'
            className='img-responsive'
            style={{
              objectFit: 'cover',
              height:'100%',
              cursor:'pointer'}}
            onClick={this.props.toDetail}
          />
          <div className="overlaydownloadandlike">
           <div className='' style={{position:'absolute',right:26,bottom:10}}>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload' style={{color:'white'}}>
                  {this.props.download}
                </span>{' '}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red', cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{ cursor:'pointer' }}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              )}
            </div>
            </div>
          {/* <div className='trending-sign' data-tips='Trending'>
            <i className='fa fa-bolt' aria-hidden='true' />
          </div> */}
        </div>
        <div className='item-content'>
          <div className='item-info'>
            <h3><NavLink className="frutigerFont" to={`${this.props.urldetail}`}>{this.props.title}</NavLink></h3> 
            <NavLink 
            to={{
              pathname: '/photo',
              state: { image_id: this.props.image_id }
            }}
            >
            <span className="frutigerFont">{this.props.catName}</span>
            </NavLink>
            
            
            
          </div>
          {/* <div className='item-profile'>
            <div className='profile-title'>
              <div className='img-wrapper'>
                <img
                  src={this.props.userPict}
                  alt='profile'
                  className='img-responsive img-circle'
                />
              </div>{' '}
              <span>{this.props.author}</span>{' '}
            </div>
            <div className='profile-rating'>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload'>{this.props.download}</span>{' '}
              </i>
              &nbsp;
              
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red',cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{cursor:'pointer'}}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              )}
             
            </div>
          </div> */}
        </div>
      </div>
      <SweetAlert
                show={this.state.isSwal}
                title="Perhatian"
                confirmButtonText="Close"
                type="warning"
                text='Anda Harus Login Terlebih Dahulu'
                showCancelButton={false}
                onConfirm={()=>this.setState({isSwal:false})}/>
      </div>
    )
  }
}

export default Card370two
