import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'

import reducer from './redux/reducers';

global.apiUrl = '//api.avinet.online/'
//global.apiUrl = 'http://avinet-api.hahabid.com/'
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['profile','freg','cart'] //freg
}

const persistedReducer = persistReducer(persistConfig, reducer)
let store = createStore(persistedReducer, applyMiddleware(ReduxThunk))
//const store = createStore(reducer, applyMiddleware(ReduxThunk))
let persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider >
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/*

[10:27, 4/4/2019] Mas Anggoro: 

[ ] Keyword (nanti) ==============================================ja
[ ] Play video cuma 3 second _> home ============================= ok
[ ] Menu video sendiri =========================================== ok
[ ] Mouse over video keluar, trailer 3 s ========================= ok



[ ] Backgroundnya foto random _> di header ======================= ok
[ ] Your our limit warna orange, create (putih) _> home ========== ok
[ ] Featured trending _> Recent upload slider _> home ============ ok
[ ] Tulisan developer ga perlu ada _> home  ====================== ok
[ ] Hastag ======================================================= ok
[ ] Halaman khusus terbaru - terlama ============================= ok
[ ] Video ======================================================== ok
[ ] Gambar ======================================================= ok
[ ] Popup terbaru (1 aja) popup kaya tempochannel.com =============================================== ok
(https://tempochannel.com/detail/85/menikmati-kopi-malabar-tiada-duanya.html -> slide ke bawah) ===== ok
[ ] Home, Foto, Video, Others, About -> urutan menu ============== ok
[ ] Image detail anak2 nya ga dibatasin ========================== ok
[ ] Gausah map -> map ilangin ==================================== ok
[ ] Loading jgn muter, logo avinet yg A dari ga warna jd warna -> loading ====== ok
[10:28, 4/4/2019] Mas Anggoro: popup nya ga di slide khusus avinet ============ ok
[10:28, 4/4/2019] Mas Anggoro: kalo tempo kan di slide ke bwah baru muncul ==== ok




Math.ceil(Math.random() * 3);


1. pisahkan kumpulan id image dan id video                      ok semua
2. buat query param get buat kumpulan id video
3  yng kumpulan id video tinggal di input di api nya 
4. api download zip tambah bisa video

function getSame(arr){
    var hasil = arr.filter((r)=>{
        if(r.isVideo == true){
          return r
        }
    })
    return last = hasil.map((item) => (item.id));
}

mas background nya diubah jadi image random yang ada di arsip_image,textnya dikasih shadow hitam agar tetap kelihatan ==== ok
image developernya di hapus ==================================== ok
 download dan likenya di pindah di  dalam pojok gambar ========= ok
video yang tampil yang statusnya 2 ============================================================ ok
videonya yang tampil hasil convert durasi 3 detik dan yang didownload video durasi full ======= ok
di setiap halaman  ditambah data  image terbaru =============================================== ok
oh iya mas history download video dan image bisa digabung aja ga?jadi ga dipisah tabel ======== ok
mas di cms dashboard disuruh buat seperti itu, data visitornya  dari portal =================== ok
Iya mas, saya video convertnya saya namain out_namavideoasli ================================== ok

history doanload di profile belum nampilin vdio doanloaded ======= ok
buat satu lagi aja husus histori download video ================= ok

ketika download di cart user downlod nya nambah === ok
ketika download di cart video downlod nya nambah === ok 
ketika download di cart video downlod history nya nambah === ok



16 april 2019

ukuran popup video home di ubah seperti card 370 ===== ok

top download diubah jadi top 5 aja, dan developernya dihilangkan dan download likenya dipindahkan kedalam gambar === ok

jaraknya di perkecil ==== ok

mas ini maksdunya  waktu di detail kan ada tags misalnya diklik tag document nah waktu diklik 
munculnya arsip dengan tags document,ini di tambahin keterangan tagsnya apa kan yang sekarang hanya photo saja == ok
========================
gradient for selina
background: linear-gradient(135deg, #2c4abf 0%, #2c4abf 35%, #a80077 60%, #[object Object] 99%);

or

background: rgb(2,0,36);
background: linear-gradient(160deg, rgba(2,0,36,1) 0%, rgba(80,80,159,1) 55%, rgba(0,212,255,1) 100%);

.text {
	text-transform: uppercase;
	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font: {
		size: 20vw;
		family: $font;
	};
}
===================
.border { 
    width: 400px;
    padding: 20px;
    border-top: 10px solid #FFFF00;
    border-bottom:10px solid #FF0000;
    background-image: 
        linear-gradient(#FFFF00, #FF0000),
        linear-gradient(#FFFF00, #FF0000)
    ;
    background-size:10px 100%;
    background-position:0 0, 100% 0;
    background-repeat:no-repeat;
}
==========
.buttonTwo {
    border-image: linear-gradient(to left, #2e98bc 0%, #c66262 100%);
    border-image-slice: 1;
    border-width: 3px;
}

========
.cube {
  margin: 50px auto;
  width: 300px;
  height: 300px;
  border-left: 15px solid #3EC8AC;
  border-right: 15px solid #4E90A4;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 20px;
  -moz-background-size: 100% 20px;
  background-size: 100% 20px;
  background-image: -webkit-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%), -webkit-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%);
  background-image: -moz-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%), -moz-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%);
  background-image: -o-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%), -o-linear-gradient(left, #3EC8AC 0%, #4E90A4 100%);
  background-image: linear-gradient(to right, #3EC8AC 0%, #4E90A4 100%), linear-gradient(to right, #3EC8AC 0%, #4E90A4 100%);
}
background-image: linear-gradient(167deg, #49b2ba, #cc0379);
https://www.file-up.org/lbef1hj29rg4 e-office ci

she.id
arah.com
bmc.or.id
i-pangan.com
golf.hahabid.com
avinet.online
https://newkutagolf.co.id/
https://wirausahabrilian.bri.co.id/

16:08, 6/20/2019] Anugrah: Revisinya om:
[16:08, 6/20/2019] Anugrah: 1. ukuran header image dikecilin 1920x420 px
[16:08, 6/20/2019] Anugrah: 2. recent upload yang ada fotonya di footer dihilangkan aja ==== ok
[16:08, 6/20/2019] Anugrah: 3. teks search here di atas boks dihilangkan aja, diganti teks search here di dalam box pencarian  === ok
[16:08, 6/20/2019] Anugrah: 4. file terbaru di bawah header dan foto2nya dihilangkan jadi langsung header, bawahnya lgsg kategori === ok
[16:08, 6/20/2019] Anugrah: revisi avinet



<Router>
  <div>
    <Route exact path="/" component={DummyIndex} /> 
    <Route path="/login" component={Login} />
    <Route path="/home" component={Home} />
    <Route path="/about" component={About} />
    <Route path="/etc" component={Etc} />
  </div>
</Router>
Then in Home Component, define your routes

class Home extends Component {
  render() {
    return <div>
      <Route exact path={this.props.match.path} component={HomeDefault} />
      <Route path={`${this.props.match.path}/one`} component={HomePageOne} />
      <Route path={`${this.props.match.path}/two`} component={HomePageTwo} />
    </div>
  }
}


*/