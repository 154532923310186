const INITIAL_STATE = {
    cart: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'input_cart':
            return { ...state, cart: action.payload }
        case 'empty_cart':
            return { ...state, cart: [] }
        case 'add_qty':
            return { ...state, cart: action.payload }
        case 'min_qty':
            return { ...state, cart: action.payload }
        case 'remove_one':
            return { ...state, cart: action.payload }


        default:
            return state;
    }
};
