import React, { Component } from 'react'
import ContentLoader from 'react-content-loader'
class CardLoaderRec extends Component {
  render () {
    return (
        <ContentLoader>
          <rect x='0' y='0' rx='0' ry='0'  width='100%' height='100%' />
        </ContentLoader>
    )
  }
}

export default CardLoaderRec
