import React, { Component } from "react";
import Img from "react-image";
import { NavLink } from "react-router-dom";
import CardLoadeRec from "./CardLoaderRect";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
class Card269VideoPop extends Component {
  state = {
    isSwal: false,
  };
  handleSendLike = async () => {
    // usid imid
    if (this.props.usid) {
      try {
        const response = await fetch(`${global.apiUrl}video/sendlike`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoid: this.props.imid,
            userid: this.props.usid,
          }),
        });
        const data = await response.json();
        if (data.success == true) {
          this.props.actionAfterLike();
        } else {
        }
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      this.setState({ isSwal: true });
      // alert('anda harus login dahulu')
    }
  };
  
  onHover=(p)=>{
    alert(p)
  }
  

  render() {
    return (
      <div className="col-md-11">
        <div className="single-item-grid">
          <div
            // onClick={this.props.toDetail}
            className="item-img"
            style={{
              height: 145,
              overflow: "hidden",
              backgroundColor: "white",
            }}
            onMouseOver={this.props.onHover}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              url={this.props.imgurl}
              // controls
              playing={this.props.isPlay}
              onClick={this.props.toDetail}
            />
             <div className="overlaydownloadandlikevideo" style={{zIndex:99}}>
           <div className='' style={{position:'absolute',right:0,bottom:10}}>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload' style={{color:'white'}}>
                  {this.props.download}
                </span>{' '}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red', cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{ cursor:'pointer' }}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              )}
            </div>
            </div>
          </div>
          <div className="item-content">
            <div className="item-info">
            <h3>Video</h3>
              <h3>
                <NavLink className="frutigerFont" to={`${this.props.urldetail}`}>
                  {this.props.title}
                </NavLink>
              </h3>
              <NavLink
                to={{
                  pathname: "/video",
                  state: { image_id: this.props.image_id },
                }}
              >
                <span className="frutigerFont">{this.props.catName}</span>
              </NavLink>
              {/* <div className="price">$15</div> */}
            </div>
            {/* <div className="item-profile">
              <div className="profile-title">
                <div className="img-wrapper">
                  <img
                    src={this.props.userPict}
                    alt="profile"
                    className="img-responsive img-circle"
                  />
                </div>
                <span>{this.props.author}</span>
              </div>
              <div className="profile-rating">
                <ul>
                  <i className="fa fa-download" aria-hidden="true">
                    {" "}
                    <span className="likeanddownload">
                      {this.props.download}
                    </span>{" "}
                  </i>
                  &nbsp;
                  {this.props.youLike ? (
                    <i
                      className="fa fa-heart"
                      style={{ color: "red", cursor: "pointer" }}
                      aria-hidden="true"
                      onClick={this.handleSendLike}
                    >
                      <span className="likeanddownload">
                        {" "}
                        {this.props.like}
                      </span>
                    </i>
                  ) : (
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-heart-o"
                      aria-hidden="true"
                      onClick={this.handleSendLike}
                    >
                      <span className="likeanddownload">
                        {" "}
                        {this.props.like}
                      </span>
                    </i>
                  )}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <SweetAlert
          show={this.state.isSwal}
          title="Perhatian"
          confirmButtonText="Close"
          type="warning"
          text="Anda Harus Login Terlebih Dahulu"
          showCancelButton={false}
          onConfirm={() => this.setState({ isSwal: false })}
        />
      </div>
    );
  }
}

export default Card269VideoPop;
