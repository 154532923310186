import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom'
import {dummyLogin,dummyLogout,login, getProfile, closeswallogin,closeloginsuccess} from '../redux/actions';
import SweetAlert from 'sweetalert2-react';
class Header extends Component {
    constructor() {
        super()
        this.state = {
            activeNavLink:'/detail',
            email:'',
            password:'',
            isLoginFailed:false,
            classSticky:''
           // loginberhasil:false
        }
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.setAttribute('id', 'mainloginbutton');
        script.src = "/js/mainloginbutton.js";
        script.async = true;
        document.body.appendChild(script);
        //console.log(this.props.token)
        if(this.props.token !== ''){
            this.props.getProfile(this.props.token)
        }
        window.addEventListener('scroll', this.handleScroll);
    }

      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }
        
      
      handleScroll = () => {
        var lastScrollY = window.scrollY;
        if(lastScrollY > 67){
            this.setState({classSticky:'stick'})
        }else{
            this.setState({classSticky:''})
        }
      };


    shouldComponentUpdate(nextProps, nextState){
        if(this.props.isLogin !== nextProps.isLogin){
            const script = document.createElement("script");
            script.setAttribute('id', 'mainloginbutton');
            script.src = "/js/mainloginbutton.js";
            script.async = true;
            document.body.appendChild(script);
            window.location.reload()
            return true
        }
        if(this.props.acr !== nextProps.acr){
           // console.log('wekwew')
            return true
        }
        // if(this.props.loginberhasil !== nextProps.loginberhasil){
        //     console.log(nextProps.loginberhasil)
        //     return true
        // }
        return true
       
    }

    handleLogin=(e)=>{
        e.preventDefault()
        if(this.state.email !== '' && this.state.password !== ''){
            this.props.login(this.state.email, this.state.password)
        }else{
            this.setState({isLoginFailed:true})
        }
        
    }
    handleLogout=()=>{
        this.props.dummyLogout()
    }

    render() {
        const fullnama = this.props.userName.split(' ')
        const firstname = fullnama[0].charAt(0).toUpperCase()+fullnama[0].slice(1);

        if (this.props.isLogin) {
            return (
                <header>
                    <div id="header2" className="header2-area">
                        <div className="header-top-bar" style={{ position:'absolute'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 hidden-xs">
                                        <div className="logo-area">
                                            <NavLink to="/"><img className="img-responsive big-none" style={{height:50}} src="/img/avinet/logo.png" alt="logo" /></NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-9 col-xs-12">
                                        <ul className="profile-notification">
                                            <li>
                                                <div className="notify-contact">
                                                {/* <span>Need help?</span> Talk to an expert: +61 3 8376 6284 */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="notify-notification">
                                                    <NavLink to="#" style={{backgroundColor:'white', display:'none'}}>
                                                    {/* <i className="fa fa-bell-o" aria-hidden="true"></i> */}
                                                    {/* <span>8</span> */}
                                                    </NavLink>
                                                    {/* <ul>
                                                        <li>
                                                            <div className="notify-notification-img">
                                                                <img className="img-responsive" src="/img/profile/1.png" alt="profile" />
                                                            </div>
                                                            <div className="notify-notification-info">
                                                                <div className="notify-notification-subject">Need WP Help!</div>
                                                                <div className="notify-notification-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-notification-sign">
                                                                <i className="fa fa-bell-o" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="notify-notification-img">
                                                                <img className="img-responsive" src="/img/profile/2.png" alt="profile" />
                                                            </div>
                                                            <div className="notify-notification-info">
                                                                <div className="notify-notification-subject">Need HTML Help!</div>
                                                                <div className="notify-notification-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-notification-sign">
                                                                <i className="fa fa-bell-o" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="notify-notification-img">
                                                                <img className="img-responsive" src="/img/profile/3.png" alt="profile" />
                                                            </div>
                                                            <div className="notify-notification-info">
                                                                <div className="notify-notification-subject">Psd Template Help!</div>
                                                                <div className="notify-notification-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-notification-sign">
                                                                <i className="fa fa-bell-o" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="notify-message">
                                                    {/* <NavLink to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i><span>5</span></NavLink> */}
                                                    <ul>
                                                        <li>
                                                            <div className="notify-message-img">
                                                                <img className="img-responsive" src="/img/profile/1.png" alt="" />
                                                            </div>
                                                            <div className="notify-message-info">
                                                                <div className="notify-message-sender">Kazi Fahim</div>
                                                                <div className="notify-message-subject">Need WP Help!</div>
                                                                <div className="notify-message-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-message-sign">
                                                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="notify-message-img">
                                                                <img className="img-responsive" src="/img/profile/2.png" alt="profile" />
                                                            </div>
                                                            <div className="notify-message-info">
                                                                <div className="notify-message-sender">Richi Lenal</div>
                                                                <div className="notify-message-subject">Need HTML Help!</div>
                                                                <div className="notify-message-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-message-sign">
                                                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="notify-message-img">
                                                                <img className="img-responsive" src="/img/profile/3.png" alt="profile" />
                                                            </div>
                                                            <div className="notify-message-info">
                                                                <div className="notify-message-sender">PsdBosS</div>
                                                                <div className="notify-message-subject">Psd Template Help!</div>
                                                                <div className="notify-message-date">01 Dec, 2016</div>
                                                            </div>
                                                            <div className="notify-message-sign">
                                                                <i className="fa fa-reply" aria-hidden="true"></i>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cart-area">
                                                    <NavLink to="/cart">
                                                    {/* <i className="fa fa-save" aria-hidden="true"> */}
                                                    <img src="/img/down-icon.png" style={{height : 18, marginTop:-5}} />
                                                    {/* </i> */}
                                                    {
                                                        this.props.cart.length > 0 && <span>{this.props.cart.length}</span>
                                                    }
                                                   
                                                    </NavLink>
                                                    {/* <ul>
                                                        <li>
                                                            <div className="cart-single-product">
                                                                <div className="media">
                                                                    <div className="pull-left cart-product-img">
                                                                        <NavLink to="#">
                                                                            <img className="img-responsive" alt="product" src="/img/product/more2.jpg" />
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className="media-body cart-content">
                                                                        <ul>
                                                                            <li>
                                                                                <h1><NavLink to="#">Product Title Here</NavLink></h1>
                                                                                <h2><span>Code:</span> STPT600</h2>
                                                                            </li>
                                                                            <li>
                                                                                <p>X 1</p>
                                                                            </li>
                                                                            <li>
                                                                                <p>$49</p>
                                                                            </li>
                                                                            <li>
                                                                                <NavLink to="#" className="trash" href="#"><i className="fa fa-trash-o"></i></NavLink>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="cart-single-product">
                                                                <div className="media">
                                                                    <div className="pull-left cart-product-img">
                                                                        <NavLink to="#">
                                                                            <img className="img-responsive" alt="product" src="/img/product/more3.jpg" />
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className="media-body cart-content">
                                                                        <ul>
                                                                            <li>
                                                                                <h1><NavLink to="#">Product Title Here</NavLink></h1>
                                                                                <h2><span>Code:</span> STPT460</h2>
                                                                            </li>
                                                                            <li>
                                                                                <p>X 1</p>
                                                                            </li>
                                                                            <li>
                                                                                <p>$75</p>
                                                                            </li>
                                                                            <li>
                                                                                <NavLink to="#" className="trash" href="#"><i className="fa fa-trash-o"></i></NavLink>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <table className="table table-bordered sub-total-area">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Total</td>
                                                                        <td>$124</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Discount</td>
                                                                        <td>$30</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Vat(20%)</td>
                                                                        <td>$18.8</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Sub Total</td>
                                                                        <td>$112.8</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </li>
                                                        <li> */}
                                                            {/* <ul className="cart-checkout-btn">
                                                                <li><NavLink to="cart" className="btn-find"><i className="fa fa-shopping-cart" aria-hidden="true"></i>Go to Cart</NavLink></li>
                                                                <li><NavLink to="checkout" className="btn-find"><i className="fa fa-share" aria-hidden="true"></i>Go to Checkout</NavLink></li>
                                                            </ul> */}
                                                        {/* </li>
                                                    </ul> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="user-account-info">
                                                    <div className="user-account-info-controler">
                                                        <div className="user-account-img" 
                                                        style={{height:34,width:34, overflow:'hidden', backgroundColor:'white'}}
                                                        >
                                                            <img className="img-responsive" src={this.props.pict} alt=""
                                                             style={{
                                                                objectFit: 'cover',
                                                                height:'100%',
                                                               }}
                                                            />
                                                        </div>
                                                        <div className="user-account-title">
                                                            <div className="user-account-name"> &nbsp;{`${firstname}`}</div>
                                                            {/* <div className="user-account-balance">$171.00</div> */}
                                                        </div>
                                                        <div className="user-account-dropdown">
                                                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li><NavLink to="/profile" className="frutigerFont">Profile Page</NavLink></li>
                                                       
                                                        {/* <li><NavLink to="/setting">Account Setting</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Downloads</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Wishlist</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Upload Item</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Statement</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Withdraws</NavLink></li> */}
                                                    </ul>
                                                </div>
                                            </li>
                                            <li><NavLink to="#" className="apply-now-btn" id="logout-button" onClick={this.handleLogout}>Logout</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`main-menu-area bg-primaryText ${this.state.classSticky}`}  id="sticker">
                            <div className="container">
                                <nav id="desktop-nav" style={{display: 'flex',
//   justifyContent: 'center'
  }}>
                                    <ul>
                                        <li>
                                        <NavLink style={{height:0}} to="/"><img className="img-responsive" style={{height:50,marginTop:-14}} src="/img/avinet/logo.png" alt="logo" /></NavLink>
                                        </li>
                                        <li><NavLink activeStyle={{color:'orange'}}  exact to="/">Home</NavLink>
                                            {/* <ul> */}
                                                {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/favorite">Favorite</NavLink></li> */}
                                                {/* <li><NavLink to="index2.html">Home 2</NavLink></li> */}
                                            {/* </ul> */}
                                        </li>
                                        {/* <li><NavLink activeStyle={{color:'orange'}}  exact to="/photo">Photo</NavLink> */}
                                                    {/* <ul>
                                                      <li><NavLink activeStyle={{color:'orange'}}  exact to="/favorite">Favorite</NavLink></li>
                                                    </ul> */}
                                        {/* </li> */}
                                        <li><NavLink activeStyle={{color:'orange'}}  exact to="/video">Video</NavLink>
                                                    {/* <ul>
                                                      <li><NavLink activeStyle={{color:'orange'}}  exact to="/favorite-video">Favorite</NavLink></li>
                                                    </ul> */}
                                        </li>
                                        <li><NavLink activeStyle={{color:'orange'}}  exact to="/arsip">Design</NavLink></li>
                                        {/* <li><NavLink activeStyle={{color:'orange'}}  exact to="/favorite">Favorite</NavLink></li> */}
                                        <li><NavLink activeStyle={{color:'orange'}}  exact to="/about">About</NavLink></li>
                                        
                                       
                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/category">Category</NavLink></li> */}
                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/contact">Contact</NavLink></li> */}
                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/help">Help</NavLink></li> */}
                                        {/* <li className="active"><NavLink to="#">Pages</NavLink>
                                            <ul className="mega-menu-area">
                                                <li>
                                                    <NavLink to="index.html">Home 1</NavLink>
                                                    <NavLink to="index2.html">Home 2</NavLink>
                                                    <NavLink to="about.html">About</NavLink>
                                                    <NavLink to="product-page-grid.html">Product Grid</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="product-page-list.html">Product List</NavLink>
                                                    <NavLink to="product-category-grid.html">Category Grid</NavLink>
                                                    <NavLink to="product-category-list.html">Category List</NavLink>
                                                    <NavLink to="single-product.html">Product Details</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="profile.html">Profile</NavLink>
                                                    <NavLink to="favourites-grid.html">Favourites Grid</NavLink>
                                                    <NavLink to="favourites-list.html">Favourites List</NavLink>
                                                    <NavLink to="settings.html">Settings</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="upload-products.html">Upload Products</NavLink>
                                                    <NavLink to="sales-statement.html">Sales Statement</NavLink>
                                                    <NavLink to="withdrawals.html">Withdrawals</NavLink>
                                                    <NavLink to="404.html">404</NavLink>
                                                </li>
                                            </ul>
                                        </li> */}
                                        {/* <li><NavLink to="product-page-grid.html">WordPress</NavLink></li>
                                        <li><NavLink to="product-category-grid.html">Joomla</NavLink></li>
                                        <li><NavLink to="product-category-list.html">Plugins</NavLink></li>
                                        <li><NavLink to="product-page-list.html">Components</NavLink></li>
                                        <li><NavLink to="product-category-grid.html">PSD</NavLink></li>
                                        <li><NavLink to="#">Blog</NavLink>
                                            <ul>
                                                <li><NavLink to="blog.html">Blog</NavLink></li>
                                                <li><NavLink to="single-blog.html">Blog Details</NavLink></li>
                                                <li className="has-child-menu"><NavLink to="#">Second Level</NavLink>
                                                    <ul className="thired-level">
                                                        <li><NavLink to="index.html">Thired Level 1</NavLink></li>
                                                        <li><NavLink to="index.html">Thired Level 2</NavLink></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="contact.html">Contact</NavLink></li>
                                        <li><NavLink to="help.html">Help</NavLink></li> */}
                                        
                                    </ul>
                                    <ul style={{position:'absolute',top:0,right:0}}>
                                    <li>
                                                <div className="cart-area">
                                                    <NavLink to="/cart">
                                                    {/* <i className="fa fa-save" aria-hidden="true">
                                                    </i> */}
                                                    <img src="/img/down-icon.png" style={{height : 18, marginTop:-5}} />
                                                    {
                                                        this.props.cart.length > 0 && <span>{this.props.cart.length}</span>
                                                    }
                                                   
                                                    </NavLink>
                                                    </div>
                                                    </li>
                                         <li>
                                                <div className="user-account-info" style={{marginLeft:20,marginRight:15,position: 'relative',top: 14}}>
                                                    <div className="user-account-info-controler" style={{width: 114}}>
                                                        <div className="user-account-img" 
                                                        style={{height:34,width:34, overflow:'hidden'}}
                                                        >
                                                            <img className="img-responsive" src={this.props.pict} alt=""
                                                             style={{
                                                                objectFit: 'cover',
                                                                height:'100%',
                                                               }}
                                                            />
                                                        </div>
                                                        <div className="user-account-title" style={{marginTop:6}}>
                                                            <div className="user-account-name" style={{color:'white'}}> &nbsp;{`${firstname}`}</div>
                                                            {/* <div className="user-account-balance">$171.00</div> */}
                                                        </div>
                                                        <div className="user-account-dropdown" style={{marginTop:6,marginLeft:-26}}>
                                                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <ul style={{marginTop:-10,backgroundColor:'rgb(2,65,130)'}}>
                                                        <li><NavLink to="/profile" className="frutigerFont" style={{color:'orange'}}>Profile Page</NavLink></li>
                                                       
                                                        {/* <li><NavLink to="/setting">Account Setting</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Downloads</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Wishlist</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Upload Item</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Statement</NavLink></li> */}
                                                        {/* <li><NavLink to="#">Withdraws</NavLink></li> */}
                                                    </ul>
                                                </div>
                                            </li>
                                        <li>
                                        <NavLink to="#" style={{}} className="" id="logout-button" onClick={this.handleLogout}>Logout</NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-menu-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mobile-menu">
                                        <nav id="dropdown">
                                            <ul>
                                            <li><NavLink activeStyle={{color:'red'}}  exact to="/">Home</NavLink>
                                            {/* <ul>
                                                <li>
                                                    <NavLink activeStyle={{color:'red'}}  exact to="/favorite">Favorite</NavLink>
                                                    </li> */}
                                                {/* <li><NavLink to="index2.html">Home 2</NavLink></li> */}
                                            {/* </ul> */}
                                        </li>
                                         {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/photo">Photo</NavLink> */}
                                                    {/* <ul>
                                                      <li><NavLink activeStyle={{color:'red'}}  exact to="/favorite">Favorite</NavLink></li>
                                                    </ul> */}
                                        {/* </li> */}
                                        <li><NavLink activeStyle={{color:'red'}}  exact to="/video">Video</NavLink>
                                        {/* <ul>
                                                      <li><NavLink activeStyle={{color:'red'}}  exact to="/favorite-video">Favorite</NavLink></li>
                                                    </ul> */}
                                        </li>
                                        <li><NavLink activeStyle={{color:'red'}}  exact to="/arsip">Design</NavLink></li>
                                        {/* <li><NavLink activeStyle={{color:'orange'}}  exact to="/favorite">Favorite</NavLink></li> */}
                                        <li><NavLink activeStyle={{color:'red'}}  exact to="/about">About</NavLink></li>

                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/about">About</NavLink></li>
                                        
                                        <li><NavLink activeStyle={{color:'red'}}  exact to="/product">Photo</NavLink>
                                                    <ul>
                                                      <li><NavLink activeStyle={{color:'red'}}  exact to="/favorite">Favorite</NavLink></li>
                                                    </ul>
                                        </li> */}
                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/category">Category</NavLink></li> */}
                                        {/* <li><NavLink activeStyle={{color:'red'}}  exact to="/contact">Contact</NavLink></li>
                                        <li><NavLink activeStyle={{color:'red'}}  exact to="/help">Help</NavLink></li> */}
                                                {/* <li className="active"><NavLink to="#">Home</NavLink>
                                                    <ul>
                                                        <li><NavLink to="index.html">Home 1</NavLink></li>
                                                        <li><NavLink to="index2.html">Home 2</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="about.html">About</NavLink></li>
                                                <li><NavLink to="#">Pages</NavLink>
                                                    <ul className="mega-menu-area">
                                                        <li>
                                                            <NavLink to="index.html">Home 1</NavLink>
                                                            <NavLink to="index2.html">Home 2</NavLink>
                                                            <NavLink to="about.html">About</NavLink>
                                                            <NavLink to="product-page-grid.html">Product Grid</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="product-page-list.html">Product List</NavLink>
                                                            <NavLink to="product-category-grid.html">Category Grid</NavLink>
                                                            <NavLink to="product-category-list.html">Category List</NavLink>
                                                            <NavLink to="single-product.html">Product Details</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="profile.html">Profile</NavLink>
                                                            <NavLink to="favourites-grid.html">Favourites Grid</NavLink>
                                                            <NavLink to="favourites-list.html">Favourites List</NavLink>
                                                            <NavLink to="settings.html">Settings</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="upload-products.html">Upload Products</NavLink>
                                                            <NavLink to="sales-statement.html">Sales Statement</NavLink>
                                                            <NavLink to="withdrawals.html">Withdrawals</NavLink>
                                                            <NavLink to="404.html">404</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="product-page-grid.html">WordPress</NavLink></li>
                                                <li><NavLink to="product-category-grid.html">Joomla</NavLink></li>
                                                <li><NavLink to="product-category-list.html">Plugins</NavLink></li>
                                                <li><NavLink to="product-page-list.html">Components</NavLink></li>
                                                <li><NavLink to="product-category-grid.html">PSD</NavLink></li>
                                                <li><NavLink to="#">Blog</NavLink>
                                                    <ul>
                                                        <li><NavLink to="blog.html">Blog</NavLink></li>
                                                        <li><NavLink to="single-blog.html">Blog Details</NavLink></li>
                                                        <li className="has-child-menu"><NavLink to="#">Second Level</NavLink>
                                                            <ul className="thired-level">
                                                                <li><NavLink to="index.html">Thired Level 1</NavLink></li>
                                                                <li><NavLink to="index.html">Thired Level 2</NavLink></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="contact.html">Contact</NavLink></li>
                                                <li><NavLink to="help.html">Help</NavLink></li>
                                                */}
                                            </ul> 
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <SweetAlert
                show={this.props.loginberhasil}
                title="Login Berhasil"
                confirmButtonText="Close"
                type="success"
                text='Anda Berhasil Login'
                showCancelButton={false}
                onConfirm={()=>this.props.closeloginsuccess()}/> */}
                </header>
            )
        } else {
            return (
                    null
            );
        }

    }
}
const mapStateToProps = state => {
    const { userName, isLogin, token, pict } = state.profile
    const { showSwalLoginFailed } = state.freg
    const { loginberhasil } = state.lo
    const { cart } = state.cart
    return { userName, isLogin, token , showSwalLoginFailed, cart,loginberhasil,pict }
}

export default connect(mapStateToProps,{dummyLogin,dummyLogout, login, getProfile, closeswallogin,closeloginsuccess})(withRouter(Header));
