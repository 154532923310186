import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Card208Video from "../components/Card208Video";
import Card269VideoHome from "../components/Card269VideoHome";
import Card239Video from "../components/Card239Video";
import SearchInput from "../components/SearchInput";
import { Animated } from "react-animated-css";
import Loader from "../components/Loader";
import FilteNoPopUp from '../components/FilterNoPopUp';
import Dropdown from "react-dropdown-modal";
class Video extends Component {
  constructor() {
    super();
    this.state = {
      listImage: [],
      listCat: [],
      topten: [],
      activeCat: "",
      activeShort: "",
      totalPage: 0,
      activePage: 1,
      page: 1,
      totalImage: 0,
      loading: true,
      isPlayingId:0,
      catVideoDokumentasi:'',
      searchFilterText:'',
      searchFilterTags:'',
      startdate:'',
      enddate:'',
      rightCount:0,
      rightCount2:-320,
      
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchListImage();
    this.fetchListCat();
    this.fetchTopTen();
    document.title = "Avinet | Video";
    const script = document.createElement("script");
    script.setAttribute("id", "forpriceslider");
    script.src = "/js/mainpriceslider.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.setAttribute("id", "geser");
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);
    window.addEventListener("resize", this.resize);
    this.resize()

    // console.log(this.props.location.state);
  }

  resize = ()=>{
    this.setState({mobileWidth: window.innerWidth <= 500})
    
}

  componentWillUnmount() {
    var todelete = document.getElementById("forpriceslider");
    todelete.remove();

    var todelete2 = document.getElementById("geser");
    todelete2.remove();
  }

  fetchListImage = async () => {
    // this.setState({loading:true})

    try {
      let url = `${global.apiUrl}video/listvideo?uid=${this.props.userid}&c=${this.state.catVideoDokumentasi}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}&p=${this.state.page}`;
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.image_id > 0) {
          url = `${global.apiUrl}video/listvideo?uid=${
            this.props.userid
          }&c=${this.props.location.state.image_id}&p=${this.state.page}`;
        } else if (this.props.location.state.image_tag > 0) {
          url = `${global.apiUrl}video/videotag?uid=${this.props.userid}&tag=${
            this.props.location.state.image_tag
          }&p=${this.state.page}`;
        }
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false,
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchListImageTwo = async () => {
    // this.setState({loading:true})

    try {
      let url = `${global.apiUrl}video/listvideo?uid=${this.props.userid}&c=${this.state.catVideoDokumentasi}&s=${this.state.searchFilterText}&tag=${this.state.searchFilterTags}&start=${this.state.startdate}&end=${this.state.enddate}&p=${this.state.page}`;
     
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false,
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };


  fetchListCat = async () => {
    try {
      const response = await fetch(`${global.apiUrl}category/listvideocat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listCat: data.data, totalImage: data.totalimage });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectCat = async cat => {
    try {
      await this.setState({ activeCat: cat });
      const response = await fetch(
        `${global.apiUrl}video/listvideo?c=${
          this.state.activeCat
        }&uid=${this.props.userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listImage: data.detail });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectCatAndShort = async so => {
    try {
      await this.setState({ activeShort: so });
      const response = await fetch(
        `${global.apiUrl}video/listvideo?c=${
          this.state.activeCat
        }&so=${this.state.activeShort}&uid=${this.props.userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listImage: data.detail });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectPage = async page => {
    try {
      await this.setState({ page: page, activePage: page });
      const response = await fetch(
        `${global.apiUrl}video/listvideo?c=${
          this.state.activeCat
        }&so=${this.state.activeShort}&uid=${this.props.userid}&p=${this.state.page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listImage: data.detail });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchTopTen = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}video/topten?uid=${this.props.userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ topten: data.detail });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleAfterLike = async iid => {
    const myArray = this.state.listImage;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listImage: myArray });
  };

  handleAfterLike2 = async iid => {
    const myArray = this.state.listImage;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listImage: myArray });
  };

  handleAfterLike3 = async iid => {
    const myArray = this.state.topten;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ topten: myArray });
  };

  handlePlayHover=(id)=>{
    this.setState({isPlayingId:id})
    setTimeout(()=>{this.setState({isPlayingId:0})},3000)

  }

  handleFetchOnCheckBokFoto = (parfoto) =>{
    if(parfoto == true){
      this.setState({catVideoDokumentasi:4},this.fetchListImageTwo)
    }else{
      this.setState({catVideoDokumentasi:''},this.fetchListImageTwo)
    }
    
  }
  handleFetchOnCheckBokVideo = (parfoto) =>{
    if(parfoto == true){
      this.setState({catVideoDokumentasi:5},this.fetchListImageTwo)
    }else{
      this.setState({catVideoDokumentasi:''},this.fetchListImageTwo)
    }
   
  }
  handleInput=(searchFilterText)=>{
    this.setState({searchFilterText},this.fetchListImageTwo)
  }
  handleConsoleTags = (searchFilterTags) => {
    this.setState({searchFilterTags},this.fetchListImageTwo)
  }
  handleChangeDateStart = (startdate) => {
    // console.log(startdate)
    this.setState({startdate},this.fetchListImageTwo)
  }
  handleChangeDateEnd = (enddate) => {
    // console.log(enddate)
    this.setState({enddate},this.fetchListImageTwo)
  }
  handleOpenFilter = () => {
     if(this.state.mobileWidth){
      this.setState({
        rightCount:280,
        rightCount2:-326
      });
    }else{
      this.setState({
        rightCount:280,
        rightCount2:-278
      });
    }
    
    if(this.state.rightCount > 0){
      this.setState({rightCount:0,rightCount2:-320})
    }
  };

  render() {
    let pagePrev = this.state.page - 1;
    let curPage = this.state.page;
    let nextPage = this.state.page + 1;
    return (
      <div style={{marginRight:this.state.rightCount,position:'relative',transition: 'all 0.5s ease'}}>
        {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }

            <div className="container-nopop" style={{right:this.state.rightCount2}}>
            <FilteNoPopUp 
                pressClose={this.handleOpenFilter}
                openProps={this.state.openFilter} 
                closeFilter={()=>this.setState({openFilter:!this.state.openFilter})}
                cekBokFoto={(par)=>this.handleFetchOnCheckBokFoto(par)}
                cekBokVideo={(par)=>this.handleFetchOnCheckBokVideo(par)}
              //  cekBokDesign={(par)=>this.handleFetchOnCheckBokDesign(par)}
                inputSearch={(txt)=>this.handleInput(txt)}
                tagsinput={(txt)=>this.handleConsoleTags(txt)}
                changeDateStart={(dd)=>this.handleChangeDateStart(dd)}
                changeDateEnd={(dd)=>this.handleChangeDateEnd(dd)}
              />
              </div>

        <SearchInput
          onChange={e => this.setState({ searchtext: e.target.value })}
          vall={this.state.searchtext}
        />

        <Animated
          animationIn=""
          animationOut="fadeOut"
          isVisible={this.state.loading}
        >
          <Loader />
        </Animated>


        <div
          className="isotope-classNamees-tab isotop-box-btn frutigerFont listcategory"
          style={{ backgroundColor: "white", width: "100%", 
          height:50,display:'flex',justifyContent:'flex-end',
          paddingTop:15
          }}
        >
          <div
            className="page-controls-sorting"
            style={{ marginTop: -5, padding: 0 }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                //  style={{border: '1px solid black',
                //   padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                {/* SORT BY */}
                {/* <i className="fa fa-angle-down" aria-hidden="true" /> */}
              </button>
              {/* <ul className="dropdown-menu">
                <li>
                  <NavLink to="#" onClick={() => console.log("DESC")}>
                    NEWEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    OLDEST
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    FAVORITE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={() => console.log("ASC")}>
                    MOST DOWNLOAD
                  </NavLink>
                </li>
              </ul> */}
            </div>
          </div>
          <div
            onClick={this.handleOpenFilter}
            className=""
            style={{
               marginRight:15, 
               float: "right" }}
          >
            <div className="dropdown">
              <button
                className="btn sorting-btn dropdown-toggle frutigerFont"
                type="button"
                data-toggle="dropdown"
                style={{border: '1px solid lightgray',
                  padding: '5px 50px 5px 50px', borderRadius:0, marginTop:-7}}
              >
                {" "}
                FILTER
              </button>
            </div>
          </div>
          </div>

        <div className="pagination-area bg-secondary">
          <div className="container">
            {/* <div className="pagination-wrapper">
              <ul>
                <li>
                  <NavLink to="index.html">Home</NavLink>
                  <span> -</span>
                </li>
                &nbsp;<li>Video</li>
              </ul>
            </div> */}
          </div>
        </div>


        <div className="row" style={{backgroundColor:'#f4f6f5',padding:20}}>
              {/* <div className="col-lg-3">
              <FilteNoPopUp 
                               openProps={this.state.openFilter} 
                               closeFilter={()=>this.setState({openFilter:!this.state.openFilter})}
                               cekBokFoto={(par)=>this.handleFetchOnCheckBokFoto(par)}
                               cekBokVideo={(par)=>this.handleFetchOnCheckBokVideo(par)}
                              //  cekBokDesign={(par)=>this.handleFetchOnCheckBokDesign(par)}
                               inputSearch={(txt)=>this.handleInput(txt)}
                               tagsinput={(txt)=>this.handleConsoleTags(txt)}
                               changeDateStart={(dd)=>this.handleChangeDateStart(dd)}
                               changeDateEnd={(dd)=>this.handleChangeDateEnd(dd)}
                              />
              </div> */}
              <div className="col-lg-12" style={{
                display:'flex',
                flexWrap:'wrap',
                flexDirection:'row'
                }}>
              {this.state.listImage.map((r, i) => {
                            return (
                              <Card269VideoHome
                                key={i}
                                onHover={()=>this.handlePlayHover(r.arsiId)}
                                isPlay={this.state.isPlayingId == r.arsiId && true}
                                imgurl={r.cover}
                                title={r.arsiTitle}
                                catName={r.catName}
                                //     author={r.author}
                                download={r.download}
                                like={r.likeCount}
                                urldetail={`detail-video/${r.arsiId}`}
                                    toDetail={() =>
                                      this.props.history.push(`detail-video/${r.arsiId}`)
                                    }
                                userPict={r.authorPict}
                                imid={r.arsiId}
                                usid={this.props.userid}
                                    actionAfterLike={() =>
                                      this.handleAfterLike(r.arsiId)
                                    }
                                  youLike={r.youLike}
                              />
                            );
                          })}
              </div>
        </div>



        <div className="product-page-grid bg-secondary section-space-bottom">
          <div className="container">
            <div className="row">
              <div
                // className='col-lg-9 col-md-8 col-sm-8 col-xs-12 col-lg-push-3 col-md-push-4 col-sm-push-4'
              className='col-lg-12 col-md-12 col-sm-12 col-xs-12'
               >
                <div className="inner-page-main-body">
                  {/* <div className="page-controls">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8">
                        <div className="page-controls-sorting">
                          <div className="dropdown">
                            <button
                              className="btn sorting-btn dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Default Sorting
                              <i className="fa fa-sort" aria-hidden="true" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectCatAndShort("date")
                                  }
                                >
                                  Date
                                </NavLink>
                              </li>

                              <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectCatAndShort("download")
                                  }
                                >
                                  Most Download
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4">
                        <div className="layout-switcher">
                          <ul>
                            <li className="active">
                              <NavLink
                                to="#gried-view"
                                data-toggle="tab"
                                aria-expanded="false"
                              >
                                <i className="fa fa-th-large" />
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="#list-view"
                                data-toggle="tab"
                                aria-expanded="true"
                              >
                                <i className="fa fa-list" />
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane fade in active clear products-container"
                      id="gried-view"
                    >
                      <div className="product-grid-view padding-narrow">
                        <div className="row">
                         
                          {/* <Card269 /> <Card269 /> <Card269 /> <Card269 /> */}
                          {/* <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                                    <div className="single-item-grid">
                                                        <div className="item-img">
                                                            <img src="/img/product/24.jpg" alt="product" className="img-responsive"/>
                                                        </div>
                                                        <div className="item-content">
                                                            <div className="item-info">
                                                                <h3><NavLink to="/detail">Team Component Pro</NavLink></h3>
                                                                <span>Joomla Component</span>
                                                                <div className="price">$15</div>
                                                            </div>
                                                            <div className="item-profile">
                                                                <div className="profile-title">
                                                                    <div className="img-wrapper"><img src="/img/profile/1.jpg" alt="profile" className="img-responsive img-circle"/></div>
                                                                    <span>PsdBosS</span>
                                                                </div>
                                                                <div className="profile-rating">
                                                                    <ul>
                                                                    <i className="fa fa-download" aria-hidden="true"> <span className="likeanddownload">200</span> </i>&nbsp;
                                                                     <i className="fa fa-heart-o" aria-hidden="true"> <span className="likeanddownload">12</span></i>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ul className="mypaging">
                              {/* <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(1)
                                  }
                                >
                                  {`First`}
                                </NavLink>
                              </li> */}
                                {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {'<'}
                                  </NavLink>
                                </li>
                              )}

                              {curPage - 1 >= 1 && (
                                <li className='active' style={{ color: 'rgb(2,65,130)' }}>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {pagePrev}
                                  </NavLink>
                                </li>
                              )}

                              <li style={{ color: 'red' }}>
                                <NavLink
                                  to='#'
                                  className='active'
                                  style={{
                                    color:
                                      curPage == this.state.activePage && 'rgb(2,65,130)'
                                  }}
                                  onClick={() => this.handleSelectPage(curPage)}
                                >
                                  {curPage}
                                </NavLink>
                              </li>
                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {nextPage}
                                  </NavLink>
                                </li>
                              )}
                              
                              {
                                this.state.totalPage !== curPage && "..."
                              }
                              {
                                this.state.totalPage !== curPage && 
                                <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(this.state.totalPage)
                                  }
                                >
                                  {this.state.totalPage}
                                </NavLink>
                              </li>
                              }

                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                    to='#'
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {'>'}
                                  </NavLink>
                                </li>
                              )}
                              
                              {/* <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(this.state.totalPage)
                                  }
                                >
                                  {`Last`}
                                </NavLink>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane fade clear products-container"
                      id="list-view"
                    >
                      <div className="product-list-view">
                        

                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ul className="pagination-align-left">
                              {/* <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(1)
                                  }
                                >
                                  {`First`}
                                </NavLink>
                              </li> */}
                              {curPage - 1 >= 1 && (
                                <li className="active" style={{ color: "red" }}>
                                  <NavLink
                                    to="#"
                                    onClick={() =>
                                      this.handleSelectPage(pagePrev)
                                    }
                                  >
                                    {pagePrev}
                                  </NavLink>
                                </li>
                              )}

                              <li style={{ color: "red" }}>
                                <NavLink
                                  to="#"
                                  className="active"
                                  style={{
                                    color:
                                      curPage == this.state.activePage && "red",
                                  }}
                                  onClick={() => this.handleSelectPage(curPage)}
                                >
                                  {curPage}
                                </NavLink>
                              </li>
                              {nextPage <= this.state.totalPage && (
                                <li>
                                  <NavLink
                                    to="#"
                                    onClick={() =>
                                      this.handleSelectPage(nextPage)
                                    }
                                  >
                                    {nextPage}
                                  </NavLink>
                                </li>
                              )}
                              {/* <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(this.state.totalPage)
                                  }
                                >
                                  {`Last`}
                                </NavLink>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  return { userName, isLogin, userid };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(Video));
// export default withRouter(ProductListCategory)
