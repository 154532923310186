import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
import renderHTML from 'react-render-html';
class Help extends Component {
  constructor () {
    super()
    this.state = {
        help : []
    }
  }

  componentDidMount() {
    document.title = "Avinet | Help"
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.fetchHelp()
}

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();
}

fetchHelp = async () => {
    try {
       const response = await fetch(
        `${global.apiUrl}about/help`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data.success == true) {
        this.setState({ help: data.detail })
      } else {
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }

  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                      
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><a href="index.html">Home</a><span> -</span></li>
                            <li>Help</li>
                        </ul>
                    </div>
                </div>  
            </div> 

           

            <div className="help-page-area bg-secondary section-space-bottom">
                <div className="container">
                    <h2 className="title-section">How can we help?</h2>
                    <div className="inner-page-details inner-page-padding"> 
                        <div className="panel-group help-page-wrapper" id="accordion">
                           
                            {
                            this.state.help.map((r,i)=>(
                                <div key={i} className="panel panel-default">
                                    <div className="panel-heading">
                                        <div className="panel-title">
                                            <a aria-expanded="false" className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href={`#${i}`}>
                                            #  {r.helpTanya}
                                            </a>
                                        </div>
                                    </div>
                                    <div aria-expanded="false" id={`${i}`} role="tabpanel" className="panel-collapse collapse">
                                        <div className="panel-body">
                                        {renderHTML(r.helpJawab)}
                                        </div>
                                    </div>
                                </div>
                                    ))
                            }

                            



                            {/* <div className="panel panel-default">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <a aria-expanded="false" className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                          #  Selling And Beign An Author
                                        </a>
                                    </div>
                                </div>
                                <div aria-expanded="false" id="collapseTwo" role="tabpanel" className="panel-collapse collapse">
                                    <div className="panel-body">
                                         <h3>How To Buy A Product?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                        <h3>How To Get Product Support?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="panel panel-default">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <a aria-expanded="false" className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                           #  Your Account
                                        </a>
                                    </div>
                                </div>
                                <div aria-expanded="false" id="collapseThree" role="tabpanel" className="panel-collapse collapse">
                                    <div className="panel-body">
                                         <h3>How To Buy A Product?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                        <h3>How To Get Product Support?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="panel panel-default">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <a aria-expanded="false" className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                                           #  Copyright And Trademark
                                        </a>
                                    </div>
                                </div>
                                <div aria-expanded="false" id="collapseFour" role="tabpanel" className="panel-collapse collapse">
                                    <div className="panel-body">
                                         <h3>How To Buy A Product?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                        <h3>How To Get Product Support?</h3>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centurbut also survived but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releas survived not  raseth leap into electronic typesetting, remaining essentially unchanged. </p>
                                    </div>
                                </div>
                            </div>                             */}
                        </div>
                    </div>  
                </div>  
            </div> 


      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  return { userName, isLogin }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(Help))
// export default withRouter(Detail);
// export default withRouter(Help)
