import React, { Component } from 'react'
import Img from 'react-image'
import CardLoadeRec from './CardLoaderRect'
import {NavLink} from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import ReactPlayer from "react-player";
class Card208Video extends Component {
  state = {
    isSwal : false
  }
  handleSendLike = async ()=>{
    // usid imid
    if(this.props.usid){
      try {
        const response = await fetch(`${global.apiUrl}video/sendlike`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            videoid: this.props.imid,
            userid: this.props.usid,
            })
        })
        const data = await response.json()
        if (data.success == true) {
          this.props.actionAfterLike()
        } else {
          
        }
      } catch (e) {
        console.log('error: ', e)
      }
    }else{
      this.setState({isSwal:true})
      // alert('anda harus login dahulu')
    }
  }


  render () {
    return (
        <div className="sidebar-single-item-grid">
        <div className="item-img" style={{
          //height:121, 
          overflow:'hidden', backgroundColor:'white'}}  >
        <ReactPlayer
        onClick={this.props.toDetail}
              width="100%"
              height="100%"
              url={this.props.imgurl}
              // controls
              playing={this.props.isPlay}
            />
             <div className="loveandlike">
                {/* <div className="profile-title">
                    <div className="img-wrapper">
                    <img src={this.props.userPict} alt="profile" className="img-responsive img-circle"/>
                    </div>
                    <span>{this.props.author}</span>
                </div> */}
                
                <div className="" >
                <i className="fa fa-download" aria-hidden="true" style={{color:'white'}}> 
                {" "}
                <span style={{color:'white'}} className="likeanddownload">{this.props.download}</span>{" "}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red',cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span style={{color:'white'}} className='likeanddownload'> {this.props.like}</span>
                </i>
              ) : (
                <i
                style={{cursor:'pointer',color:'white'}}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span style={{color:'white'}} className='likeanddownload'> {this.props.like}</span>
                </i>
              )}
                </div>
           
            </div>
        </div>
        <div className="item-content">
            <div className="item-info">
                <h3><NavLink className="frutigerFont" to={`${this.props.urldetail}`}>{this.props.title}</NavLink></h3>
                <span className="frutigerFont">{this.props.catName}</span>
                {/* <div className="price">$15</div> */}
            </div>
            {/* <div className="item-profile">
                <div className="profile-title">
                    <div className="img-wrapper"><img src={this.props.userPict} alt="profile" className="img-responsive img-circle"/></div>
                    <span>{this.props.author}</span>
                </div>
                <div className="profile-rating">
                <i className="fa fa-download" aria-hidden="true">
                {" "}
                <span className="likeanddownload">{this.props.download}</span>{" "}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red',cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              ) : (
                <i
                style={{cursor:'pointer'}}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              )}
                </div>
            </div> */}
        </div>
        <SweetAlert
                show={this.state.isSwal}
                title="Perhatian"
                confirmButtonText="Close"
                type="warning"
                text='Anda Harus Login Terlebih Dahulu'
                showCancelButton={false}
                onConfirm={()=>this.setState({isSwal:false})}/>
    </div>
    )
  }
}

export default Card208Video
