import React, { Component } from "react";
import Img from "react-image";
import { NavLink, withRouter } from "react-router-dom";
import CardLoadeRec from "./CardLoaderRect";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
import Card269Pop from "./Card269Pop";
import Card269VideoHomePop from "./Card269VideoHomePop";
import Dropdown from "react-dropdown-modal";
import ReactTags from 'react-tag-autocomplete'
import DatePicker from "react-datepicker";
class FilterPopUp extends Component {
  state = {
    isSwal: false,
    isRight: -340, //-590
    isPlaying: false,
    urlV: "",
    isShowPlayer: true,
    namaV: "",
    idnya: "",
    isVideo: null,
    cat: "",
    catId: "",
    id: "",
    like: "",
    download: "",
    youLike: "",
    searchTagTxt:'',
    tooltip: false,
    xpos: 0,
    ypos: 0,
    listTags: [],
    tags: [],
    suggestions:[],
    stringtags:'',
    startDate:'',
    endDate:'',
    tagFocus:false

  };

  

  shouldComponentUpdate(np, ns) {
    if (np.openProps !== this.props.openProps) {
      if (np.openProps == true) {
        this.onOpen();
        return true;
      } else {
        this.onClose();
        return true;
      }
    }
    return true;
  }

  scrollToTop = () => {
    this.intervalId = setInterval(this.handleInc, 30);
  };
  scrollToTop2 = () => {
    this.intervalId2 = setInterval(this.handleInc2, 10);
  };

  //========

  onOpen = () => {
    this.scrollToTopOpen();
  };
  scrollToTopOpen = () => {
    this.intervalIdOpen = setInterval(this.handleIncOpen, 10);
  };
  handleIncOpen = () => {
    this.setState({ isRight: this.state.isRight + 15 }, this.handleClearOpen);
  };
  handleClearOpen = () => {
    if (this.state.isRight > -16) {
      clearInterval(this.intervalIdOpen);
    }
  };

  //============

  handleInc = () => {
    this.setState({ isRight: this.state.isRight + 20 }, this.handleClear);
  };
  handleInc2 = () => {
    this.setState({ isRight: this.state.isRight - 20 }, this.handleClear2);
  };

  handleClear = () => {
    if (this.state.isRight > -237) {
      clearInterval(this.intervalId);
    }
  };
  handleClear2 = () => {
    if (this.state.isRight < -350) {
      clearInterval(this.intervalId2);
    }
  };

  componentDidMount() {
    // this.fetchNew();
    // this.scrollToTopOpen();
    this.fetchListTag()
  }

  // shouldComponentUpdate(np,ns){
  //   if(this.state.isPlaying !== ns.isPlaying){
  //     this.setState({isPlaying:false})
  //     return true
  //   }
  //   return true
  // }

  onClose = () => {
    this.scrollToTop2();
    //this.setState({isPlaying:false},this.scrollToTop2)
    setTimeout(() => {
      this.setState({ isShowPlayer: false });
    }, 1000);
  };

  fetchNew = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}video/new_video?uid=${this.props.useridprops}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          urlV: data.detail.cover,
          isPlaying: true,
          namaV: data.detail.name,
          idnya: data.detail.id,
          isVideo: data.detail.isVideo,
          cat: data.detail.cat,
          id: data.detail.id,
          catId: data.detail.catId,
          like: data.detail.like,
          download: data.detail.download,
          youLike: data.detail.youlike,
        });
        setTimeout(() => {
          this.setState({ isPlaying: false });
        }, 5000);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleAfterLike = async => {
    if (this.state.youLike) {
      this.setState({
        like: this.state.like - 1,
        youLike: !this.state.youLike,
      });
    } else {
      this.setState({
        like: this.state.like + 1,
        youLike: !this.state.youLike,
      });
    }
  };

  fetchListTag = async () => {
    try {
      const response = await fetch(`${global.apiUrl}image/alltagsfix`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listTags: data.data });
        // console.log(data.detail);
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  listItemTag = (tag) => {
    return (
      tag.map((r,i)=>(
        <li onClick={()=>{
          // console.log(r.tagName)
          // this.setState({tooltip:true})
        }} style={{cursor:'pointer'}}>{r.tagName}</li>
      ))
    )
  }

  handleCekBokFoto = ({target})=>{
    if (typeof this.props.cekBokFoto === 'function') {
      if (target.checked){
        this.props.cekBokFoto(true)
      } else {
        this.props.cekBokFoto(false)
      }
    }
  }
  handleCekBokVideo = ({target})=>{
    if (typeof this.props.cekBokVideo === 'function') {
      if (target.checked){
        this.props.cekBokVideo(true)
      } else {
        this.props.cekBokVideo(false)
      }
    }
}
handleCekBokDesign=({target})=>{
  if (typeof this.props.cekBokDesign === 'function') {
    if (target.checked){
      this.props.cekBokDesign(true)
    } else {
      this.props.cekBokDesign(false)
    }
  }
}

handleSearchInput=(text)=>{
  if (typeof this.props.inputSearch === 'function') {
    this.props.inputSearch(text)
  }
}
handleSearchTags=()=>{
  if (typeof this.props.tagsinput === 'function') {
    this.props.tagsinput(this.state.stringtags)
  }
}

handleDelete (i) {
  const tags = this.state.tags.slice(0)
  tags.splice(i, 1)
  let stringTags = tags.map(function(item) {
    return item['id'];
  }).join()
  this.setState({ tags:tags, stringtags:stringTags },this.handleSearchTags)
}

handleAddition (tag) {
  const tags = [].concat(this.state.tags, tag)
  let stringTags = tags.map(function(item) {
    return item['id'];
  }).join()
  this.setState({ tags:tags, stringtags:stringTags },this.handleSearchTags)
}

handleInputChange = (searchTagTxt) =>{
  this.setState({searchTagTxt},this.handleFetchTagSuggestion)
}

handleFetchTagSuggestion = async () => {
  try {
    const response = await fetch(`${global.apiUrl}image/alltagsuggest?s=${this.state.searchTagTxt}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.success == true) {
      this.setState({ suggestions: data.data });
    } else {
    }
  } catch (e) {
    console.log("error: ", e);
  }
}

handleChangeDateStart=(date)=>{
  if(date === null){
    if (typeof this.props.changeDateStart === 'function') {
      this.props.changeDateStart('')
    }
  }else{
    var year=date.getFullYear();
    var month=date.getMonth()+1 //getMonth is zero based;
    var day=date.getDate();
    var formatted=year+"-"+month+"-"+day;
    
    this.setState({
      startDate: date
    });
    if (typeof this.props.changeDateStart === 'function') {
      this.props.changeDateStart(formatted)
    }
  }
  
}
handleChangeDateEnd=(date)=>{
  if(date === null){
    if (typeof this.props.changeDateEnd === 'function') {
      this.props.changeDateEnd('')
    }
  }else{
    var year=date.getFullYear();
    var month=date.getMonth()+1 //getMonth is zero based;
    var day=date.getDate();
    var formatted=year+"-"+month+"-"+day;
    this.setState({
      endDate: date
    });
    if (typeof this.props.changeDateEnd === 'function') {
      this.props.changeDateEnd(formatted)
    }
  }
  
}

focousOut = (value)=>{
  if (value == '') {
      this.setState({ startDate: '' });
      // this.setState({ selectedValue: moment() });
     }
  }

  focousOutEndDate = (value)=>{
    if (value == '') {
        this.setState({ endDate: '' });
        // this.setState({ selectedValue: moment() });
       }
    }


  render() {
    return (
      <div className="filter-nopop">
        <div
          onClick={this.props.pressClose}
          style={{
            color: "white",
            position: "absolute",
            top: 12,
            right: 20,
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <i className="fa fa-close" /><span style={{ color: "black" }}>X</span>
        </div>

      

        <span style={{top: 0, left: 40 }} />
        <div style={{ minHeight: 200, backgroundColor: "white" }}>
          {/* <div onClick={() => this.setState({ openFilter: false })}>
              Close
            </div> */}
          <div className="container-filter">
            {/* <p className="frutigerFont">FILTER</p> */}

            <ul>
            <li className="frutigerFont"><span className="frutigerFont" style={{fontSize:14,fontWeight:'bold'}}>Category</span></li>
              {
                this.props.location.pathname == '/photo' ||  this.props.location.pathname == '/arsip' ? 
                <div style={{ marginLeft: 15 }}>
                <li className="frutigerFont">
                  <input type="checkbox" name="vehicle1" value="Bike" onClick={this.handleCekBokFoto}/>&nbsp;&nbsp;
                  <span className="frutigerFont" style={{fontSize:14}}>FOTO DOKUMENTASI</span>
                  
                </li>
                <li className="frutigerFont">
                  <input type="checkbox" name="vehicle1" value="Bike" onClick={this.handleCekBokVideo} />&nbsp;&nbsp;
                  <span className="frutigerFont" style={{fontSize:14}}>FOTO PRODUKSI</span>
                  
                </li>
              </div> : this.props.location.pathname == '/video' ?
              <div style={{ marginLeft: 15 }}>
              <li className="frutigerFont">
                <input type="checkbox" name="vehicle1" value="Bike" onClick={this.handleCekBokFoto}/>&nbsp;&nbsp;
                <span className="frutigerFont" style={{fontSize:14}}>VIDEO DOKUMENTASI</span>
                
              </li>
              <li className="frutigerFont">
                <input type="checkbox" name="vehicle1" value="Bike" onClick={this.handleCekBokVideo} />&nbsp;&nbsp;
                <span className="frutigerFont" style={{fontSize:14}}>VIDEO PRODUKSI</span>
                
              </li>
            </div> : 
            null
              }
             
              
            </ul>
            <hr/>
            <div className="row" style={{ marginTop: 5 }}>
              <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                <div className="form-group">
                <span className="frutigerFont"><span className="frutigerFont" style={{fontSize:14,fontWeight:'bold'}}>Filename</span></span>
                  <input
                    // value={this.props.fnomorhandphone}
                    style={{borderRadius:5}}
                    onChange={(e)=> this.handleSearchInput(e.target.value)}
                    type="text"
                    id="company-name"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: 5 }}>
              <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                <div className="form-group">
                <span className="frutigerFont" style={{fontSize:14,fontWeight:'bold'}}>Tags</span>

                  <ReactTags
                  handleFocus={()=>this.setState({tagFocus:true})}
                  handleBlur={()=>this.setState({tagFocus:false})}
                  handleInputChange={(txt)=>this.handleInputChange(txt)}
                  tags={this.state.tags}
                  suggestions={this.state.suggestions}
                  handleDelete={this.handleDelete.bind(this)}
                  handleAddition={this.handleAddition.bind(this)}
                  maxSuggestionsLength={4}
                  />
                  
                  {/* <textarea
                    rows="4"
                    value={this.props.fnomorhandphone}
                    onChange={e =>
                      this.props.registervariable({
                        prop: "fnomorhandphone",
                        value: e.target.value,
                      })
                    }
                    className="form-control"
                  /> */}
                  {/* <Dropdown
                    visible={this.state.tooltip}
                    onButtonClick={e => {
                      // console.log("Mouse", e.screenX, e.screenY); // You can use Mouse-positions in state
                      //this.setState({ tooltip: true });
                    }}
                    onClose={() => {
                      this.setState({ tooltip: false });
                    }}
                    position={{
                      right: "339px", // State Mouse-positions are valid
                      top: "70px", // State Mouse-positions are valid
                      width: "500px",
                    }}
                    modalContent={() => (
                      <div className="" style={{ padding: 20 }}>
                        {this.state.listTags.map((r, i) => (
                          <div style={{ float: "left", marginRight: 40 }}>
                            <span style={{ fontWeight: "bold" }}>
                              {r.tagGroup}
                            </span>
                            <ul style={{ marginLeft: 10 }}>
                              {this.listItemTag(r.list)}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    <button
                      onClick={() =>
                        this.setState({ tooltip: !this.state.tooltip })
                      }
                      className="that-s€xy-green-btn"
                      style={{
                        backgroundColor: "rgb(2,65,130)",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginTop: 5,
                        borderRadius: 4,
                      }}
                    >
                      All Tags
                    </button>
                  </Dropdown> */}
                </div>
              </div>
            </div>
<hr />
            <div className={this.state.tagFocus ? "containerDateModified" : ""} style={{  }}>
              <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
              <span className="frutigerFont" style={{ marginBottom: 8 ,fontSize:14,fontWeight:'bold'}}>Date Modified</span>
                <div className="form-group" style={{marginTop:20}}>
                  <div>
                    <div style={{ marginBottom: 6 }} className="col-md-3">
                    <span className="frutigerFont" style={{fontSize:14,fontWeight:'bold'}}>From</span>
                    </div>
                    <div style={{ marginBottom: 6 }} className="col-md-9">
                      {/* <input
                          value={this.props.fnomorhandphone}
                          onChange={e =>
                            this.props.registervariable({
                              prop: "fnomorhandphone",
                              value: e.target.value,
                            })
                          }
                         type="text"
                         id="company-name"
                          className="form-control"
                       />  */}
                     <div className="sales-statement-filter-item" style={{border:'1px solid gainsboro',padding:6,borderRadius:5}}>
                      <DatePicker
                          placeholderText="Pilih Tanggal Mulai"
                          dateFormat="yyyy-MM-dd"
                          selected={this.state.startDate}
                          onChange={this.handleChangeDateStart}
                          value={this.state.startDate}
                          // onChangeRaw={(event) =>
                          //   this.handleChangeRaw(event.target.value)}
                            onBlur={event => this.focousOut(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-3">
                    <span className="frutigerFont" style={{fontSize:14,fontWeight:'bold'}}>To</span>
                    </div>
                    <div className="col-md-9">
                    <div className="sales-statement-filter-item" style={{border:'1px solid gainsboro',padding:6,borderRadius:5}}>
                      <DatePicker
                      placeholderText="Pilih Tanggal Akhir"
                          dateFormat="yyyy-MM-dd"
                          selected={this.state.endDate}
                          onChange={this.handleChangeDateEnd}
                          value={this.state.endDate}
                          onBlur={event => this.focousOutEndDate(event.target.value)}
                       
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FilterPopUp);

