import React, { Component } from "react";
import Img from "react-image";
import { NavLink } from "react-router-dom";
import CardLoadeRec from "./CardLoaderRect";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
class Card370twoVideo extends Component {
  state = {
    isSwal: false,
  };
  handleSendLike = async () => {
    // usid imid
    if (this.props.usid) {
      try {
        const response = await fetch(`${global.apiUrl}video/sendlike`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoid: this.props.imid,
            userid: this.props.usid,
          }),
        });
        const data = await response.json();
        if (data.success == true) {
          this.props.actionAfterLike();
        } else {
        }
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      this.setState({ isSwal: true });
      // alert('anda harus login dahulu')
    }
  };

  onHover = (p) => {
    alert(p)
  }


  render() {
    return (
      <div className="mybootstrap" style={{
        // width:300
      }}>
        <div className="single-item-grid" style={{ backgroundColor: 'white' }}>
          <div
            // onClick={this.props.toDetail}
            className="item-img"
            style={{
              //height: 145,
              overflow: "hidden",
              // backgroundColor: "white",
            }}
            onMouseOver={this.props.onHover}
          >
            <NavLink
              to={`${this.props.urldetail}`}
              target="_blank"
            >
              <ReactPlayer
                width="100%"
                height="100%"
                // style={{width:'100%'}}
                url={this.props.imgurl}
                // controls
                playing={this.props.isPlay}
              // onClick={this.props.toDetail}
              />
            </NavLink>

            <div className="overlaydownloadandlike2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: '0px 10px 0px 10px ',

                }}
              >
                <div style={{ width: '70%' }}>
                  <div>
                    <span>
                      <NavLink
                        style={{ color: 'black', marginTop: -20, fontSize: 11 }}
                        className="frutigerFont"
                        to={`${this.props.urldetail}`}
                        target="_blank"
                      >
                        {this.props.title}
                      </NavLink>
                    </span>
                  </div>
                  <NavLink
                    to={{
                      pathname: "/photo",
                      state: { image_id: this.props.image_id },
                    }}
                  >
                    <span style={{ fontSize: 10 }} className="frutigerFont">
                      {this.props.catName}
                    </span>
                  </NavLink>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <i className="fa fa-download" aria-hidden="true" style={{ fontSize: 11, border: '1px solid grey', padding: '4px 10px 4px 10px', borderRadius: 4, marginRight: 3 }}>
                    {" "}
                    <span className="likeanddownload" style={{ color: "grey" }}>
                      {this.props.download}
                    </span>{" "}
                  </i>
                  {this.props.youLike ? (
                    <i
                      className="fa fa-heart"
                      style={{ fontSize: 11, color: "red", cursor: "pointer", border: '1px solid grey', padding: '4px 10px 4px 10px', borderRadius: 4 }}
                      aria-hidden="true"
                      onClick={this.handleSendLike}
                    >
                      <span
                        className="likeanddownload"
                        style={{ color: "grey", fontSize: 11 }}
                      >
                        {" "}
                        {this.props.like}
                      </span>
                    </i>
                  ) : (
                      <i
                        style={{ fontSize: 11, cursor: "pointer", border: '1px solid grey', padding: '4px 10px 4px 10px', borderRadius: 4 }}
                        className="fa fa-heart-o"
                        aria-hidden="true"
                        onClick={this.handleSendLike}
                      >
                        <span
                          className="likeanddownload"
                          style={{ color: "grey", fontSize: 11 }}
                        >
                          {" "}
                          {this.props.like}
                        </span>
                      </i>
                    )}
                </div>
              </div>
            </div>




            {/* <div className="overlaydownloadandlikevideo" style={{zIndex:99}}>
           <div className='' style={{position:'absolute',right:10,bottom:10}}>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload' style={{color:'white'}}>
                  {this.props.download}
                </span>{' '}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red', cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{ cursor:'pointer' }}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              )}
            </div>
            </div> */}
          </div>
          {/* <div 
          className="item-content"
          >
            <div className="item-info">
              <h3>
                <NavLink className="frutigerFont" to={`${this.props.urldetail}`}>
                  {this.props.title}
                </NavLink>
              </h3>
              <NavLink
                to={{
                  pathname: "/video",
                  state: { image_id: this.props.image_id },
                }}
              >
                <span className="frutigerFont">{this.props.catName}</span>
              </NavLink>
             
            </div>
            <div className="item-profile">
              <div className="profile-title">
                <div className="img-wrapper">
                  <img
                    src={this.props.userPict}
                    alt="profile"
                    className="img-responsive img-circle"
                  />
                </div>
                <span>{this.props.author}</span>
              </div>
              <div className="profile-rating">
                <ul>
                  <i className="fa fa-download" aria-hidden="true">
                    {" "}
                    <span className="likeanddownload">
                      {this.props.download}
                    </span>{" "}
                  </i>
                  &nbsp;
                  {this.props.youLike ? (
                    <i
                      className="fa fa-heart"
                      style={{ color: "red", cursor: "pointer" }}
                      aria-hidden="true"
                      onClick={this.handleSendLike}
                    >
                      <span className="likeanddownload">
                        {" "}
                        {this.props.like}
                      </span>
                    </i>
                  ) : (
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-heart-o"
                      aria-hidden="true"
                      onClick={this.handleSendLike}
                    >
                      <span className="likeanddownload">
                        {" "}
                        {this.props.like}
                      </span>
                    </i>
                  )}
                </ul>
              </div>
            </div>

            
          </div> */}
        </div>
        <SweetAlert
          show={this.state.isSwal}
          title="Perhatian"
          confirmButtonText="Close"
          type="warning"
          text="Anda Harus Login Terlebih Dahulu"
          showCancelButton={false}
          onConfirm={() => this.setState({ isSwal: false })}
        />
      </div>
    );
  }
}

export default Card370twoVideo;
