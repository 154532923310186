import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
const google = window.google;
class About extends Component {
  constructor () {
    super()
    this.state = {}
    this.map=""
  }
async componentDidMount(){
    document.title = "Avinet | Contac"

    // this.map = new google.maps.Map(this.refs.googleMap, {
    //    center: { lat: -6.5656, lng: 106.5656, },
    //    zoom: 9,
    //             scrollwheel: false,
    //             //center: new google.maps.LatLng(-37.81618, 144.95692),
    //             styles: [{
    //                 stylers: [{
    //                   saturation: -100
    //                 }]
    //             }]
    //   })

    //   new google.maps.Marker({
    //     position: this.map.getCenter(),
    //     animation: google.maps.Animation.BOUNCE,
    //     icon: '/img/map-marker.png',
    //     map: this.map
    // });

    const script2 = document.createElement("script");
    script2.setAttribute('id', 'geser');
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);
  
}

omponentWillUnmount(){
    var todelete2 = document.getElementById('geser');
    todelete2.remove();
}
  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
      
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                        
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><a href="index.html">Home</a><span> -</span></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            {/* <div className="google-map-area">                               
                <div id="googleMap" ref="googleMap" style={{width:"100%",height:430}}></div>                            
            </div> */}

            {/* <div id="map" ref="map" style={{ height: 500 }}></div> */}

            <div className="contact-us-info-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-us-left">
                                <h2>Contact Information</h2>      
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        <h3 className="title-bar-medium-left">Our Office Address</h3>
                                        <p>PO Box 16122 Collins Street West Victoria 8007 Australia</p> 
                                    </li>
                                    <li>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <h3 className="title-bar-medium-left">Phone</h3>
                                        <p>+61 3 8376 6284</p>   
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                        <h3 className="title-bar-medium-left">E-mail</h3>
                                        <p>info@foxtar.com</p>   
                                    </li>      
                                </ul>
                            </div>  
                        </div>  
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div className="contact-us-right"> 
                                <h2>Drop Us A Message </h2>    
                                <div className="contact-form"> 
                                    <form id="contact-form">
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Name*" className="form-control" name="name" id="form-name" data-error="Name field is required" required />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input type="email" placeholder="Email*" className="form-control" name="email" id="form-email" data-error="Email field is required" required />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                 <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Subject*" className="form-control" name="subject" id="form-subject" data-error="Subject field is required" required />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea placeholder="Message*" className="textarea form-control" name="message" id="form-message" rows="7" cols="20" data-error="Message field is required" required></textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-sm-12">
                                                    <div className="form-group margin-bottom-none">
                                                        <button type="submit" className="update-btn">Send Message</button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-6 col-sm-12">
                                                    <div className='form-response'></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  return { userName, isLogin }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(About))
// export default withRouter(Detail);
// export default withRouter(About)
