import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Img from "react-image";
import CardLoadeRec from "./CardLoaderRect";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
class Card239Video extends Component {
  state = {
    isSwal: false,
  };
  handleSendLike = async () => {
    // usid imid
    if (this.props.usid) {
      try {
        const response = await fetch(`${global.apiUrl}video/sendlike`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoid: this.props.imid,
            userid: this.props.usid,
          }),
        });
        const data = await response.json();
        if (data.success == true) {
          this.props.actionAfterLike();
        } else {
        }
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      this.setState({ isSwal: true });
      // alert('anda harus login dahulu')
    }
  };

  render() {
    return (
      <div className="single-item-list">
        <div
          className="item-img"
          style={{ 
            // height: 150, 
            overflow: "hidden", backgroundColor: "white" }}
          onMouseOver={this.props.onHover}
          onClick={this.props.toDetail}
        >
           <ReactPlayer
              width="100%"
              height="100%"
              url={this.props.imgurl}
              // controls
              playing={this.props.isPlay}
            />
          {/* <div className='trending-sign' data-tips='Trending'>
            <i className='fa fa-bolt' aria-hidden='true' />
          </div> */}
        </div>
        <div className="item-content" style={{padding: '0px 20px 10px 20px'}}>
          <div className="item-info">
            <div className="item-">
            
              <h3>
                <NavLink className="frutigerFont" to={`${this.props.urldetail}`}>
                  {this.props.title}
                </NavLink>
              </h3>
             
              {/* <span>{this.props.title}</span> */}
              <NavLink
                to={{
                  pathname: "/video",
                  state: { image_id: this.props.image_id },
                }}
              >
                <p style={{color:'rgb(2,65,130)'}} className="frutigerFont">{this.props.catName}</p>
              </NavLink>
            </div>
            <div className="item-sale-info">
              {/* <div className="price">$15</div> */}
              {/* <div className='sale-qty'>Sales ( 11 )</div> */}
            </div>
          </div>
          <div className="item-profile">
            <div className="profile-title">
              <div className="img-wrapper">
                {/* <img
                  src={this.props.userPict}
                  alt="profile"
                  className="img-responsive img-circle"
                /> */}
              </div>
              {/* <span> {this.props.author}</span> */}
            </div>
            <div className="profile-rating-info">
              <ul>
                <li>
                  <i className="fa fa-download" style={{color:'black'}} aria-hidden="true">
                    {" "}
                    <span className="likeanddownload">
                      {" "}
                      {this.props.download}
                    </span>{" "}
                  </i>
                  &nbsp;
                  {/* <i className="fa fa-heart-o" aria-hidden="true"> <span className="likeanddownload">12</span></i> */}
                </li>
                {/* <li>
                  <i className='fa fa-comment-o' aria-hidden='true' />( 10 )
                </li> */}
                {this.props.youLike ? (
                  <i
                    className="fa fa-heart"
                    style={{ color: "red", cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={this.handleSendLike}
                  >
                    <span className="likeanddownload"> {this.props.like}</span>
                  </i>
                ) : (
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-heart-o"
                    aria-hidden="true"
                    onClick={this.handleSendLike}
                  >
                    <span className="likeanddownload"> {this.props.like}</span>
                  </i>
                )}
              </ul>
            </div>
          </div>
        </div>
        <SweetAlert
          show={this.state.isSwal}
          title="Perhatian"
          confirmButtonText="Close"
          type="warning"
          text="Anda Harus Login Terlebih Dahulu"
          showCancelButton={false}
          onConfirm={() => this.setState({ isSwal: false })}
        />
      </div>
    );
  }
}

export default Card239Video;
