import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Card269 from "../components/Card269";
import Card181 from "../components/Card181";
import Card208 from "../components/Card208";
import Card239 from "../components/Card239";
import { Animated } from "react-animated-css";
import SearchInput from "../components/SearchInput";
import Loader from '../components/Loader';
class ArsipImage extends Component {
  constructor() {
    super();
    this.state = {
      listImage: [],
      keySearch: "",
      isAnimated: true,
      numRand: 1,
      totalPage: 0,
      activePage: 1,
      page: 1,
      listTerbaru:[],
      isSort:''
    };
  }

  handleRandom = () => {
    this.setState({ numRand: Math.ceil(Math.random() * 3) });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.handleRandom();
    //this.setState({keySearch : this.props.match.params.key})
    this.fetchListImage();
    this.fetchListTerbaru();
    document.title = "Avinet | Product";
    const script = document.createElement("script");
    script.setAttribute("id", "geser");
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({ keySearch: this.props.match.params.key });

    const script2 = document.createElement("script");
    script2.setAttribute("id", "naon");
    script2.src = "/js/main.js";
    script2.async = true;
    document.body.appendChild(script2);
  }

  componentWillUnmount() {
    var todelete = document.getElementById("geser");
    todelete.remove();

    var todelete2 = document.getElementById("naon");
    todelete2.remove();
  }

  fetchListImage = async () => {
    try {
      let url = `${global.apiUrl}history/listimageforproductpage?uid=${
        this.props.userid
      }&so=DESC`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listImage: data.detail,
          totalPage: data.pagetotal,
          loading: false,
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleSelectShort = async so => {
    this.setState({isSort:so})
      try {
        let url = `${global.apiUrl}history/listimageforproductpage?uid=${
          this.props.userid
        }&so=${so}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (data.success == true) {
          this.setState({
            listImage: data.detail,
            totalPage: data.pagetotal,
            loading: false,
          });
        } else {
        }
      } catch (e) {
        console.log("error: ", e);
      }
  };

  handleSelectPage = async page => {
    try {
      await this.setState({ page: page, activePage: page });
      const response = await fetch(
        `${global.apiUrl}history/listimageforproductpage?uid=${this.props.userid}&so=${this.state.isSort}&p=${this.state.page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listImage: data.detail });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleAfterLike = async iid => {
    const myArray = this.state.listImage;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listImage: myArray });
  };

  fetchListTerbaru = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}history/listimageforproductpage2?uid=${
          this.props.userid
        }&so=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listTerbaru: data.detail,
        });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleAfterLike3 = async iid => {
    const myArray = this.state.listTerbaru;
    let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
    myArray[objIndex].youLike = !myArray[objIndex].youLike;
    myArray[objIndex].likeCount = myArray[objIndex].youLike
      ? myArray[objIndex].likeCount + 1
      : myArray[objIndex].likeCount - 1;
    this.setState({ listTerbaru: myArray });
  };

  render() {
    let pagePrev = this.state.page - 1;
    let curPage = this.state.page;
    let nextPage = this.state.page + 1;
    return (
      <div>
      {
        //!this.props.isLogin && <div style={{ marginTop: 80 }} />
      }
     
            <SearchInput
              onChange={e => this.setState({ searchtext: e.target.value })}
              vall={this.state.searchtext}
            />
         

      <Animated
        animationIn=""
        animationOut="fadeOut"
        isVisible={this.state.loading}
      >
        <Loader />
      </Animated>

      <div className="pagination-area bg-secondary">
        <div className="container">
          <div className="pagination-wrapper">
            <ul>
              <li>
                <NavLink to="index.html">Home</NavLink>
                <span> -</span>&nbsp;
              </li>
              <li>Arsip</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="product-page-grid bg-secondary section-space-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 col-lg-push-3 col-md-push-4 col-sm-push-4">
              <div className="inner-page-main-body">
                <div className="page-controls">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8">
                    <div className="page-controls-sorting">
                      <div className="dropdown">
                        <button
                          className="btn sorting-btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                        >
                          {" "}
                          Sort by:
                          <i className="fa fa-sort" aria-hidden="true" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="#" onClick={()=>this.handleSelectShort('DESC')}>New</NavLink>
                          </li>
                          <li>
                            <NavLink to="#" onClick={()=>this.handleSelectShort('ASC')}>Older</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4">
                      <div className="layout-switcher">
                        <ul>
                          <li className="active">
                            <NavLink
                              to="#gried-view"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              <i className="fa fa-th-large" />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#list-view"
                              data-toggle="tab"
                              aria-expanded="true"
                            >
                              <i className="fa fa-list" />
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane fade in active clear products-container"
                    id="gried-view"
                  >
                    <div className="product-grid-view padding-narrow">
                      <div className="row">
                        {this.state.listImage.map((r, i) => {
                          return (
                            <Card269
                              key={i}
                              imgurl={r.cover}
                              title={r.arsiTitle}
                              catName={r.catName}
                              author={r.author}
                              download={r.download}
                              like={r.likeCount}
                              urldetail={`detail/${r.arsiId}`}
                              imgurl={r.cover}
                              toDetail={() =>
                                this.props.history.push("detail/" + r.arsiId)
                              }
                              userPict={r.authorPict}
                              imid={r.arsiId}
                              usid={this.props.userid}
                              actionAfterLike={() =>
                                this.handleAfterLike(r.arsiId)
                              }
                              image_id={r.arsiCat}
                              youLike={r.youLike}
                            />
                          );
                        })}
                        {/* <Card269 /> <Card269 /> <Card269 /> <Card269 /> */}
                        {/* <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                                  <div className="single-item-grid">
                                                      <div className="item-img">
                                                          <img src="/img/product/24.jpg" alt="product" className="img-responsive"/>
                                                      </div>
                                                      <div className="item-content">
                                                          <div className="item-info">
                                                              <h3><NavLink to="/detail">Team Component Pro</NavLink></h3>
                                                              <span>Joomla Component</span>
                                                              <div className="price">$15</div>
                                                          </div>
                                                          <div className="item-profile">
                                                              <div className="profile-title">
                                                                  <div className="img-wrapper"><img src="/img/profile/1.jpg" alt="profile" className="img-responsive img-circle"/></div>
                                                                  <span>PsdBosS</span>
                                                              </div>
                                                              <div className="profile-rating">
                                                                  <ul>
                                                                  <i className="fa fa-download" aria-hidden="true"> <span className="likeanddownload">200</span> </i>&nbsp;
                                                                   <i className="fa fa-heart-o" aria-hidden="true"> <span className="likeanddownload">12</span></i>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                            {/* <li>
                              <NavLink
                                to="#"
                                onClick={() =>
                                  this.handleSelectPage(1)
                                }
                              >
                                {`First`}
                              </NavLink>
                            </li> */}
                            {curPage - 1 >= 1 && (
                              <li className="active" style={{ color: "red" }}>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(pagePrev)
                                  }
                                >
                                  {pagePrev}
                                </NavLink>
                              </li>
                            )}

                            <li style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                className="active"
                                style={{
                                  color:
                                    curPage == this.state.activePage && "red",
                                }}
                                onClick={() => this.handleSelectPage(curPage)}
                              >
                                {curPage}
                              </NavLink>
                            </li>
                            {nextPage <= this.state.totalPage && (
                              <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(nextPage)
                                  }
                                >
                                  {nextPage}
                                </NavLink>
                              </li>
                            )}
                            {/* <li>
                              <NavLink
                                to="#"
                                onClick={() =>
                                  this.handleSelectPage(this.state.totalPage)
                                }
                              >
                                {`Last`}
                              </NavLink>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    className="tab-pane fade clear products-container"
                    id="list-view"
                  >
                    <div className="product-list-view">
                      {this.state.listImage.map((r, i) => {
                        return (
                          <Card239
                            key={i}
                            imgurl={r.cover}
                            title={r.arsiTitle}
                            catName={r.catName}
                            author={r.author}
                            download={r.download}
                            like={r.likeCount}
                            imgurl={r.cover}
                            userPict={r.authorPict}
                            urldetail={`detail/${r.arsiId}`}
                            toDetail={() =>
                              this.props.history.push("detail/" + r.arsiId)
                            }
                            imid={r.arsiId}
                            usid={this.props.userid}
                            actionAfterLike={() =>
                              this.handleAfterLike2(r.arsiId)
                            }
                            image_id={r.arsiCat}
                            youLike={r.youLike}
                          />
                        );
                      })}

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                            {/* <li>
                              <NavLink
                                to="#"
                                onClick={() =>
                                  this.handleSelectPage(1)
                                }
                              >
                                {`First`}
                              </NavLink>
                            </li> */}
                            {curPage - 1 >= 1 && (
                              <li className="active" style={{ color: "red" }}>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(pagePrev)
                                  }
                                >
                                  {pagePrev}
                                </NavLink>
                              </li>
                            )}

                            <li style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                className="active"
                                style={{
                                  color:
                                    curPage == this.state.activePage && "red",
                                }}
                                onClick={() => this.handleSelectPage(curPage)}
                              >
                                {curPage}
                              </NavLink>
                            </li>
                            {nextPage <= this.state.totalPage && (
                              <li>
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    this.handleSelectPage(nextPage)
                                  }
                                >
                                  {nextPage}
                                </NavLink>
                              </li>
                            )}
                            {/* <li>
                              <NavLink
                                to="#"
                                onClick={() =>
                                  this.handleSelectPage(this.state.totalPage)
                                }
                              >
                                {`Last`}
                              </NavLink>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 col-lg-pull-9 col-md-pull-8 col-sm-pull-8">
              <div className="fox-sidebar">
               
                {/* <div className="sidebar-item">
                                  <div className="sidebar-item-inner">
                                      <h3 className="sidebar-item-title">Price Range</h3>
                                      <div id="price-range-wrapper" className="price-range-wrapper">
                                          <div id="price-range-filter"></div>
                                          <div className="price-range-select">
                                              <div className="price-range" id="price-range-min"></div>
                                              <div className="price-range" id="price-range-max"></div>
                                          </div>
                                          <button className="sidebar-full-width-btn disabled" type="submit" value="Login"><i className="fa fa-search" aria-hidden="true"></i>Search</button>
                                      </div>
                                  </div>
                              </div> */}
                <div className="sidebar-item">
                  <div className="sidebar-item-inner">
                    <h3 className="sidebar-item-title">Recent Image</h3>
                    {this.state.listTerbaru.map((r, i) => {
                        return (
                          <Card208
                            key={i}
                            imgurl={r.cover}
                            title={r.arsiTitle}
                            catName={r.catName}
                            author={r.author}
                            download={r.download}
                            like={r.likeCount}
                            urldetail={`detail/${r.arsiId}`}
                            imgurl={r.cover}
                            toDetail={() =>
                              this.props.history.push("detail/" + r.arsiId)
                            }
                            imid={r.arsiId}
                            usid={this.props.userid}
                            actionAfterLike={() =>
                              this.handleAfterLike3(r.arsiId)
                            }
                            youLike={r.youLike}
                            image_id={r.arsiCat}
                            userPict={r.authorPict}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  return { userName, isLogin, userid };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(ArsipImage));
//export default withRouter(Detail);
// export default withRouter(ProductList)
