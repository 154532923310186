import React, { Component } from 'react'
import MyRouter from './router/MyRouter'
import Header from './components/Header'
import Footer from './components/Footer'
import {  withRouter } from 'react-router-dom'
class App extends Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount(){
    this.visitorforserver()
  }
  visitorforserver = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}main/visitor`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
    } catch (e) {
      console.log("error: ", e);
    }
  };

  render () {
    return (
      <div>
        <Header acr={this.props.location.pathname} />
        <MyRouter />
        <Footer />
      </div>
    )
  }
}
export default withRouter(App);
/*

om nanti api di copas ke sini ya

domain : http://api.avinet.online/
folder : /opt/html/avinet-api-online
=====================================
om nanti portaldi copas ke sini ya

domain : http://avinet.online/
folder : /opt/html/avinet-online
 
 */