import React, { Component } from "react";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { inputcart } from "../redux/actions";
import SearchInput from "../components/SearchInput";
import SweetAlert from "sweetalert2-react";
import CardImageMore from "../components/CardImageMore";
class Detail extends Component {
  constructor() {
    super();
    this.state = {
      detail: {},
      images: [],
      relate: [],
      intervalId: 0,
      arsipIdActive: 0,
      imageForCart: {},
      nowDate: "",
      tags: [],
    };
  }

  scrollStep = () => {
    if (window.pageYOffset < 500) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(500, window.pageYOffset - 40);
  };

  scrollToTop = () => {
    let intervalId = setInterval(this.scrollStep.bind(this), 60);
    this.setState({ intervalId: intervalId });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchDetail();
    this.fetchDetailImage();
    document.title = "Avinet | Detail";
    // const script = document.createElement('script')
    // script.setAttribute('id', 'forselect2')
    // script.src = '/js/mainselect2.js'
    // script.async = true
    // document.body.appendChild(script)

    const script2 = document.createElement("script");
    script2.setAttribute("id", "geser");
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.setAttribute("id", "naon");
    script3.src = "/js/main.js";
    script3.async = true;
    document.body.appendChild(script3);

    this.setState({ arsipIdActive: this.props.match.params.id });
    let no = new Date();
    let codeD =
      "AVN" +
      no.getFullYear() +
      no.getMonth() +
      no.getDay() +
      "-" +
      no.getHours() +
      no.getMinutes() +
      no.getSeconds();
    this.setState({
      nowDate: codeD,
      arsipIdActive: this.props.match.params.id,
    });
    // console.log(this.props.match.params.id)

    // document.onkeydown = this.onKeyDown

  }

  //   shouldComponentUpdate(np,ns){
  //       if(this.state.detail !== ns.detail){
  //           return true
  //       }
  //       return true
  //   }

  // componentDidUpdate(pp,ps){
  //   if(this.state.images !== ps.images){
  //     console.log('terjalankan')
  //     this.fetchRelate()
  //   }
  // }

  componentWillUnmount() {
    // var todelete = document.getElementById('forselect2')
    // todelete.remove()

    var todelete2 = document.getElementById("geser");
    todelete2.remove();

    var todelete3 = document.getElementById("naon");
    todelete3.remove();
  }

  fetchDetail = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}image/detail?id=${this.props.match.params.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState(
          {
            detail: data.detail,
            tags: data.detail.tagstwo,
          },
          this.fetchRelate
        );
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchDetailImage = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}image/imagebyid?id=${this.props.match.params.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ images: data.detail }, this.fetchRelate);
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchRelate = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}image/imagebelonguser?uid=${
        this.state.detail.arsiUserIdSaved
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ relate: data.detail });
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handlePush = async id => {
    this.scrollToTop();
    // window.scrollTo(500, 500)
    try {
      this.setState({ arsipIdActive: id });
      const response = await fetch(`${global.apiUrl}image/detail?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState(
          {
            detail: data.detail,
            tags: data.detail.tagstwo,
          },
          this.fetchRelate
        );
        this.fetchDetailImage2(id);
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchDetailImage2 = async id => {
    try {
      const response = await fetch(`${global.apiUrl}image/imagebyid?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ images: data.detail }, this.fetchRelate);
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleDownload = async () => {
    window.location.href = `${global.apiUrl}image/imagefordownload?id=${
      this.state.arsipIdActive
      }&uid=${this.props.userid}&code=${this.state.nowDate}&arsipsId=${
      this.state.arsipIdActive
      }`;
  };

  addToCart = e => {
    if (this.props.isLogin) {
      e.preventDefault();
      var product = this.state.imageForCart;
      product["imageid"] = this.state.detail.arsiId;
      product["isVideo"] = false;
      product["imagename"] = this.state.detail.arsiTitle;
      product["imageurl"] = this.state.detail.cover;

      if (this.props.cart !== "" || this.props.cart !== []) {
        var items = this.props.cart;
        var valueArr = items.map(item => item.imageid);
        var issame = valueArr.find(item => item == this.state.detail.arsiId);
        if (issame > 0) {
          //this.setState({ isExistCart: true });
          items.push(product);
          this.props.inputcart(JSON.stringify(items));
          this.setState({ successSaveCart: true });
        } else {
          items.push(product);
          this.props.inputcart(JSON.stringify(items));
          this.setState({ successSaveCart: true });
        }
      } else {
        this.props.inputcart(JSON.stringify([product]));
        //alert('image berhasil di masukan ke keranjang')
        this.setState({ successSaveCart: true });
      }
    } else {
      this.setState({ notLogin: true });
    }
  };

  handleShare = () => {
    var newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=http://avinet.hahabid.com/detail/${
      this.state.arsipIdActive
      }`,
      "title",
      "scrollbars=yes, width=700, height=500, top=100, left=300"
    );
    if (window.focus) {
      newWindow.focus();
    }
  };
  handleShareTwitter = () => {
    var newWindow = window.open(
      `https://twitter.com/home?status=http://avinet.hahabid.com/detail/${
      this.state.arsipIdActive
      }`,
      "title",
      "scrollbars=yes, width=700, height=500, top=100, left=300"
    );
    if (window.focus) {
      newWindow.focus();
    }
  };
  handleShareLinkedIn = () => {
    var newWindow = window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=http://avinet.hahabid.com/detail/${
      this.state.arsipIdActive
      }`,
      "title",
      "scrollbars=yes, width=700, height=500, top=100, left=300"
    );
    if (window.focus) {
      newWindow.focus();
    }
  };
  handleSharePinterest = () => {
    var newWindow = window.open(
      `http://pinterest.com/pin/create/button/?url=http://avinet.hahabid.com/detail/${
      this.state.arsipIdActive
      }`,
      "title",
      "scrollbars=yes, width=700, height=500, top=100, left=300"
    );
    if (window.focus) {
      newWindow.focus();
    }
  };

  handleDownloadOne = async (arimid) => {
    window.location.href = `${global.apiUrl}image/download_one?id=${arimid}&uid=${this.props.userid}`;
  };

  // onKeyDown = (e) => {
  //   e = e || window.event;
  //   if (e.keyCode == '38') {
  //     // alert('atas!')
  //   }
  //   else if (e.keyCode == '40') {
  //     // alert('bawah!')
  //   }
  //   else if (e.keyCode == '37') {
  //     // alert('Kiri!')
  //   }
  //   else if (e.keyCode == '39') {
  //     // alert('Kanan')
  //   }
  // }

  render() {
    return (
      <div>
        {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }


        {/* <SearchInput
                onChange={e => this.setState({ searchtext: e.target.value })}
                vall={this.state.searchtext}
              /> */}


        <div className="pagination-area bg-secondary">
          <div className="container">
            <div className="pagination-wrapper">
              <ul>
                <li>
                  <NavLink className="frutigerFont" to="#">Home</NavLink>
                  <span> -</span>&nbsp;
                </li>
                <li>
                  <NavLink className="frutigerFont" to="#">Detail</NavLink>
                  <span> -</span>&nbsp;
                </li>
                <li className="frutigerFont">{this.state.detail.arsiTitle}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="product-details-page bg-secondary">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                <div className="inner-page-main-body">
                  <div className="single-banner">
                    {
                      this.state.images.length > 0 &&

                      <Carousel
                        // onClickItem={(e)=>alert(e)}
                        dynamicHeight
                        useKeyboardArrows
                        // autoPlay={true}
                        // interval={5000}
                        // infiniteLoop
                        stopOnHover={false}
                        showIndicators={
                          this.state.images.length < 2 ? false : true
                        }
                        showThumbs={this.state.images.length < 2 ? false : true}
                        showStatus={false}
                      >
                        {this.state.images.map((r, i) => {
                          return (
                            <div

                              key={i}>
                              <div
                                onClick={() => this.handleDownloadOne(r.arimId)}
                                style={{
                                  top: 20,
                                  border: '1px solid white',
                                  // padding:10,
                                  height: 40,
                                  width: 40,
                                  borderRadius: 30,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  // borderWidth:1,
                                  // background:'red',
                                  position: 'absolute',
                                  right: 60
                                }}><i style={{ color: 'white', fontSize: 16 }} className="fa fa-download" aria-hidden="true" /></div>
                              <img src={r.img6} alt="badges" />
                            </div>
                          );
                        })}
                      </Carousel>
                    }
                    {/* <div>onClick={()=>alert(r.arimId)}
                                            <img src="/img/product/details.jpg" alt="badges"/>
                                             <p className="legend">Photo 1</p>
                                        </div>
                                        <div>
                                            <img src="/img/product/details.jpg" alt="badges" />
                                            <p className="legend">Photo 2</p>
                                        </div>
                                        <div>
                                            <img src="/img/product/details.jpg" alt="badges" />
                                            <p className="legend">Photo 3</p>
                                        </div> */}

                    {/* <img src="/img/product/details.jpg" alt="product" className="img-responsive" /> */}
                  </div>
                  {/* <h2 className="title-inner-default">
                    {this.state.detail.arsiTitle}
                  </h2>
                  <p className="para-inner-default">
                    {this.state.detail.arsiDesc}
                  </p> */}



                  {/* <div className="product-tag-line">
                    <ul className="product-tag-item">
                      {this.state.tags.map((r, i) => (
                        <li key={i}>
                          <NavLink to={{
                              pathname: '/photo',
                              state: { 
                                image_tag: r.id,
                                image_tag_name: r.name
                              }
                            }}>
                          {r.name}
                          </NavLink>
                        </li>
                      ))} */}
                  {/* <li><NavLink to="#">Live Preview</NavLink></li>
                                            <li><NavLink to="#">Screenshots</NavLink></li>
                                            <li><NavLink to="#">Documentation</NavLink></li> */}
                  {/* </ul>
                    <ul className="social-default">
                      <li>
                        <NavLink to="#" onClick={this.handleShare}>
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#" onClick={this.handleShareTwitter}>
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#" onClick={this.handleShareLinkedIn}>
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#" onClick={this.handleSharePinterest}>
                          <i className="fa fa-pinterest" aria-hidden="true" />
                        </NavLink>
                      </li>
                    </ul>
                  </div> */}




                  <div className="product-details-tab-area">
                    <div className="row">
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                                                <ul className="product-details-title">
                                                    <li className="active"><Link to="#description" data-toggle="tab" aria-expanded="false">Item Features</Link></li>
                                                    <li><Link to="#review" data-toggle="tab" aria-expanded="false">Comments</Link></li>
                                                    <li><Link to="#add-tags" data-toggle="tab" aria-expanded="false">Reviews</Link></li>
                                                    <li><Link to="#add-tags" data-toggle="tab" aria-expanded="false">Support</Link></li>
                                                </ul>
                                            </div> */}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade active in" id="description">
                                                        <ul className="product-details-content">
                                                            <li>Printing and typesetting industry</li>
                                                            <li>Printing and typesetting industry</li>
                                                            <li>Bhen an unknown printe</li>
                                                            <li>Bhen an unknown printe</li>
                                                            <li>Handard dummy text</li>
                                                            <li>Handard dummy text</li>
                                                            <li>Desktop publishing software</li>
                                                            <li>Bhen an unknown printe</li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-pane fade" id="review">
                                                        <p>Porem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.</p>
                                                    </div>
                                                    <div className="tab-pane fade" id="add-tags">
                                                        <p>Porem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <h3 className="title-inner-section frutigerFont">MORE IMAGES</h3>

                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <div className="fox-sidebar">
                  <div className="sidebar-item">
                    <div className="sidebar-item-inner">
                      <h3 className="sidebar-item-title">
                        {this.state.detail.arsiTitle}
                      </h3>
                      {/* <ul className='sidebar-product-price'>
                        <li />
                        <li> */}
                      {/* <form id="personal-info-form">
                                                        <div className="custom-select">
                                                            <select id="categories" className='select2'>
                                                                <option value="0">Regular</option>
                                                                <option value="1">Extended</option>
                                                            </select>
                                                        </div>
                                                    </form> */}
                      {/* </li>
                      </ul> */}
                      <ul className="sidebar-product-btn">
                        <li>
                          {" "}
                          <NavLink
                            to="#"
                            className="add-to-cart-btn frutigerFont"
                            id="cart-button"
                            onClick={this.addToCart}
                          >
                            {/* <i
                              className="fa fa-shopping-cart"
                              aria-hidden="true"
                            /> */}
                            <img src="/img/down-icon.png" className="ikondonlot" style={{ height: 20, marginRight: 10 }} />
                            {" "}
                            Add To Cart
                          </NavLink>
                        </li>
                        {/* <li><Link to="#" className="add-to-favourites-btn" id="favourites-button"><i className="fa fa-heart-o" aria-hidden="true"></i> Add To Favourites</Link></li> */}
                        <li>
                          <NavLink
                            to="#"
                            onClick={this.handleDownload}
                            className="buy-now-btn frutigerFont"
                            id="buy-button"
                          >
                            Download Now
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="sidebar-item">
                                        <div className="sidebar-item-inner">
                                            <ul className="sidebar-sale-info">
                                                <li><i className="fa fa-shopping-cart" aria-hidden="true"></i></li>
                                                <li>05</li>
                                                <li>Sales</li>
                                            </ul>
                                        </div>
                                    </div> */}
                  <div className="sidebar-item">
                    <div className="sidebar-item-inner">
                      <h3 className="sidebar-item-title frutigerFont">Image Information</h3>
                      <ul className="sidebar-product-info">
                        <li>
                          <p className="release-date-text frutigerFont">Release Date : </p>
                          <span className="frutigerFont" style={{ fontSize: 13 }}>{this.state.detail.arsiSaved}</span>
                        </li>
                        {/* <li>Last Update:<span> 20 April, 2016</span></li> */}
                        <li className="frutigerFont">
                          Download:<span>&nbsp;&nbsp;{this.state.detail.download}</span>
                        </li>
                        <li className="frutigerFont">
                          Description:
                        </li>
                        <li className="frutigerFont">
                          <p className="frutigerFont" style={{ fontSize: 13 }}>{this.state.detail.arsiDesc}</p>
                        </li>
                        <li className="frutigerFont">
                          Tags:&nbsp;
                          </li>
                        <li>{
                          this.state.tags.map((r, i) => (
                            <NavLink
                              className="frutigerFont"
                              key={i} style={{ color: 'grey', fontSize: 13 }} to={{

                                pathname: '/photo',
                                state: {
                                  image_tag: r.id,
                                  image_tag_name: r.name
                                }
                              }}>
                              {r.name}{this.state.tags.length - 1 === i ? null : ', '}
                            </NavLink>
                          ))
                        }
                        </li>
                        {/* <li>Version:<span> 1.1</span></li>
                                                <li>Compatibility:<span> Wordpress 4+</span></li>
                                                <li>Compatible Browsers:<span> IE9, IE10, IE11, Firefox, Safari, Opera, Chrome</span></li> */}
                      </ul>
                    </div>
                  </div>
                  {/* <div className='sidebar-item'>
                    <div className='sidebar-item-inner'>
                      <h3 className='sidebar-item-title'>Image Author</h3>
                      <div className='sidebar-author-info'>
                        <img
                          src={this.state.detail.authorPict}
                          style={{ height: 40 }}
                          alt='product'
                          className='img-responsive'
                        />
                        <div className='sidebar-author-content'>
                          <h3>{this.state.detail.author}</h3> */}
                  {/* <Link to="#" className="view-profile">View Profile</Link> */}
                  {/* </div>
                      </div> */}
                  {/* <ul className="sidebar-badges-item">
                                                <li><img src="/img/profile/badges1.png" alt="badges" className="img-responsive" /></li>
                                                <li><img src="/img/profile/badges2.png" alt="badges" className="img-responsive" /></li>
                                                <li><img src="/img/profile/badges3.png" alt="badges" className="img-responsive" /></li>
                                                <li><img src="/img/profile/badges4.png" alt="badges" className="img-responsive" /></li>
                                                <li><img src="/img/profile/badges5.png" alt="badges" className="img-responsive" /></li>
                                            </ul> */}
                  {/* </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row">
              {this.state.relate.map((r, i) => (
                <CardImageMore
                  key={i}
                  title={r.arsiTitle}
                  catName={r.catName}
                  //author={r.author}
                  download={r.download}
                  like={r.likeCount}
                  urldetail={`detail/${r.arsiId}`}
                  toDetail={() =>
                    this.props.history.push("detail/" + r.arsiId)
                  }
                  userPict={r.authorPict}
                  imgurl={r.cover}
                  imid={r.arsiId}
                  usid={this.props.userid}
                  actionAfterLike={() => this.handleAfterLike2(r.arsiId)}
                  image_id={r.arsiCat}
                  youLike={r.youLike}
                />
              ))}
            </div>

          </div>
        </div>


        <SweetAlert
          show={this.state.isExistCart}
          title="Gagal"
          confirmButtonText="Close"
          type="warning"
          text="Image Sudah Ada Di Keranjang Anda !"
          showCancelButton={false}
          onConfirm={() => this.setState({ isExistCart: false })}
        />
        <SweetAlert
          show={this.state.notLogin}
          title="Gagal"
          confirmButtonText="Close"
          type="warning"
          text="Anda Harus Login !"
          showCancelButton={false}
          onConfirm={() => this.setState({ notLogin: false })}
        />
        <SweetAlert
          show={this.state.successSaveCart}
          title="Berhasil"
          confirmButtonText="Close"
          type="success"
          text="Memasukan Image Ke Keranjang"
          showCancelButton={false}
          onConfirm={() => this.setState({ successSaveCart: false })}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  const { cart } = state.cart;
  return { userName, isLogin, cart, userid };
};

export default connect(
  mapStateToProps,
  { inputcart }
)(withRouter(Detail));
// export default withRouter(Detail);
//http://avinet-api.hahabid.com/image/imagefordownload?id=1
/*

<div className="row more-product-item-wrapper">
 <div
                        key={i}
                        className="col-lg-4 col-md-6 col-sm-6 col-xs-6"
                      >
                        <div className="more-product-item">
                          <div
                            className="more-product-item-img"
                            style={{
                              height: 90,
                              overflow: "hidden",
                              backgroundColor: "white",
                            }}
                          >
                            <img
                              src={r.cover}
                              alt="product"
                              style={{
                                height: "100%",
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              className="img-responsive"
                            />
                          </div>
                          <div className="more-product-item-details">
                            <h4>
                              <NavLink
                                to={`${r.arsiId}`}
                                onClick={() => this.handlePush(r.arsiId)}
                              >
                                {r.arsiTitle}
                              </NavLink>
                            </h4>
                            <NavLink
                              to={{
                                pathname: "/photo",
                                state: { image_id: r.arsiCat },
                              }}
                            >
                              <div className="p-title">{r.catName}</div>
                            </NavLink>

                          </div>
                        </div>
                      </div></div>


*/