import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Img from 'react-image'
import CardLoadeRec from './CardLoaderRect'
import ReactPlayer from "react-player";
class Card181Video extends Component {
  render () {
    return (
      <div className='single-item-list'>
        <div className='item-img' style={{
          //height:147,
           overflow:'hidden', backgroundColor:'white'}}>
        <ReactPlayer
              width="100%"
              height="100%"
              url={this.props.imgurl}
              // controls
              playing={this.props.isPlay}
            />
             <div className='overlaydownloadandlikevideo' style={{marginBottom:10}}>
            
            <div className='' style={{width:100, position:'absolute',right:-20,bottom:10}}>
              <ul>
                <li style={{float:'left'}}>
                  <ul className='profile-rating'>
                    <i className='fa fa-download' aria-hidden='true'>
                      {' '}
                      <span className='likeanddownload' style={{color:'white'}}>{this.props.download}</span>{' '}
                    </i>
                    &nbsp;
                    
                  </ul>
                </li>
               
                <li>
                  <i className='fa fa-heart-o' aria-hidden='true' /> {this.props.like}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='item-content'>
          <div className='item-info'>
            <div className='item-title'>
              <h3>
              <NavLink className="frutigerFont" to={`${this.props.urldetail}`}>
                {this.props.title}
              </NavLink>
              </h3>
              <span className="frutigerFont">{this.props.catName}</span>
            </div>
            {/* <div className='item-description'>
              <p>{this.props.arsiDesc}</p>
            </div> */}
            {/* <div className='item-sale-info'>
             
            </div> */}
          </div>
          {/* <div className='item-profile-list'>
            <div className='profile-title'>
              <div className='img-wrapper'>
                <img
                 src={this.props.userPict}
                  alt='profile'
                  className='img-responsive img-circle'
                />
              </div>
              <span>{this.props.author}</span>
            </div>
            <div className='profile-rating-info'>
              <ul>
                <li>
                  <ul className='profile-rating'>
                    <i className='fa fa-download' aria-hidden='true'>
                      {' '}
                      <span className='likeanddownload'>{this.props.download}</span>{' '}
                    </i>
                    &nbsp;
                    
                  </ul>
                </li>
               
                <li>
                  <i className='fa fa-heart-o' aria-hidden='true' />( {this.props.like} )
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

export default Card181Video
