import React, { Component } from 'react'
import Img from 'react-image'
import { NavLink } from 'react-router-dom'
import CardLoadeRec from './CardLoaderRect'
import SweetAlert from 'sweetalert2-react';
class Card370 extends Component {
  static defaultProps = {
    title: 'title'
  }
  state = {
    isSwal : false
  }
  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.title !== nextProps.title) {
      console.log('update harusnya')
      return true
    }

    return true
  }

  handleSendLike = async () => {
    // usid imid
    if (this.props.usid) {
      try {
        const response = await fetch(`${global.apiUrl}image/sendlike`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            arsipImageId: this.props.imid,
            arsipLikeUserId: this.props.usid
          })
        })
        const data = await response.json()
        if (data.success == true) {
          this.props.actionAfterLike()
        } else {
        }
      } catch (e) {
        console.log('error: ', e)
      }
    } else {
      this.setState({isSwal:true})
      // alert('anda harus login dahulu')
    }
  }

  render () {
    return (
      <div className='single-item-grid' style={{ margin: 10 }}>
        <div className='item-img' style={{height:220, overflow:'hidden', backgroundColor:'white'}}>
          {' '}
          <Img
            style={{ 
              objectFit: 'cover',
              height:'100%', 
              cursor: 'pointer'
             }}
            src={this.props.imgurl}
            alt='product'
            className='img-responsive'
            loader={<CardLoadeRec />}
            unloader={<CardLoadeRec />}
            onClick={this.props.toDetail}
          />
          {/* <div className='trending-sign' data-tips='Trending'>
            <i className='fa fa-bolt' aria-hidden='true' />
          </div> */}
          <div className="overlaydownloadandlike">
           <div className='' style={{position:'absolute',right:10,bottom:10}}>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload' style={{color:'white'}}>
                  {this.props.download}
                </span>{' '}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red', cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{ cursor:'pointer' }}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload' style={{color:'white'}}> {this.props.like}</span>
                </i>
              )}
            </div>
            </div>
        </div>
        <div className='item-content'>
          <div className='item-info'>
            <h3>
              <NavLink className="frutigerFont" to={`${this.props.urldetail}`}>
                {this.props.title}
              </NavLink>
            </h3>
           
            <NavLink 
            to={{
              pathname: '/photo',
              state: { image_id: this.props.image_id }
            }}
            >
            <span className="frutigerFont">{this.props.catName}</span>
            </NavLink>
           
           
            {/* <div className="price">$15</div> */}
          </div>
          {/* <div className='item-profile'>
            <div className='profile-title'>
              <div className='img-wrapper'>
                <img
                  src={this.props.userPict}
                  alt='profile'
                  className='img-responsive img-circle'
                />
              </div>{' '}
              <span>{this.props.author}</span>{' '}
            </div>
            <div className='profile-rating'>
              <i className='fa fa-download' aria-hidden='true'>
                {' '}
                <span className='likeanddownload'>
                  {this.props.download}
                </span>{' '}
              </i>
              &nbsp;
              {this.props.youLike ? (
                <i
                  className='fa fa-heart'
                  style={{ color: 'red', cursor:'pointer' }}
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              ) : (
                <i
                  style={{ cursor:'pointer' }}
                  className='fa fa-heart-o'
                  aria-hidden='true'
                  onClick={this.handleSendLike}
                >
                  <span className='likeanddownload'> {this.props.like}</span>
                </i>
              )}
            </div>
          </div> */}
        </div>
        <SweetAlert
                show={this.state.isSwal}
                title="Perhatian"
                confirmButtonText="Close"
                type="warning"
                text='Anda Harus Login Terlebih Dahulu'
                showCancelButton={false}
                onConfirm={()=>this.setState({isSwal:false})}/>
      </div>
    )
  }
}

export default Card370
// {
// <i className="fa fa-heart" style={{color:'red'}} aria-hidden="true" onClick={this.handleSendLike}>
