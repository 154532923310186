import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
class Checkout extends Component {
  constructor () {
    super()
    this.state = {}
  }
  componentDidMount() {
    document.title = "Avinet | Checkout"
    const script = document.createElement("script");
    script.setAttribute('id', 'forselect2');
    script.src = "/js/mainselect2.js";
    script.async = true;
    document.body.appendChild(script);


    const script2 = document.createElement("script");
    script2.setAttribute('id', 'geser');
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);
}

componentWillUnmount(){
    var todelete = document.getElementById('forselect2');
    todelete.remove();

    var todelete2 = document.getElementById('geser');
    todelete2.remove();
}
  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        <div className="inner-banner-area" style={{ backgroundImage: `url(/img/avinet/bg-asset-bl.png)` , paddingTop:80}}>
                    <div className="container">
                        <div className="inner-banner-wrapper">
                        <p>Download what you want, cancel when you want</p>
                           
                        </div>
                    </div>
                </div>
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><NavLink to="index.html">Home</NavLink><span> -</span></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>  
            </div> 


            <div className="checkout-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="checkout-page-top">
                                <p><i className="fa fa-check" aria-hidden="true"></i><NavLink to="#"> Returning customer? Click here to login</NavLink></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="billing-details-area">
                                <h2 className="cart-area-title">BILLING DETAILS</h2>
                                <form className="checkout-form-select2">
                                    <div className="row">
                                       
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="first-name">First Name *</label>
                                                <input type="text" id="first-name" className="form-control"/>
                                            </div>
                                        </div>
                                      
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="last-name">Last Name *</label>
                                                <input type="text" id="last-name" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                       
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="company-name">Company Name</label>
                                                <input type="text" id="company-name" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                      
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="email">E-mail Address *</label>
                                                <input type="text" id="email" className="form-control"/>
                                            </div>
                                        </div>
                                      
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="phone">Phone *</label>
                                                <input type="text" id="phone" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>                                  
                                    <div className="row">
                                      
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="country">Country</label>
                                                <div className="custom-select">
                                                    <select id="country" className='select2'>
                                                        <option value="0">Select your country</option>
                                                        <option value="1">Bangladesh</option>
                                                        <option value="2">Spain</option>
                                                        <option value="3">Belgium</option>
                                                        <option value="3">Ecuador</option>
                                                        <option value="3">Ghana</option>
                                                        <option value="3">South Africa</option>
                                                        <option value="3">India</option>
                                                        <option value="3">Pakistan</option>
                                                        <option value="3">Thailand</option>
                                                        <option value="3">Malaysia</option>
                                                        <option value="3">Italy</option>
                                                        <option value="3">Japan</option>
                                                        <option value="4">Germany</option>
                                                        <option value="5">USA</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                       
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                            <div className="form-group">
                                                <label className="control-label">Address</label>
                                                <input type="text" id="address-line1" className="form-control"/>
                                                <input type="text" id="address-line2" className="form-control"/>
                                            </div>
                                        </div>                                      
                                    </div>      
                                    <div className="row">
                                      
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="town-city">Town / City</label>
                                                <input type="text" id="town-city" className="form-control"/>
                                                
                                            </div>
                                        </div>                                      
                                    </div>  
                                    <div className="row">
                                       
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="district">District *</label>
                                                <div className="custom-select">
                                                    <select id="district" className='select2'>
                                                        <option value="0">Select Your District</option>
                                                        <option value="1">Dhaka</option>
                                                        <option value="2">Rajshahi</option>
                                                        <option value="4">Chittagong</option>
                                                        <option value="5">GAZIPUR</option>
                                                        <option value="6">GOPALGANJ</option>
                                                        <option value="7">JAMALPUR</option>
                                                        <option value="8">KISHOREGONJ</option>
                                                        <option value="9">MADARIPUR</option>
                                                        <option value="10">MANIKGANJ</option>
                                                        <option value="11">MUNSHIGANJ</option>
                                                        <option value="12">MYMENSINGH</option>
                                                        <option value="13">NARAYANGANJ</option>
                                                        <option value="14">NARSINGDI</option>
                                                        <option value="15">NETRAKONA</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">                                          
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="postcode">Postcode / ZIP</label>
                                                <input type="text" id="postcode" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="row">
                                      
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                                            <div className="form-group">
                                                <span className="checkbox"><input type="checkbox" name="remember"/>CREAT AN ACCOUNT?</span>  
                                            </div>
                                        </div>                                      
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="ship-to-another-area">
                                <h2 className="cart-area-title">SHIP TO A DIFFERENT ADDRESS?<span><input type="checkbox" name="remember"/></span></h2>
                                <label className="control-label">Order Notes</label>
                                <textarea className="form-control" style={{minWidth: "100%"}} defaultValue="Catatan untuk seller"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                            <div className="order-sheet">
                                <h2>Your Order</h2>
                                <ul>
                                    <li>01. Product Title<span>$59.00</span></li>
                                    <li>02. Product Title<span>$59.00</span></li>
                                </ul>
                                <h3>Subtotal<span>$ 118.00</span></h3>
                                <h3>Total<span>$ 118.00</span></h3>
                            </div>
                        </div>                                      
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                            <div className="payment-option">
                                <div className="form-group">
                                    <span><input type="checkbox" name="remember"/>DIRECT BANK TRANSFER</span>
                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>    
                                </div>
                                <div className="form-group">
                                    <span><input type="checkbox" name="remember"/>CHECK PAYMENTS</span>
                                </div>
                                <div className="form-group">
                                    <span><input type="checkbox" name="remember"/>CASH ON DELIVERY</span>
                                </div>
                            </div>
                        </div>                                      
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">                                           
                            <div className="pLace-order">
                                <button className="btn-send-message disabled" type="submit" value="Login">PLace Order</button>
                            </div>
                        </div>                                      
                    </div>
                </div>
            </div>

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  return { userName, isLogin }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(Checkout))
// export default withRouter(Detail);
// export default withRouter(Checkout)
