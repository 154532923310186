const initialState = {
  loginberhasil: false,
  activeSearchCategorySearch: 'ALL ASSET'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'loginberhasilopen':
      return { ...state, loginberhasil: true }
    case 'loginberhasilclose':
      return { ...state, loginberhasil: false }
    case 'change_category_search':
      return { ...state, activeSearchCategorySearch: action.payload }
    default:
      return state
  }
}
