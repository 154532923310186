import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SearchInputHome from './SearchInputHome';
class SearchInput extends Component {
  constructor() {
    super();
    this.state = {
      numRand: 0,
      myrandombg:''
    };
  }
  handleSearch = () => {
    // alert(this.props.vall)
    if (this.props.vall) {
      this.props.history.push("search/" + this.props.vall);
    } else {
      // console.log('none')
    }
  };

  componentDidMount(){
    this.handleRandom()
  }

  handleRandom = async () => {
   // this.setState({ numRand: Math.ceil(Math.random() * 3) });
   try {
    const response = await fetch(`${global.apiUrl}image/randombg`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.success == true) {
      this.setState({ myrandombg: data.cover });
      // console.log(data.detail)
    } else {
    }
  } catch (e) {
    console.log("error: ", e);
  }
  };

  handlePress = (e) => {
    if (typeof this.props.silahkanReloadLagi === 'function') {
      this.props.silahkanReloadLagi(e)
    }
  }

  render() {
    return (
      <div
        className="inner-banner-area"
        style={{
          backgroundImage: `url(${this.state.myrandombg})`,
          paddingTop: 40,
          paddingBottom:80
        }}
      >
        <div className="container">
          <div className="inner-banner-wrapper">
            {/* <p className="homeheadertext">Download what you want, cancel when you want</p> */}
            <div className="col-md-12 col-xs-12 search-input-center-maker" style={{}}>
            <SearchInputHome
               silahkanReload={(tagid)=>this.handlePress(tagid)}
            />
            </div>
            {/* <div className="banner-search-area input-group">
              <input
                onChange={this.props.onChange}
                className="form-control"
                placeholder="Search Here"
                type="text"
                id="inputan"
              />
              <span className="input-group-addon">
                <button type="submit" onClick={this.handleSearch}>
                  <span className="glyphicon glyphicon-search" />
                </button>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchInput);
/*
 <div
        className="inner-banner-area"
        style={{
          backgroundImage: `url(${this.state.myrandombg})`,
          paddingTop: 40,
          paddingBottom:80
        }}
      >
        <div className="container">
          <div className="inner-banner-wrapper">
            <p className="homeheadertext">Download what you want, cancel when you want</p>
            <div className="col-md-12 col-xs-12" style={{}}>
            <SearchInputHome
               silahkanReload={(tagid)=>this.handlePress(tagid)}
            />
            </div>
            {/* <div className="banner-search-area input-group">
              <input
                onChange={this.props.onChange}
                className="form-control"
                placeholder="Search Here"
                type="text"
                id="inputan"
              />
              <span className="input-group-addon">
                <button type="submit" onClick={this.handleSearch}>
                  <span className="glyphicon glyphicon-search" />
                </button>
              </span>
            </div>
            </div>
            </div>
          </div>
*/