export const login = (email, password) => {
  return async dispatch => {
    try {
      const response = await fetch(`${global.apiUrl}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
      const data = await response.json()
      if (data.success == true) {
        console.log(data)
        dispatch(loginsuccess(data))
        window.location.href='/'
       
      } else {
        dispatch({type:'open_swal_login'})
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }
}

export const getProfile = token => {
  return async dispatch => {
    try {
      fetch(global.apiUrl + 'profile/detail', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => response.json())
        .then(data => {
         //console.log(data)
          if (data.success == false) {
            dispatch(dummyLogout())
          }
        })
    } catch (e) {
      console.log('error: ', e)
    }
  }
}

export const registervariable = ({ prop, value }) => {
  return {
    type: 'register_variable',
    payload: { prop, value }
  }
}
export const updatevariable = ({ prop, value }) => {
  return {
    type: 'update_variable',
    payload: { prop, value }
  }
}

export const register = datareg => {
  // console.log(datareg)
  return async dispatch => {
    try {
      const response = await fetch(`${global.apiUrl}auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userName: datareg.nama,
          userNomorPegawai: datareg.nomorpegawai,
          userEmail: datareg.email,
          userPassword: datareg.password,
          userFullName: datareg.nama
        })
      })
      const data = await response.json()
      if (data.success == true) {
        dispatch({ type: 'register_success' })
        dispatch({ type: 'open_swal_register' })
      } else {
        dispatch({ type: 'open_swal_register_failed' })
      }
    } catch (e) {
      console.log('error: ', e)
    }
  }
}

export const closeswalregister = () => {
  return dispatch => {
    dispatch({ type: 'close_swal_register' })
  }
}

export const closeswallogin = () => {
  return dispatch => {
    dispatch({ type: 'close_swal_login' })
  }
}

export const loginsuccess = dt => {
  return dispatch => {
    dispatch({ type: 'login_success', payload: dt })
    // setTimeout(()=>{dispatch({type:'loginberhasilopen'})},1500)
    dispatch({type:'loginberhasilopen'})
    setTimeout(()=>{dispatch({type:'loginberhasilclose'})},1500)
  }
}
export const closeloginsuccess = () => {
  return dispatch => {
    dispatch({ type: 'loginberhasilclose' })
  }
}

export const dummyLogin = () => {
  return dispatch => {
    dispatch({ type: 'dummy_login' })
  }
}

export const dummyLogout = () => {
  return dispatch => {
    dispatch({ type: 'dummy_logout' })
  }
}

export const inputcart = (data) => {
	return (dispatch) => {
		dispatch({ type: 'input_cart', payload: JSON.parse(data) })
	}
}

export const removeone = (data, one) => {
	return (dispatch) => {
		let items = []
		let carts = JSON.parse(data)
		let oneitem = JSON.parse(one)
		for (var i = 0; i < carts.length; i++) {
			if (JSON.stringify(carts[i]) !== JSON.stringify(oneitem)) {
				items.push(carts[i]);
			}
		}
		dispatch({ type: 'remove_one', payload: items })
	}
}

export const emptycart = () => {
	return (dispatch) => {
		dispatch({ type: 'empty_cart' })
	}
}

export const change_category_search = (data) => {
	return (dispatch) => {
		dispatch({ type: 'change_category_search', payload: data })
	}
}




/*

export const loginUser = (props,data)=>{
	return(dispatch)=>{
		axios.post(apiUrl+'auth/login',data).then((res)=>{
		if(res.data.success == true){
			localStorage.setItem('myName',res.data.username);//dispatch({type:'getProfile',payload:res.data.username})
			localStorage.setItem('authToken',res.data.token);//to get localStorage.getItem('authToken')//
			localStorage.setItem('myPict',res.data.userPict)
			//props.history.push('/dashboard')
		    window.location.href='/'
		    }else{
			dispatch({type:'failLogin',payload:res.data.message})
		 }
       })
	}
}

export const fetchDriver = ()=>{
	return(dispatch)=>{
		axios.get(apiUrl+'driver',{
          headers:{
            Authorization:'Bearer '+localStorage.getItem('authToken')
          }
        }).then((res)=>{//http://api.enjoypeoples.com/driver
			dispatch({type:'getDriver',payload:res.data.data})
		})
	}
}

axios.post(apiUrl+'auth/login',data).then((res)=>{
		if(res.data.success == true){
			dispatch({type:'getProfile',payload:res.data.username})
			localStorage.setItem('authToken',res.data.token);//to get localStorage.getItem('authToken')//
			window.location.href = 'dashboard';
		}else{
			return
		}

       })

export const fetchGroup = ()=>{
	return(dispatch)=>{
		axios.get(apiUrl+'group',{
          headers:{
            Authorization:'Bearer '+localStorage.getItem('authToken')
          }
        }).then((res)=>{//http://api.enjoypeoples.com/driver
			dispatch({type:'getGroup',payload:res.data.data})
		})
	}
}

export const fetchUnit = ()=>{
	return(dispatch)=>{
		axios.get(apiUrl+'unit',{
          headers:{
            Authorization:'Bearer '+localStorage.getItem('authToken')
          }
        }).then((res)=>{//http://api.enjoypeoples.com/driver
			dispatch({type:'getUnit',payload:res.data.data})
		})
	}
}

export const fetchIcon = ()=>{
	return(dispatch)=>{
		axios.get(apiUrl+'icon',{
          headers:{
            Authorization:'Bearer '+localStorage.getItem('authToken')
          }
        }).then((res)=>{//http://api.enjoypeoples.com/driver
			dispatch({type:'getIcon',payload:res.data.data})
		})
	}
}

export const fetchUser = ()=>{
	return(dispatch)=>{
		axios.get(apiUrl+'user',{
          headers:{
            Authorization:'Bearer '+localStorage.getItem('authToken')
          }
        }).then((res)=>{//http://api.enjoypeoples.com/driver
			dispatch({type:'getUser',payload:res.data.data})
		})
	}
}
*/
