import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import {
  change_category_search
} from "../redux/actions";
import { connect } from "react-redux";

const getSuggestionValue = suggestion => suggestion.arsiTitle;

const renderSuggestion = suggestion => <div>{suggestion.arsiTitle}</div>;

class SearchInputHome extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: [],
      tagsAll: [],
      mainCategory:''
    };
  }

  componentDidMount() {
    // this.fetchAllTags();
    // console.log(this.props.location.pathname);
  }

  handleChangeMainCategory = (mainCategory) => {
    this.setState({mainCategory})
    this.props.change_category_search(mainCategory)
  }

  getSuggestions = async value => {
    try {
      const inputValue = await value.trim().toLowerCase();
      const inputLength = await inputValue.length;

      const response = await fetch(
        `${global.apiUrl}image/suggestforsearch?s=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        // this.setState({ listImage: data.detail });
        console.log(data.detail)

        return inputLength === 0
          ? []
          : data.detail.filter(
              lang =>
                lang.arsiTitle.toLowerCase().slice(0, inputLength) ===
                inputValue
            );
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }

    // return inputLength === 0
    //   ? []
    //   : languages.filter(
    //     lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
    //   )
  };

  //= =============== auto suggest
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    // console.log(value)
    const anu = await this.getSuggestions(value);
    this.setState({
      suggestions: anu,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onKeyDown = (event)=>{
    if (event.key === 'Enter') {
      //alert('Enter pressed!')
      this.handleSearch()
    }
  }

  //= =============== end auto suggest
  handleSearch = () => {
    // alert(this.props.vall)
    if (this.state.value) {
      this.props.history.push("search/" + this.state.value);
      // this.props.history.push({
      //   pathname: 'search/'+ this.state.value,
      //   state: { catpassing: this.state.mainCategory }
      // });
    } else {
      // console.log('none')
    }
  };

  fetchAllTags = async () => {
    try {
      const response = await fetch(`${global.apiUrl}image/alltags`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success == true) {
        this.setState({ tagsAll: data.data });
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleClickTags = async () => {
    alert("hall");
  };

  handlePress = e => {
    if (typeof this.props.silahkanReload === "function") {
      this.props.silahkanReload(e);
    }
  };

  render() {
    // console.log(this.state.suggestions)
    // console.log(this.props.activeSearchCategorySearch)
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "SEARCH HERE",
      value,
      onChange: this.onChange,
      class: "myinputstyle col-md-12",
      onKeyDown: this.onKeyDown
    };

    return (
      <div
        className="col-md-12 container-input-search"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderRadius:5
        }}
      >
        {/* <div
        className="container-all-asset-text"
          style={{
            height: 50,
            // width: 190,
            backgroundColor: "rgb(2,65,130)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius:5,
            borderBottomLeftRadius:5
          }}
        >
          <span
            className="dropdown1"
            style={{
              borderRight: "1px solid gray",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button className="btn-all-asset" type="submit">
              <span className="all-asset-txt frutigerFont" style={{ padding: 18, color: "white" }}>
                {this.state.mainCategory !== '' ? this.state.mainCategory : 'ALL ASSET' }{" "}
                <span className="" style={{}}>
                  <span
                    className="fa fa-angle-down"
                    style={{ marginLeft: 20, fontSize: 20 }}
                  />
                </span>{" "}
              </span>
            </button> */}

            {/* <div
              className="dropdown1-content"
              style={{ cursor: "pointer", backgroundColor: "rgb(2,65,130)" }}
            >
             <div
                className="listTags frutigerFont"
                onClick={() => this.handleChangeMainCategory('ALL ASSET')}
                style={{ cursor: "pointer", color: "white" }}
              >
                ALL ASSET
              </div>
              <div
                className="listTags frutigerFont"
                onClick={() => this.handleChangeMainCategory('FOTO')}
                style={{ cursor: "pointer", color: "white" }}
              >
                FOTO
              </div>
              <div
                className="listTags frutigerFont"
                onClick={() => this.handleChangeMainCategory('VIDEO')}
                style={{ cursor: "pointer", color: "white" }}
              >
                VIDEO
              </div>
              <div
                className="listTags frutigerFont"
                onClick={() => this.handleChangeMainCategory('DESIGN')}
                style={{ cursor: "pointer", color: "white" }}
              >
                DESIGN
              </div> */}

              {
                //   this.state.tagsAll.map((r, i) => (
                //   <div
                //     key={i}
                //     style={{ cursor: "pointer" }}
                //   >
                //     <NavLink
                //       key={i}
                //       style={{ color: "grey" }}
                //       to={{
                //         pathname: "/photo",
                //         state: {
                //           image_tag: r.tagId,
                //           image_tag_name: r.tagName,
                //         },
                //       }}
                //     >
                //       {r.tagName}
                //     </NavLink>
                //   </div>
                // ))
              }
            {/* </div> */}
          {/* </span>
        </div> */}

        {/* <div style={{ backgroundColor: 'red' }}>
          <span className=''>
            <button type='submit' onClick={this.handleSearch}>
              <span className=''>Tags</span>
            </button>
          </span>
        </div> */}
        <div className="input-group col-md-6" style={{
          borderRadius:5
        }}>
          <Autosuggest
            // onChage={onChange(event, { newValue, method })}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
              suggestionsContainer: "suggestCon",
              suggestionsContainerOpen: "suggestCon",
              suggestionsList: "suggestionsListStyle",
              suggestion: "suggestion",
              suggestionHighlighted: "suggestionHighlighted",
            }}
          />
        </div>
        <div
          className="glassSearch"
          style={{  cursor: "pointer" }}
          onClick={this.handleSearch}
        >
          <span
            className="input-group-addon glassSearch2"
            style={{
              height: 50,
              // backgroundColor: "white",
              border: "none",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <button type="submit" onClick={this.handleSearch}>
              <span className="glyphicon glyphicon-search" />
            </button>
          </span>
        </div>
      </div>
    );
  }
}

// export default withRouter(SearchInputHome)

const mapStateToProps = state => {
  const { userName, isLogin, userid } = state.profile;
  const { activeSearchCategorySearch } = state.lo;
  return { userName, isLogin, userid, activeSearchCategorySearch };
};

export default connect(
  mapStateToProps,
  {change_category_search}
)(withRouter(SearchInputHome));

/*

<div className="banner-search-area input-group">
        <input
          onChange={this.props.onChange}
          className="form-control"
          id="inputan"
          placeholder="SEARCH HERE"
          type="text"
        />
        <span className="input-group-addon">
          <button type="submit" onClick={this.handleSearch}>
            <span className="glyphicon glyphicon-search" />
          </button>
        </span>
      </div>




              <div
          style={{
            height: 50,
            width: 150,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            className="dropdown1"
            style={{
              borderRight: "1px solid gray",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button type="submit" onClick={() => console.log("not")}>
              <span className="" style={{ padding: 20 }}>
                TAGS{" "}
                <span className="" style={{}}>
                  <span
                    className="glyphicon glyphicon-triangle-bottom"
                    style={{ marginLeft: 20, fontSize: 10 }}
                  />
                </span>{" "}
              </span>
            </button>

            <div className="dropdown1-content">
              {this.props.location.pathname == "/photo"
                ? this.state.tagsAll.map((r, i) => (
                    <div
                      className="listTags"
                      key={i}
                      onClick={()=>this.handlePress(r.tagId)}
                      style={{ cursor: "pointer" }}
                    >
                      {r.tagName}
                    </div>
                  ))
                : this.state.tagsAll.map((r, i) => (
                    <div key={i} className="listTags" style={{ cursor: "pointer" }}>
                      <NavLink
                        key={i}
                        style={{ color: "grey" }}
                        to={{
                          pathname: "/photo",
                          state: {
                            image_tag: r.tagId,
                            image_tag_name: r.tagName,
                          },
                        }}
                      >
                        {r.tagName}
                      </NavLink>
                    </div>
                  ))}
              {
                //   this.state.tagsAll.map((r, i) => (
                //   <div
                //     key={i}
                //     style={{ cursor: "pointer" }}
                //   >
                //     <NavLink
                //       key={i}
                //       style={{ color: "grey" }}
                //       to={{
                //         pathname: "/photo",
                //         state: {
                //           image_tag: r.tagId,
                //           image_tag_name: r.tagName,
                //         },
                //       }}
                //     >
                //       {r.tagName}
                //     </NavLink>
                //   </div>
                // ))
              }
            </div>
          </span>
        </div>

*/
