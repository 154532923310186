const initialState = {
  fnama: '',
  fnomorpegawai: '',
  fnomorhandphone: '',
  femail: '',
  fpassword: '',
  showSwalRegisterFailed: false,
  showSwalRegisterFailed2: false,
  showSwalLoginFailed: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'register_variable':
      return { ...state, [action.payload.prop]: action.payload.value }
    case 'open_swal_register':
      return { ...state, showSwalRegisterFailed: true }
    case 'close_swal_register':
      return {
        ...state,
        showSwalRegisterFailed: false,
        showSwalRegisterFailed2: false
      }
    case 'close_swal_login':
      return {
        ...state,
        showSwalLoginFailed: false
      }
    case 'open_swal_login':
      return {
        ...state,
        showSwalLoginFailed: true
      }
    case 'open_swal_register_failed':
      return { ...state, showSwalRegisterFailed2: true }
    case 'register_success':
      return {
        ...state,
        fnama: '',
        fnomorpegawai: '',
        fnomorhandphone: '',
        femail: '',
        fpassword: ''
      }

    default:
      return state
  }
}
