import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
class Setting extends Component {
  constructor () {
    super()
    this.state = {}
  }
  componentDidMount() {
    document.title = "Avinet | Setting"
    const script = document.createElement("script");
    script.setAttribute('id', 'forselect2');
    script.src = "/js/mainselect2.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.setAttribute('id', 'geser');
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.setAttribute("id", "naon");
    script3.src = "/js/main.js";
    script3.async = true;
    document.body.appendChild(script3);
}

componentWillUnmount(){
    var todelete = document.getElementById('forselect2');
    todelete.remove();

    var todelete2 = document.getElementById('geser');
    todelete2.remove();

    var todelete3 = document.getElementById('naon');
    todelete3.remove();
}
  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        <div className="inner-banner-area" style={{ backgroundImage: `url(/img/avinet/bg-asset-bl.png)` , paddingTop:80}}>
                    <div className="container">
                        <div className="inner-banner-wrapper">
                        <p>Download what you want, cancel when you want</p>
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                        </div>
                    </div>
                </div>
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><NavLink to="index.html">Home</NavLink><span> -</span></li>
                            <li>Settings</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            <div className="settings-page-area bg-secondary section-space-bottom">
                <div className="container">
                    <div className="row settings-wrapper">
                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            <ul className="settings-title">
                                <li className="active"><NavLink to="#Personal" data-toggle="tab" aria-expanded="false">Personal Information</NavLink></li>
                                {/* <li><NavLink to="#Profile" data-toggle="tab" aria-expanded="false">Profile</NavLink></li>
                                <li><NavLink to="#Badges" data-toggle="tab" aria-expanded="false">Badges</NavLink></li>
                                <li><NavLink to="#E-mail" data-toggle="tab" aria-expanded="false">E-mail Settings</NavLink></li>
                                <li><NavLink to="#Social" data-toggle="tab" aria-expanded="false">Social Network</NavLink></li> */}
                            </ul>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12"> 
                            <form className="form-horizontal" id="personal-info-form">
                                <div className="settings-details tab-content">
                                    <div className="tab-pane fade active in" id="Personal">
                                        <h2 className="title-section">Personal Information</h2>
                                        <div className="personal-info inner-page-padding"> 
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Nama</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="first-name" value={this.props.userName} type="text"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Nomor Pegawai</label>
                                                <div className="col-sm-9">
                                                    <input value={this.props.nomorpegawai}  className="form-control" id="last-name" type="text"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Nomor Handphone</label>
                                                <div className="col-sm-9">
                                                    <input value={this.props.phone}  className="form-control" id="company-name" type="text"/>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <label className="col-sm-3 control-label">Country</label>
                                                <div className="col-sm-9">
                                                    <div className="custom-select">
                                                        <select id="country" className='select2'>
                                                            <option value="0">Select your country</option>
                                                            <option value="1">Bangladesh</option>
                                                            <option value="2">Spain</option>
                                                            <option value="3">Belgium</option>
                                                            <option value="3">Ecuador</option>
                                                            <option value="3">Ghana</option>
                                                            <option value="3">South Africa</option>
                                                            <option value="3">India</option>
                                                            <option value="3">Pakistan</option>
                                                            <option value="3">Thailand</option>
                                                            <option value="3">Malaysia</option>
                                                            <option value="3">Italy</option>
                                                            <option value="3">Japan</option>
                                                            <option value="4">Germany</option>
                                                            <option value="5">USA</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Email</label>
                                                <div className="col-sm-9">
                                                    <input value={this.props.email}  className="form-control" id="address1" type="text"/>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <label className="col-sm-3 control-label">Address Line 2</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="address2" type="text"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">City</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="city" type="text"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">State / Province / Region</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="state" type="text"/>
                                                </div>
                                            </div>*/}
                                            <div className="form-group">
                                                {/* <label className="col-sm-3 control-label">Zip / Postal Code</label> */}
                                                <div className="col-sm-9">
                                                    {/* <input className="form-control" id="zip" type="text"/> */}
                                                    {/* <NavLink to="#" className="update-btn" id="login-update">Update</NavLink> */}
                                                </div>
                                            </div>                                         
                                        </div> 
                                    </div> 
                                    <div className="tab-pane fade" id="Profile">
                                        <h3 className="title-section">Public Profile</h3>
                                        <div className="public-profile inner-page-padding"> 
                                            <div className="public-profile-item"> 
                                                <div className="public-profile-title"> 
                                                    <h4>Avatar</h4>
                                                </div>
                                                <div className="public-profile-content"> 
                                                    <img className="img-responsive" src="/img/profile/avatar.jpg" alt="Avatar" />
                                                    <div className="file-title">Upload a new avatar:</div>
                                                    <div className="file-upload-area"><NavLink to="#">Choose File</NavLink>No File Choosen</div>
                                                    <div className="file-size">JPEG 80x80px</div>
                                                </div>
                                            </div> 
                                            <div className="public-profile-item"> 
                                                <div className="public-profile-title"> 
                                                    <h4>Banner Image</h4>
                                                </div>
                                                <div className="public-profile-content"> 
                                                    <img className="img-responsive" src="/img/profile/banner.jpg" alt="Avatar" />
                                                    <div className="file-title">Upload a new homepage image:</div>
                                                    <div className="file-upload-area"><NavLink to="#">Choose File</NavLink>No File Choosen</div>
                                                    <div className="file-size">JPEG 590x242</div>
                                                </div>
                                            </div> 
                                            <div className="public-profile-item"> 
                                                <div className="public-profile-title"> 
                                                    <h4>Show Your Country Name on Your Profile</h4>
                                                </div>
                                                <div className="public-profile-content"> 
                                                    <div className="radio radio-info radio-inline">
                                                        <input type="radio" id="inlineRadio1" value="option1" name="radioInline" checked />
                                                        <label for="inlineRadio1"> Yes</label>
                                                    </div>
                                                    <div className="radio radio-inline">
                                                        <input type="radio" id="inlineRadio2" value="option2" name="radioInline" />
                                                        <label for="inlineRadio2"> No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="public-profile-item"> 
                                                <div className="public-profile-title"> 
                                                    <h4>Profile Heading</h4>
                                                </div>
                                                <div className="public-profile-content"> 
                                                    <input className="profile-heading" type="text" value="" />
                                                    <div className="file-size">Appears on your profile page</div>
                                                </div>
                                            </div>
                                            <div className="public-profile-item"> 
                                                <div className="public-profile-title"> 
                                                    <h4>Profile Text</h4>
                                                </div>
                                                <div className="public-profile-content"> 
                                                    <textarea className="profile-heading" rows="5"></textarea>
                                                    <div className="file-size">Here's a refresher on how to add some HTML magic to your comment.</div>
                                                    <ul className="html-format">
                                                        <li>&lt;strong&gt;&lt;/strong&gt; to make things bold</li>
                                                        <li>&lt;em&gt;&lt;/em&gt; to emphasize</li>
                                                        <li>&lt;ul&gt;&lt;li&gt; or &lt;ol&gt;&lt;li&gt; to make lists</li>
                                                        <li>&lt;h3&gt; or &lt;h4&gt; to make headings</li>
                                                        <li>&lt;pre&gt;&lt;/pre&gt; for code blocks</li>
                                                        <li>&lt;code&gt;&lt;/code&gt; for a few words of code</li>
                                                        <li>&lt;a&gt;&lt;/a&gt; for links</li>
                                                        <li>&lt;img&gt; to paste in an image (it'll need to be hosted somewhere else though)</li>
                                                        <li>&lt;blockquote&gt;&lt;/blockquote&gt; to quote somebody</li>
                                                    </ul>
                                                    <NavLink to="#" className="update-btn" id="save">Save</NavLink>
                                                </div>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="tab-pane fade" id="Badges">
                                        <div className="inner-page-main-body">                               
                                            <div className="author-summery">
                                                <div className="single-item">
                                                    <div className="item-title">Country:</div>
                                                    <div className="item-details">England</div>                                       
                                                </div>
                                                <div className="single-item">
                                                    <div className="item-title">Member Since:</div>
                                                    <div className="item-details">December 26, 2014</div>                                       
                                                </div>
                                                <div className="single-item">
                                                    <div className="item-title">Author Rating:</div>
                                                    <div className="item-details">
                                                        <ul className="default-rating">
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li>(<span> 05</span> )</li>
                                                        </ul>
                                                    </div>                                       
                                                </div>
                                                <div className="single-item">
                                                    <div className="item-title">Total Sales:</div>
                                                    <div className="item-name">$ 5,030</div>                                       
                                                </div>
                                            </div>
                                        </div> 
                                        <h2 className="title-section">Your Badges</h2> 
                                        <div className="profile-badges inner-page-padding">
                                            <p>You Cahnge Your Badge Position</p>
                                            <ul className="badges-item">
                                                <li><img src="/img/profile/badges1.png" alt="badges" className="img-responsive"/></li>
                                                <li><img src="/img/profile/badges2.png" alt="badges" className="img-responsive"/></li>
                                                <li><img src="/img/profile/badges3.png" alt="badges" className="img-responsive"/></li>
                                                <li><img src="/img/profile/badges4.png" alt="badges" className="img-responsive"/></li>
                                                <li><img src="/img/profile/badges5.png" alt="badges" className="img-responsive"/></li>
                                            </ul>
                                            <NavLink to="#" className="update-btn" id="badge-save">Save</NavLink>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="E-mail">
                                        <h2 className="title-section">Email Settings</h2> 
                                        <div className="profile-email inner-page-padding">
                                            <div className="checkbox">
                                                <label><input type="checkbox" value=""/>Rating reminders</label>
                                                <p>Send an email reminding me to rate an item a week after purchase</p>
                                            </div>
                                            <div className="checkbox">
                                                <label><input type="checkbox" value=""/>Rating reminders</label>
                                                <p>Send an email reminding me to rate an item a week after purchase</p>
                                            </div>
                                            <div className="checkbox">
                                                <label><input type="checkbox" value="" />Rating reminders</label>
                                                <p>Send an email reminding me to rate an item a week after purchase</p>
                                            </div>
                                            <NavLink to="#" className="update-btn" id="email-setting-save">Save</NavLink>
                                        </div> 
                                    </div>
                                    <div className="tab-pane fade" id="Social">
                                        <h2 className="title-section">Your Social Networks</h2> 
                                        <div className="profile-social inner-page-padding">
                                             <ul className="social-item">
                                                <li>
                                                    <div className="social-item-img"><img src="/img/profile/social1.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li> 
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social2.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social3.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social4.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social5.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>                                         
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social6.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>
                                                 <li>
                                                    <div className="social-item-img"><img src="/img/profile/social7.jpg" alt="badges" className="img-responsive"/></div> 
                                                    <input className="profile-heading" placeholder="User Name" value="" type="text"/>
                                                </li>                                                                                    
                                            </ul>                                       
                                            <NavLink to="#" className="update-btn" id="social-save">Save</NavLink>
                                        </div> 
                                    </div>                                       
                                </div> 

                            </form> 
                        </div>  
                    </div>  
                </div>  
            </div> 

            
      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin, nomorpegawai, phone, email } = state.profile
  return { userName, isLogin ,nomorpegawai, phone, email}
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(Setting))

