import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom'
import Card269 from '../components/Card269';
import Card269Video from '../components/Card269Video';
import Card181 from '../components/Card181';
import {Animated} from "react-animated-css";
import SearchInput from '../components/SearchInput';
class ProductList extends Component {
  constructor () {
    super()
    this.state = {
      listImage: [],
      listVideo:[],
      keySearch:'',
      isAnimated:true,
      numRand:1,
      myrandombg:''
    }
  }

  // handleRandom = () => {
  //   this.setState({ numRand: Math.ceil(Math.random() * 3) });
  // };

  componentDidMount() {
    this.handleRandom()
    //this.setState({keySearch : this.props.match.params.key})
    this.fetchListImage()
    this.fetchListVideo()
    document.title = "Avinet | Product"
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({keySearch:this.props.match.params.key})

    const script2 = document.createElement("script");
    script2.setAttribute("id", "naon");
    script2.src = "/js/main.js";
    script2.async = true;
    document.body.appendChild(script2);
    // console.log(this.props.location.state.catpassing)
}

handleRandom = async () => {
  // this.setState({ numRand: Math.ceil(Math.random() * 3) });
  try {
   const response = await fetch(`${global.apiUrl}image/randombg`, {
     method: "GET",
     headers: {
       "Content-Type": "application/json",
     },
   });
   const data = await response.json();
   if (data.success == true) {
     this.setState({ myrandombg: data.cover });
     // console.log(data.detail)
   } else {
   }
 } catch (e) {
   console.log("error: ", e);
 }
 };

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();

    var todelete2 = document.getElementById('naon');
    todelete2.remove();
}

fetchListImage = async () => {
  try {
    if(this.props.activeSearchCategorySearch == 'FOTO' || this.props.activeSearchCategorySearch == 'ALL ASSET'){
      const response = await fetch(
        `${global.apiUrl}image/listimageforproductpage?uid=${this.props.userid}&s=${this.props.match.params.key}&c=${this.props.activeSearchCategorySearch}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listImage: data.detail });
      } else {
      }
    }
    
  } catch (e) {
    console.log("error: ", e);
  }
};

fetchListVideo = async () => {
  try {
    if(this.props.activeSearchCategorySearch == 'VIDEO' || this.props.activeSearchCategorySearch == 'ALL ASSET'){

      const response = await fetch(
        `${global.apiUrl}video/listvideo?uid=${this.props.userid}&s=${this.props.match.params.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({ listVideo: data.detail });
      } else {
      }

    }
    
  } catch (e) {
    console.log("error: ", e);
  }
};


fetchListImage2 = async () => {
 
  try {
    await this.setState({isAnimated:false})
    const response = await fetch(
      `${global.apiUrl}image/listimageforproductpage?uid=${this.props.userid}&s=${this.state.keySearch}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.success == true) {
      // this.setState({ listImage: data.detail });
      // this.setState({isAnimated:true})
     setTimeout(()=>{this.setState({ listImage: data.detail });},500)
      setTimeout(()=>{this.setState({isAnimated:true});},500)
    } else {
    }
  } catch (e) {
    console.log("error: ", e);
  }
};

fetchListImage22 = async () => {
 
  try {
    await this.setState({isAnimated:false})
    const response = await fetch(
      `${global.apiUrl}video/listvideo?uid=${this.props.userid}&s=${this.state.keySearch}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.success == true) {
      // this.setState({ listImage: data.detail });
      // this.setState({isAnimated:true})
     setTimeout(()=>{this.setState({ listVideo: data.detail });},500)
      setTimeout(()=>{this.setState({isAnimated:true});},500)
    } else {
    }
  } catch (e) {
    console.log("error: ", e);
  }
};

handleAfterLike = async iid => {
  const myArray = this.state.listImage;
  let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
  myArray[objIndex].youLike = !myArray[objIndex].youLike;
  myArray[objIndex].likeCount = myArray[objIndex].youLike
    ? myArray[objIndex].likeCount + 1
    : myArray[objIndex].likeCount - 1;
  this.setState({ listImage: myArray });
};

handleAfterLikeVideo = async iid => {
  const myArray = this.state.listVideo;
  let objIndex = myArray.findIndex(obj => obj.arsiId == iid);
  myArray[objIndex].youLike = !myArray[objIndex].youLike;
  myArray[objIndex].likeCount = myArray[objIndex].youLike
    ? myArray[objIndex].likeCount + 1
    : myArray[objIndex].likeCount - 1;
  this.setState({ listVideo: myArray });
};

  render () {
    return (
      <div>

<SearchInput />
         {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
        {/* <div
          className='inner-banner-area'
          style={{ backgroundImage: `url(${this.state.myrandombg })`, paddingTop:80}}
        >
          <div className='container'>
            <div className='inner-banner-wrapper'>
            <p>Download what you want, cancel when you want</p>
              <div className='banner-search-area input-group'>
                <input
                  onChange={e => {
                    this.setState({ keySearch: e.target.value },this.fetchListImage2)
                    this.setState({ keySearch: e.target.value },this.fetchListImage22)
                  }}
                  className='form-control'
                  placeholder='Search Your Keywords . . .'
                  type='text'
                  value={this.state.keySearch}
                />
                <span className='input-group-addon'>
                  <button type='submit'>
                    <span className='glyphicon glyphicon-search' />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className='pagination-area bg-secondary'>
          <div className='container'>
            <div className='pagination-wrapper'>
              <ul>
                <li>
                  <NavLink to='index.html'>Home</NavLink>
                  <span> -</span>
                </li>
                <li>Product Category Grid</li>
              </ul>
            </div>
          </div>
        </div> */}


         <div className="category-product-grid bg-secondary section-space-bottom">                
                <div className="container">
                    <div className="inner-page-main-body">
                        <div className="page-controls">
                            <div className="row">
                                {/* <div className="col-lg-10 col-md-10 col-sm-10 col-xs-8"> */}
                                    {/* <div className="page-controls-sorting display-none-in-mobile">
                                        <div className="dropdown">
                                            <button className="btn sorting-btn dropdown-toggle" type="button" data-toggle="dropdown">Categories<i className="fa fa-angle-down" aria-hidden="true"></i></button>
                                            <ul className="product-categories-list dropdown-menu">
                                                <li><NavLink to="#">WordPress<span>(150)</span></NavLink></li>
                                                <li><NavLink to="#">Joomla <span>(100)</span></NavLink></li>
                                                <li><NavLink to="#">PSD<span>(50)</span></NavLink></li>
                                                <li><NavLink to="#">Plugins<span>(60)</span></NavLink></li>
                                                <li><NavLink to="#">Components<span>(40)</span></NavLink></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <div className="page-controls-sorting display-none-in-mobile">
                                        <div className="dropdown">
                                            <button className="btn sorting-btn dropdown-toggle" type="button" data-toggle="dropdown">Price Range<i className="fa fa-angle-down" aria-hidden="true"></i> </button>
                                            <ul className="dropdown-menu">
                                                <li><NavLink to="#">Date</NavLink></li>
                                                <li><NavLink to="#">Best Sale</NavLink></li>
                                                <li><NavLink to="#">Rating</NavLink></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <div className="page-controls-sorting">
                                        <div className="dropdown">
                                            <button className="btn sorting-btn dropdown-toggle" type="button" data-toggle="dropdown"> Sort by: New Items<i className="fa fa-sort" aria-hidden="true"></i></button>
                                            <ul className="dropdown-menu">
                                                <li><NavLink to="#">Date</NavLink></li>
                                                <li><NavLink to="#">Best Sale</NavLink></li>
                                                <li><NavLink to="#">Rating</NavLink></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                {/* </div> */}
                                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4">
                                    <div className="layout-switcher">
                                        <ul>
                                            <li className="active"><NavLink to="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></NavLink></li>
                                            <li><NavLink to="#list-view" data-toggle="tab" aria-expanded="true"><i className="fa fa-list"></i></NavLink></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane fade in active clear products-container" id="gried-view">
                                <div className="product-grid-view padding-narrow">
                                    <div className="row">
                                    {
                                      (this.state.listImage.length == 0 && this.state.listVideo.length == 0) &&  <div className="row"><h3 className="text-center" style={{color:'#89C453'}}>No Result</h3></div>     
                                    }              
                                                        
                                    <Animated animationIn="bounceInLeft" animationOut="bounceOutRight" isVisible={this.state.isAnimated}>
                                    {this.state.listImage.map((r, i) => {
                                      return (
                                        <Card269
                                          key={i}
                                          imgurl={r.cover}
                                          title={r.arsiTitle}
                                          catName={r.catName}
                                          author={r.author}
                                          download={r.download}
                                          like={r.likeCount}
                                          urldetail={`detail/${r.arsiId}`}
                                          imgurl={r.cover}
                                          toDetail={()=>this.props.history.push('detail/'+r.arsiId)}
                                          userPict={r.authorPict}
                                          imid={r.arsiId}
                                          usid={this.props.userid}
                                          actionAfterLike={()=>this.handleAfterLike(r.arsiId)}
                                          image_id={r.arsiCat}
                                          youLike={r.youLike}
                                        />
                                      );
                                    })}
                                     {this.state.listVideo.map((r, i) => {
                                      return (
                                        <Card269Video
                                          key={i}
                                          imgurl={r.cover}
                                          title={r.arsiTitle}
                                          catName={r.catName}
                                          author={r.author}
                                          download={r.download}
                                          like={r.likeCount}
                                          urldetail={`detail-video/${r.arsiId}`}
                                          imgurl={r.cover}
                                          toDetail={()=>this.props.history.push('detail-video/'+r.arsiId)}
                                          userPict={r.authorPict}
                                          imid={r.arsiId}
                                          usid={this.props.userid}
                                          actionAfterLike={()=>this.handleAfterLikeVideo(r.arsiId)}
                                          image_id={r.arsiCat}
                                          youLike={r.youLike}
                                        />
                                      );
                                    })}
                                  </Animated>

                                   
                                      
                                       {/* <Card269 /> */}
                                       
                                       
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ul className="pagination-align-center">
                                                <li className="active"><NavLink to="#">1</NavLink></li>
                                                <li><NavLink to="#">2</NavLink></li>
                                                <li><NavLink to="#">3</NavLink></li>
                                            </ul>
                                        </div>   */}
                                    </div>
                                </div>                                
                            </div>
                            <div role="tabpanel" className="tab-pane fade clear products-container" id="list-view">
                                <div className="product-list-view-style2">
                                    
                                    <Card181 />
                                    <Card181 />
                                    {/* <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ul className="pagination-align-center">
                                                <li className="active"><NavLink to="#">1</NavLink></li>
                                                <li><NavLink to="#">2</NavLink></li>
                                                <li><NavLink to="#">3</NavLink></li>
                                            </ul>
                                        </div>  
                                    </div> */}
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>


      </div>
    )
  }
}
const mapStateToProps = state => {
    const { userName, isLogin, userid } = state.profile
    const { activeSearchCategorySearch } = state.lo;
    return { userName, isLogin, userid, activeSearchCategorySearch }
}

export default connect(mapStateToProps,{})(withRouter(ProductList));
//export default withRouter(Detail);
// export default withRouter(ProductList)
