import React from "react";
import Home from "../pages/Home";
import Detail from "../pages/Detail";
import ProductList from "../pages/ProductList";
import ProductListCategory from "../pages/ProductListCategory";
import Profile from "../pages/Profile";
import About from "../pages/About";
import About2 from "../pages/About2";
import Register from "../pages/Register";
import Setting from "../pages/Setting";
import Login from "../pages/Login";
import Cart from "../pages/Cart";
import CheckOut from "../pages/CheckOut";
import Contact from "../pages/Contact";
import Favorite from "../pages/Favorite";
import Help from "../pages/Help";
import Page404 from "../pages/page404";
import Video from "../pages/Video";
import DetailVideo from "../pages/DetailVideo";
import ArsipImage from "../pages/ArsipImage";
import ArsipImage2 from "../pages/ArsipImage2";
import Design from '../pages/Design';
import FavoriteVideo from "../pages/FavoriteVideo";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";


class PrivateRoute2 extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return this.props.isLogin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      />
    );
  }
}
const mapStateToProps2 = state => {
  const { userName, isLogin } = state.profile;
  return { userName, isLogin };
};
PrivateRoute2 = connect(mapStateToProps2)(withRouter(PrivateRoute2));


const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <PrivateRoute2 exact path="/detail/:id" component={Detail} />

    <Route
      exact
      path="/tag/photo"
      render={props => <Redirect to={`/photo`} />}
    />

    <Route
      exact
      path="/search/search/:key"
      render={props => <Redirect to={`/search/${props.match.params.key}`} />}
    />

    <Route
      exact
      path="/search/search/search/:key"
      render={props => <Redirect to={`/search/${props.match.params.key}`} />}
    />

    <Route
      exact
      path="/detail/search/:key"
      render={props => <Redirect to={`/search/${props.match.params.key}`} />}
    />
    <Route
      exact
      path="/search/detail/:id"
      render={props => <Redirect to={`/detail/${props.match.params.id}`} />}
    />
    <Route
      exact
      path="/search/detail-video/:id"
      render={props => <Redirect to={`/detail-video/${props.match.params.id}`} />}
    />
     <Route
      exact
      path="/detail-video/search/:key"
      render={props => <Redirect to={`/search/${props.match.params.key}`} />}
    />

    <Route
      exact
      path="/detail/detail/:key"
      render={props => <Redirect to={`/detail/${props.match.params.key}`} />}
    />

    <PrivateRoute2 path="/photo" component={ProductListCategory} />
    <PrivateRoute2 exact path="/search/:key" component={ProductList} />
    <PrivateRoute2 path="/profile" component={Profile} />
    <PrivateRoute2 path="/about" component={About} />
    <PrivateRoute2 path="/about2" component={About2} />
    {/* <Route path="/login" component={Login} /> */}
    <PrivateRoute2 path="/setting" component={Setting} />
    <PrivateRoute2 path="/cart" component={Cart} />
    <PrivateRoute2 path="/checkout" component={CheckOut} />
    <PrivateRoute2 path="/contact" component={Contact} />
    <PrivateRoute2 path="/favorite" component={Favorite} />
    <PrivateRoute2 path="/favorite-video" component={FavoriteVideo} />
    <PrivateRoute2 path="/help" component={Help} />
    <PrivateRoute2 path="/video" component={Video} />
    <PrivateRoute2 exact path="/detail-video/:id" component={DetailVideo} />
    <PrivateRoute2 path="/arsip" component={Design} />
    <PrivateRoute2 path="/arsip2" component={ArsipImage2} />
    <PrivateRoute2 component={Page404} />
  </Switch>
);

export default Routes;
//path='/detail/search/:key'
/* <Route exact path='/search/detail/:id' component={Detail} /> */

// class PrivateRoute extends React.Component {
//   state = {
//     loaded: false,
//     isAuthenticated: false,
//   };

//   render() {
//     const { component: Component, ...rest } = this.props;
//     return (
//       <Route
//         {...rest}
//         render={props => {
//           return this.props.isLogin ? (
//             <Component {...props} />
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/",
//               }}
//             />
//           );
//         }}
//       />
//     );
//   }
// }
// const mapStateToProps = state => {
//   const { userName, isLogin } = state.profile;
//   return { userName, isLogin };
// };
// PrivateRoute = connect(mapStateToProps)(withRouter(PrivateRoute));