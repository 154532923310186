const initialState = {
  isLogin: false,
  userid: '',
  userName: '',
  nomorpegawai:'',
  phone:'',
  email: '',
  pict: '',
  token: '',
 
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'login_success':
      return {
        ...state,
        isLogin: true,
        userid: action.payload.id,
        userName: action.payload.name,
        email: action.payload.email,
        token: action.payload.token,
        pict:action.payload.pict,
        nomorpegawai:action.payload.nomorpegawai,
        phone:action.payload.phone,
      }
    case 'getProfile':
      return { ...state, userName: action.payload }
    case 'dummy_login':
      return { ...state, isLogin: true }
    case 'dummy_logout':
      return {
        ...state,
        isLogin: false,
        userid: '',
        userName: '',
        email: '',
        token: '',
        pict:'',
        nomorpegawai:'',
        phone:''
      }
    case 'update_variable':
      return { ...state, [action.payload.prop]: action.payload.value }

    default:
      return state
  }
}
