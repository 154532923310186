import React, { Component } from 'react'
import { connect } from 'react-redux'
import {  withRouter, NavLink } from 'react-router-dom'
import SearchInput from '../components/SearchInput';
class Page404 extends Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.setAttribute('id', 'geser');
    script.src = "/js/maingeser.js";
    script.async = true;
    document.body.appendChild(script);
}

componentWillUnmount(){
    var todelete = document.getElementById('geser');
    todelete.remove();
}

  render () {
    return (
      <div>
       {
          //!this.props.isLogin && <div style={{ marginTop: 80 }} />
        }
       
                            <SearchInput 
                                onChange={e => this.setState({ searchtext: e.target.value })}
                                vall={this.state.searchtext} />
                       
        
                <div className="pagination-area bg-secondary">
                <div className="container">
                    <div className="pagination-wrapper">
                        <ul>
                            <li><NavLink to="/">Home</NavLink><span> -</span></li>
                            <li>404 Error</li>
                        </ul>
                    </div>
                </div>  
            </div> 

            <div className="page-error-area bg-secondary section-space-bottom">
                <div className="container">
                    <h2 className="title-section">Error Page</h2>
                    <div className="page-error-top">
                        <img src="/img/404.png" alt="404" className="img-responsive" />
                        <p>Sorry Page Was Not Found</p>
                        <div className="page-error-bottom">
                            <p>The page you are looking is not available or has been removed.</p>
                            <p>Try going to Home Page by using the button below.</p>
                            <NavLink to="/" className="default-btn">Go To Home Page</NavLink>
                        </div>
                    </div>
                </div>
            </div>

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile
  return { userName, isLogin }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(Page404))
// export default withRouter(Detail);
// export default withRouter(Page404)
