import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import SearchInput from "../components/SearchInput";
import { updatevariable } from "../redux/actions";
import SweetAlert from "sweetalert2-react";
import ReactPlayer from "react-player";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listHistory: [],
      listHistoryVideo: [],
      divisi: "",
      totaldownload: "",
      since: "",
      nama: "",
      email: "",
      phone: "",
      successUpdate: false,
      totalPage: 0,
      activePage: 1,
      page: 1,
      showPassword: false,
      showConfirmPassword: false
    };
  }

  componentDidMount() {
    this.fetchListHistory();
    this.fetchListHistoryVideo();
    document.title = "Avinet | Profile";
    const script2 = document.createElement("script");
    script2.setAttribute("id", "geser");
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script = document.createElement("script");
    script.setAttribute("id", "naon");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }
  static getDerivedStateFromProps(props, state) {
    return {
      nama: props.userName,
      phone: props.phone,
      email: props.email,
    };
  }

  componentWillUnmount() {
    var todelete2 = document.getElementById("geser");
    todelete2.remove();

    var todelete = document.getElementById("naon");
    todelete.remove();
  }

  fetchListHistory = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}history/list?uid=${this.props.userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listHistory: data.detail,
          divisi: data.divisi,
          since: data.since,
          totaldownload: data.totalImageDownload,
          totalPage: data.pagetotal,
        });
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  fetchListHistoryVideo = async () => {
    try {
      const response = await fetch(
        `${global.apiUrl}history/listvideo?uid=${this.props.userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listHistoryVideo: data.detail,
          // divisi: data.divisi,
          // since: data.since,
          // totaldownload: data.totalImageDownload,
          // totalPage: data.pagetotal,
        });
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  handleUpdateProfile = async () => {
    if (this.np.value !== '' || this.cp.value !== '') {
      if (this.np.value == this.cp.value) {
        try {
          const response = await fetch(`${global.apiUrl}profile/update`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + this.props.token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userFullName: this.na.value,
              userEmail: this.em.value,
              userPhone: this.ph.value,
              password: this.cp.value,
            }),
          });
          const data = await response.json();
          if (data.success == true) {
            this.setState({ successUpdate: true });
            this.props.updatevariable({ prop: "userName", value: this.na.value });
            this.props.updatevariable({ prop: "phone", value: this.ph.value });
            this.props.updatevariable({ prop: "email", value: this.em.value });
          } else {
            alert("update data gagal");
          }
        } catch (e) {
          console.log("error: ", e);
        }
      } else {
        alert('Password dan Confirm Password harus sama')
      }
    } else {
      try {
        const response = await fetch(`${global.apiUrl}profile/update`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.props.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userFullName: this.na.value,
            userEmail: this.em.value,
            userPhone: this.ph.value,
            passwordbaru: this.cp.value
          }),
        });
        const data = await response.json();
        if (data.success == true) {
          this.setState({ successUpdate: true });
          this.props.updatevariable({ prop: "userName", value: this.na.value });
          this.props.updatevariable({ prop: "phone", value: this.ph.value });
          this.props.updatevariable({ prop: "email", value: this.em.value });
        } else {
          alert("update data gagal");
        }
      } catch (e) {
        console.log("error: ", e);
      }
    }

  };

  handleChangeNama = e => {
    this.setState({ nama: e.target.value });
  };
  //await this.setState({ page: page, activePage: page });
  handleSelectPage = async page => {
    try {
      await this.setState({ page: page, activePage: page });
      const response = await fetch(
        `${global.apiUrl}history/list?uid=${this.props.userid}&p=${
        this.state.page
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success == true) {
        this.setState({
          listHistory: data.detail,
          divisi: data.divisi,
          since: data.since,
          totaldownload: data.totalImageDownload,
        });
        // console.log(data.detail)
      } else {
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };

  render() {
    let pagePrev = this.state.page - 1;
    let curPage = this.state.page;
    let nextPage = this.state.page + 1;
    return (
      <div>

        <SearchInput
          onChange={e => this.setState({ searchtext: e.target.value })}
          vall={this.state.searchtext}
        />


        <div className="pagination-area bg-secondary">
          <div className="container">
            <div className="pagination-wrapper">
              <ul>
                <li>
                  <NavLink className="frutigerFont" to="/">Home</NavLink>
                  <span> -</span>&nbsp;
                </li>
                <li className="frutigerFont">My Profile</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="profile-page-area bg-secondary section-space-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 col-lg-push-3 col-md-push-4 col-sm-push-4">
                <div className="inner-page-main-body">
                  {/* <div
                    className="single-banner"
                    style={{
                      height: 332,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "cover",
                        // marginTop:-116
                      }}
                      src={this.props.pict}
                      alt="product"
                      className="img-responsive"
                    />
                  </div> */}
                  <div className="author-summery">
                    <div className="single-item">
                      <div className="item-title frutigerFont">Divisi:</div>
                      <div className="item-details frutigerFont">{this.state.divisi}</div>
                    </div>
                    <div className="single-item">
                      <div className="item-title frutigerFont">Member Since:</div>
                      <div className="item-details frutigerFont">{this.state.since}</div>
                    </div>
                    {/* <div className="single-item">
                                        <div className="item-title">Author Rating:</div>
                                        <div className="item-details">
                                            <ul className="default-rating">
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li>(<span> 05</span> )</li>
                                            </ul>
                                        </div>                                       
                                    </div> */}
                    <div className="single-item">
                      <div className="item-title frutigerFont">Total Download:</div>
                      <div className="item-name">
                        {this.state.totaldownload}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 col-lg-pull-9 col-md-pull-8 col-sm-pull-8">
                <div className="fox-sidebar">
                  <div className="sidebar-item">
                    <div className="sidebar-item-inner">
                      <h3 className="sidebar-item-title frutigerFont">Profile</h3>
                      <div className="sidebar-author-info">
                        <div
                          className="sidebar-author-img"
                          style={{
                            height: 63,
                            width: 73,
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={this.props.pict}
                            style={{
                              height: "100%",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                            alt="profile"
                            className="img-responsive"
                          />
                        </div>
                        <div className="sidebar-author-content">
                          <h3 className="frutigerFont">{this.props.userName}</h3>
                          {/* <NavLink to="#" className="view-profile"><i className="fa fa-circle" aria-hidden="true"></i>Online</NavLink> */}
                        </div>
                      </div>
                      {/* <ul className="sidebar-badges-item">
                                            <li><img src="/img/profile/badges1.png" alt="badges" className="img-responsive"/></li>
                                            <li><img src="/img/profile/badges2.png" alt="badges" className="img-responsive"/></li>
                                            <li><img src="/img/profile/badges3.png" alt="badges" className="img-responsive"/></li>
                                            <li><img src="/img/profile/badges4.png" alt="badges" className="img-responsive"/></li>
                                            <li><img src="/img/profile/badges5.png" alt="badges" className="img-responsive"/></li>
                                        </ul> */}
                    </div>
                  </div>
                  {/* <ul className="social-default">
                                    <li><NavLink to="#"><i className="fa fa-facebook" aria-hidden="true"></i></NavLink></li>
                                    <li><NavLink to="#"><i className="fa fa-twitter" aria-hidden="true"></i></NavLink></li>
                                    <li><NavLink to="#"><i className="fa fa-linkedin" aria-hidden="true"></i></NavLink></li>
                                    <li><NavLink to="#"><i className="fa fa-pinterest" aria-hidden="true"></i></NavLink></li>
                                </ul>                                 */}
                  {/* <ul className="sidebar-product-btn">
                                    <li><NavLink to="contact.html" className="buy-now-btn" id="buy-button"><i className="fa fa-envelope-o" aria-hidden="true"></i> Send Message</NavLink></li>
                                    <li><NavLink to="#" className="add-to-cart-btn" id="cart-button">Following</NavLink></li>
                                </ul> */}
                </div>
              </div>
            </div>
            <div className="row profile-wrapper">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <ul className="profile-title">
                  <li className="active">
                    <NavLink
                      className="frutigerFont"
                      to="#Products"
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <i className="fa fa-briefcase" aria-hidden="true" />
                      Setting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="frutigerFont"
                      to="#Profile"
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <i className="fa fa-image" aria-hidden="true" />Image History
                      Download
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="frutigerFont"
                      to="#video"
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <i className="fa fa-camera" aria-hidden="true" />Video History
                      Download
                    </NavLink>
                  </li>
                  {/* <li><NavLink to="#Products" data-toggle="tab" aria-expanded="false"><i className="fa fa-briefcase" aria-hidden="true"></i> Products ( 10 )</NavLink></li> */}
                  {/* <li><NavLink to="#Message" data-toggle="tab" aria-expanded="false"><i className="fa fa-envelope-o" aria-hidden="true"></i> Message Box</NavLink></li> */}
                  {/* <li><NavLink to="#Reviews" data-toggle="tab" aria-expanded="false"><i className="fa fa-comments-o" aria-hidden="true"></i> Customer Reviews ( 20 )</NavLink></li> */}
                  {/* <li><NavLink to="#Followers" data-toggle="tab" aria-expanded="false"><i className="fa fa-users" aria-hidden="true"></i> Followers (100 )</NavLink></li> */}
                </ul>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                <div className="tab-content">
                  <div className="tab-pane fade " id="Profile">
                    <h3 className="title-inner-section frutigerFont">Image Download History</h3>
                    <div className="inner-page-main-body">
                      <div className="row more-product-item-wrapper">
                        {this.state.listHistory.map((r, i) => (
                          <div
                            key={i}
                            className="col-lg-4 col-md-6 col-sm-4 col-xs-6"
                          >
                            <div className="more-product-item">
                              <div
                                className="more-product-item-img"
                                style={{
                                  height: 90,
                                  overflow: "hidden",
                                  backgroundColor: "white",
                                }}
                              >
                                <img
                                  src={r.cover}
                                  alt="product"
                                  style={{
                                    height: "100%",
                                    //cursor:'pointer',
                                    objectFit: "cover",
                                  }}
                                  className="img-responsive"
                                />
                              </div>
                              <div className="more-product-item-details">
                                <h4>
                                  <NavLink className="frutigerFont" to={`detail/${r.arsiId}`}>
                                    {r.arsiTitle}
                                  </NavLink>
                                </h4>
                                <NavLink
                                  className="frutigerFont"
                                  to={{
                                    pathname: "/photo",
                                    state: { image_id: r.arsiCat },
                                  }}
                                >
                                  <div className="p-title frutigerFont">{r.catName}</div>
                                </NavLink>
                                {/* <div className="p-price">$20</div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <ul className="pagination-align-left">
                                                    <li className="active"><NavLink to="#">1</NavLink></li>
                                                    <li><NavLink to="#">2</NavLink></li>
                                                    <li><NavLink to="#">3</NavLink></li>
                                                </ul>
                                            </div>  
                                        </div> */}
                    </div>
                    {/* <div className="inner-page-details inner-page-content-box">
                                        <h3>About Me:</h3>
                                        <p>Bimply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
                                        <h3>Skills:</h3>
                                        <div className="skill-area">
                                            
                                        </div>
                                    </div>  */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ul className="pagination-align-left">
                          {curPage - 1 >= 1 && (
                            <li className="active" style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(pagePrev)}
                              >
                                {pagePrev}
                              </NavLink>
                            </li>
                          )}

                          <li style={{ color: "red" }}>
                            <NavLink
                              to="#"
                              className="active"
                              style={{
                                color:
                                  curPage == this.state.activePage && "red",
                              }}
                              onClick={() => this.handleSelectPage(curPage)}
                            >
                              {curPage}
                            </NavLink>
                          </li>
                          {nextPage <= this.state.totalPage && (
                            <li>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(nextPage)}
                              >
                                {nextPage}
                              </NavLink>
                            </li>
                          )}
                          {/* <li className="active"><a href="#">1</a></li>
                                                    <li><a href="#">2</a></li>
                                                    <li><a href="#">3</a></li> */}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade active in" id="Products"
                  // style={{marginTop:-100}}
                  >
                    {/* <h3 className="title-inner-section frutigerFont">My Profile</h3> */}
                    <div className="inner-page-main-body">
                      <div className=" more-product-item-wrapper">
                        <form
                          className="form-horizontal"
                          id="personal-info-form"
                        >
                          <div className="settings-details tab-content">
                            <div
                              className="tab-pane fade active in"
                              id="Personal"
                            >
                              <h2 className="title-section frutigerFont">
                                Personal Information
                              </h2>
                              <div className="personal-info inner-page-padding">
                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Nama
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      ref={input => (this.na = input)}
                                      className="form-control frutigerFont"
                                      id=""
                                      defaultValue={this.state.nama}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Nomor Pegawai
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      disabled
                                      defaultValue={this.props.nomorpegawai}
                                      className="form-control frutigerFont"
                                      id="last-name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Nomor Handphone
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      ref={input => (this.ph = input)}
                                      defaultValue={this.state.phone}
                                      className="form-control frutigerFont"
                                      id="company-name"
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Email
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      ref={input => (this.em = input)}
                                      defaultValue={this.state.email}
                                      className="form-control frutigerFont"
                                      id="address1"
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Password Baru
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      ref={input => (this.np = input)}
                                      defaultValue={this.state.newpass}
                                      className="form-control frutigerFont"
                                      id="address1"
                                      // type="password"
                                      type={this.state.showPassword ? "text" : "password"}
                                    />
                                    <p onClick={()=>this.setState({showPassword:!this.state.showPassword})} 
                                    style={{cursor:'pointer' , margin:0, color:"#8bc34a"}}
                                    className="frutigerFont">{this.state.showPassword ? "Sembunyikan Password" : "Perlihatkan Password"}</p>
                                  </div>
                                </div>


                                <div className="form-group">
                                  <label
                                    className="col-sm-3 control-label frutigerFont"
                                    style={{ textAlign: "left" }}
                                  >
                                    Confirm Password
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      ref={input => (this.cp = input)}
                                      defaultValue={this.state.confirmpass}
                                      className="form-control frutigerFont"
                                      id="address1"
                                      type={this.state.showConfirmPassword ? "text" : "password"}
                                    />
                                     <p 
                                     style={{cursor:'pointer',color:"#8bc34a"}}
                                     onClick={()=>this.setState({showConfirmPassword:!this.state.showConfirmPassword})} 
                                     className="frutigerFont">{this.state.showConfirmPassword ? "Sembunyikan Confirm Password" : "Perlihatkan Confirm Password"}</p>
                                  </div>
                                 
                                </div>

                                <div className="form-group">
                                  {/* <label className="col-sm-3 control-label">Zip / Postal Code</label> */}
                                  <div className="col-sm-9">
                                    {/* <input className="form-control" id="zip" type="text"/> */}
                                    <NavLink
                                      to="#"
                                      onClick={this.handleUpdateProfile}
                                      className="update-btn frutigerFont"
                                      id="login-update"
                                    >
                                      Update
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="Profile">
                              <h3 className="title-section frutigerFont">Public Profile</h3>
                              <div className="public-profile inner-page-padding">
                                <div className="public-profile-item">
                                  <div className="public-profile-title">
                                    <h4>Avatar</h4>
                                  </div>
                                  <div className="public-profile-content">
                                    <img
                                      className="img-responsive"
                                      src="/img/profile/avatar.jpg"
                                      alt="Avatar"
                                    />
                                    <div className="file-title">
                                      Upload a new avatar:
                                    </div>
                                    <div className="file-upload-area">
                                      <NavLink to="#">Choose File</NavLink>No
                                      File Choosen
                                    </div>
                                    <div className="file-size">
                                      JPEG 80x80px
                                    </div>
                                  </div>
                                </div>
                                <div className="public-profile-item">
                                  <div className="public-profile-title">
                                    <h4>Banner Image</h4>
                                  </div>
                                  <div className="public-profile-content">
                                    <img
                                      className="img-responsive"
                                      src="/img/profile/banner.jpg"
                                      alt="Avatar"
                                    />
                                    <div className="file-title">
                                      Upload a new homepage image:
                                    </div>
                                    <div className="file-upload-area">
                                      <NavLink to="#">Choose File</NavLink>No
                                      File Choosen
                                    </div>
                                    <div className="file-size">
                                      JPEG 590x242
                                    </div>
                                  </div>
                                </div>
                                <div className="public-profile-item">
                                  <div className="public-profile-title">
                                    <h4>
                                      Show Your Country Name on Your Profile
                                    </h4>
                                  </div>
                                  <div className="public-profile-content">
                                    <div className="radio radio-info radio-inline">
                                      {/* <input type="radio" id="inlineRadio1" value="option1" name="radioInline" checked /> */}
                                      <label htmlFor="inlineRadio1"> Yes</label>
                                    </div>
                                    <div className="radio radio-inline">
                                      <input
                                        type="radio"
                                        id="inlineRadio2"
                                        value="option2"
                                        name="radioInline"
                                      />
                                      <label htmlFor="inlineRadio2"> No</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="public-profile-item">
                                  <div className="public-profile-title">
                                    <h4>Profile Heading</h4>
                                  </div>
                                  <div className="public-profile-content">
                                    <input
                                      className="profile-heading"
                                      type="text"
                                    />
                                    <div className="file-size">
                                      Appears on your profile page
                                    </div>
                                  </div>
                                </div>
                                <div className="public-profile-item">
                                  <div className="public-profile-title">
                                    <h4>Profile Text</h4>
                                  </div>
                                  <div className="public-profile-content">
                                    <textarea
                                      className="profile-heading"
                                      rows="5"
                                    />
                                    <div className="file-size">
                                      Here's a refresher on how to add some HTML
                                      magic to your comment.
                                    </div>
                                    <ul className="html-format">
                                      <li>
                                        &lt;strong&gt;&lt;/strong&gt; to make
                                        things bold
                                      </li>
                                      <li>
                                        &lt;em&gt;&lt;/em&gt; to emphasize
                                      </li>
                                      <li>
                                        &lt;ul&gt;&lt;li&gt; or
                                        &lt;ol&gt;&lt;li&gt; to make lists
                                      </li>
                                      <li>
                                        &lt;h3&gt; or &lt;h4&gt; to make
                                        headings
                                      </li>
                                      <li>
                                        &lt;pre&gt;&lt;/pre&gt; for code blocks
                                      </li>
                                      <li>
                                        &lt;code&gt;&lt;/code&gt; for a few
                                        words of code
                                      </li>
                                      <li>&lt;a&gt;&lt;/a&gt; for links</li>
                                      <li>
                                        &lt;img&gt; to paste in an image (it'll
                                        need to be hosted somewhere else though)
                                      </li>
                                      <li>
                                        &lt;blockquote&gt;&lt;/blockquote&gt; to
                                        quote somebody
                                      </li>
                                    </ul>
                                    <NavLink
                                      to="#"
                                      className="update-btn"
                                      id="save"
                                    >
                                      Save
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="Badges">
                              <div className="inner-page-main-body">
                                <div className="author-summery">
                                  <div className="single-item">
                                    <div className="item-title">Country:</div>
                                    <div className="item-details">England</div>
                                  </div>
                                  <div className="single-item">
                                    <div className="item-title">
                                      Member Since:
                                    </div>
                                    <div className="item-details">
                                      December 26, 2014
                                    </div>
                                  </div>
                                  <div className="single-item">
                                    <div className="item-title">
                                      Author Rating:
                                    </div>
                                    <div className="item-details">
                                      <ul className="default-rating">
                                        <li>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </li>
                                        <li>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </li>
                                        <li>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </li>
                                        <li>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </li>
                                        <li>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </li>
                                        <li>
                                          (<span> 05</span> )
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="single-item">
                                    <div className="item-title">
                                      Total Sales:
                                    </div>
                                    <div className="item-name">$ 5,030</div>
                                  </div>
                                </div>
                              </div>
                              <h2 className="title-section">Your Badges</h2>
                              <div className="profile-badges inner-page-padding">
                                <p>You Cahnge Your Badge Position</p>
                                <ul className="badges-item">
                                  <li>
                                    <img
                                      src="/img/profile/badges1.png"
                                      alt="badges"
                                      className="img-responsive"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/img/profile/badges2.png"
                                      alt="badges"
                                      className="img-responsive"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/img/profile/badges3.png"
                                      alt="badges"
                                      className="img-responsive"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/img/profile/badges4.png"
                                      alt="badges"
                                      className="img-responsive"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="/img/profile/badges5.png"
                                      alt="badges"
                                      className="img-responsive"
                                    />
                                  </li>
                                </ul>
                                <NavLink
                                  to="#"
                                  className="update-btn"
                                  id="badge-save"
                                >
                                  Save
                                </NavLink>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="E-mail">
                              <h2 className="title-section">Email Settings</h2>
                              <div className="profile-email inner-page-padding">
                                <div className="checkbox">
                                  <label>
                                    <input type="checkbox" defaultValue="" />
                                    Rating reminders
                                  </label>
                                  <p>
                                    Send an email reminding me to rate an item a
                                    week after purchase
                                  </p>
                                </div>
                                <div className="checkbox">
                                  <label>
                                    <input type="checkbox" defaultValue="" />
                                    Rating reminders
                                  </label>
                                  <p>
                                    Send an email reminding me to rate an item a
                                    week after purchase
                                  </p>
                                </div>
                                <div className="checkbox">
                                  <label>
                                    <input type="checkbox" defaultValue="" />
                                    Rating reminders
                                  </label>
                                  <p>
                                    Send an email reminding me to rate an item a
                                    week after purchase
                                  </p>
                                </div>
                                <NavLink
                                  to="#"
                                  className="update-btn"
                                  id="email-setting-save"
                                >
                                  Save
                                </NavLink>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="Social">
                              <h2 className="title-section">
                                Your Social Networks
                              </h2>
                              <div className="profile-social inner-page-padding">
                                <ul className="social-item">
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social1.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social2.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social3.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social4.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social5.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social6.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                  <li>
                                    <div className="social-item-img">
                                      <img
                                        src="/img/profile/social7.jpg"
                                        alt="badges"
                                        className="img-responsive"
                                      />
                                    </div>
                                    <input
                                      className="profile-heading"
                                      placeholder="User Name"
                                      defaultValue=""
                                      type="text"
                                    />
                                  </li>
                                </ul>
                                <NavLink
                                  to="#"
                                  className="update-btn"
                                  id="social-save"
                                >
                                  Save
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                                                <div className="more-product-item">
                                                    <div className="more-product-item-img">
                                                        <img src="/img/product/more2.jpg" alt="product" className="img-responsive"/>
                                                    </div>
                                                    <div className="more-product-item-details">
                                                        <h4><NavLink to="#">Grand Ballet - Dance</NavLink></h4>
                                                        <div className="p-title">PSD Template</div>
                                                        <div className="p-price">$20</div>
                                                    </div>
                                                </div>
                                            </div> */}
                      </div>
                      {/* <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <ul className="pagination-align-left">
                                                    <li className="active"><NavLink to="#">1</NavLink></li>
                                                    <li><NavLink to="#">2</NavLink></li>
                                                    <li><NavLink to="#">3</NavLink></li>
                                                </ul>
                                            </div> 
                                        </div>  */}
                    </div>
                  </div>


                  <div className="tab-pane fade " id="video">
                    <h3 className="title-inner-section">Video Download History</h3>
                    <div className="inner-page-main-body">
                      <div className="row more-product-item-wrapper">
                        {this.state.listHistoryVideo.map((r, i) => (
                          <div
                            key={i}
                            className="col-lg-4 col-md-6 col-sm-4 col-xs-6"
                          >
                            <div className="more-product-item">
                              <div
                                className="more-product-item-img"
                                style={{
                                  //height: 90,
                                  overflow: "hidden",
                                  backgroundColor: "white",
                                }}
                              >
                                {/* <img
                                  src={r.cover}
                                  alt="product"
                                  style={{
                                    height: "100%",
                                    //cursor:'pointer',
                                    objectFit: "cover",
                                  }}
                                  className="img-responsive"
                                /> */}

                                <ReactPlayer
                                  width="100%"
                                  height="100%"
                                  url={r.cover}
                                // controls
                                // playing={this.props.isPlay}
                                />
                              </div>
                              <div className="more-product-item-details">
                                <h4>
                                  <NavLink to={`detail-video/${r.arsiId}`}>
                                    {r.arsiTitle}
                                  </NavLink>
                                </h4>
                                <NavLink
                                  to={{
                                    pathname: "/video",
                                    state: { image_id: r.arsiCat },
                                  }}
                                >
                                  <div className="p-title">{r.catName}</div>
                                </NavLink>
                                {/* <div className="p-price">$20</div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <ul className="pagination-align-left">
                                                    <li className="active"><NavLink to="#">1</NavLink></li>
                                                    <li><NavLink to="#">2</NavLink></li>
                                                    <li><NavLink to="#">3</NavLink></li>
                                                </ul>
                                            </div>  
                                        </div> */}
                    </div>
                    {/* <div className="inner-page-details inner-page-content-box">
                                        <h3>About Me:</h3>
                                        <p>Bimply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
                                        <h3>Skills:</h3>
                                        <div className="skill-area">
                                            
                                        </div>
                                    </div>  */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ul className="pagination-align-left">
                          {curPage - 1 >= 1 && (
                            <li className="active" style={{ color: "red" }}>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(pagePrev)}
                              >
                                {pagePrev}
                              </NavLink>
                            </li>
                          )}

                          <li style={{ color: "red" }}>
                            <NavLink
                              to="#"
                              className="active"
                              style={{
                                color:
                                  curPage == this.state.activePage && "red",
                              }}
                              onClick={() => this.handleSelectPage(curPage)}
                            >
                              {curPage}
                            </NavLink>
                          </li>
                          {nextPage <= this.state.totalPage && (
                            <li>
                              <NavLink
                                to="#"
                                onClick={() => this.handleSelectPage(nextPage)}
                              >
                                {nextPage}
                              </NavLink>
                            </li>
                          )}
                          {/* <li className="active"><a href="#">1</a></li>
                                                    <li><a href="#">2</a></li>
                                                    <li><a href="#">3</a></li> */}
                        </ul>
                      </div>
                    </div>
                  </div>




                  <div className="tab-pane fade" id="Products">
                    <h3 className="title-inner-section">My Products</h3>
                    <div className="inner-page-main-body">
                      <div className="row more-product-item-wrapper">
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/product/more2.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Grand Ballet - Dance</NavLink>
                              </h4>
                              <div className="p-title">PSD Template</div>
                              <div className="p-price">$20</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                            <li className="active">
                              <NavLink to="#">1</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">2</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">3</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="Message">
                    <h3 className="title-inner-section">Message Box</h3>
                    <div className="message-wrapper">
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/3.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>Richi Rose</h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled it to make a type specimen book
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/4.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>Richi Rose</h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled it to make a type specimen book
                            </p>
                          </div>
                        </div>
                        <div className="single-item-inner-author">
                          <img
                            src="/img/profile/5.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span> Author</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled it to make a type specimen book
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/6.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>Richi Rose</h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled it to make a type specimen book
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <ul className="pagination-profile-align-center">
                          <li className="active">
                            <NavLink to="#">1</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">2</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">3</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">4</NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="single-item-message">
                        <h3>Leave A Comment</h3>
                        <div className="leave-comments-message">
                          <img
                            src="/img/profile/5.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="leave-comments-box">
                            <textarea
                              placeholder="Write your comment here ...*"
                              className="textarea form-control"
                              name="message"
                            />
                            <button type="submit" className="update-btn">
                              Post Comment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="Reviews">
                    <h3 className="title-inner-section">
                      Customer Reviews ( 20 )
                    </h3>
                    <div className="reviews-wrapper">
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/3.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/4.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/5.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/6.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/7.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <div className="single-item-inner">
                          <img
                            src="/img/profile/8.jpg"
                            alt="profile"
                            className="img-responsive"
                          />
                          <div className="item-content">
                            <h4>
                              Richi Rose<span>WordPress</span>
                            </h4>
                            <span>2 days ago</span>
                            <p>
                              Tmply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the 1n printer took
                              a galley.
                            </p>
                            <div className="profile-rating">
                              <ul>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                                <li>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-item-message">
                        <ul className="pagination-profile-align-center">
                          <li className="active">
                            <NavLink to="#">1</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">2</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">3</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">4</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="Followers">
                    <h3 className="title-inner-section">Followers</h3>
                    <div className="inner-page-main-body">
                      <div className="row more-product-item-wrapper">
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/5.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Psdart</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/6.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">RadiusTheme</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/7.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Maxbox</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/8.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Dancty</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/9.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Austonea</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/10.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Branadom</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/11.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Grand Balle</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/12.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Akkas</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6">
                          <div className="more-product-item">
                            <div className="more-product-item-img">
                              <img
                                src="/img/profile/13.jpg"
                                alt="product"
                                className="img-responsive"
                              />
                            </div>
                            <div className="more-product-item-details">
                              <h4>
                                <NavLink to="#">Moinar ma</NavLink>
                              </h4>
                              <div className="a-item">516 Items</div>
                              <div className="a-followers">406 Followers</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <ul className="pagination-align-left">
                            <li className="active">
                              <NavLink to="#">1</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">2</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">3</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">4</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SweetAlert
          show={this.state.successUpdate}
          title="Berhasil"
          confirmButtonText="Close"
          type="success"
          text="Update Profile Berhasil"
          showCancelButton={false}
          onConfirm={() => this.setState({ successUpdate: false })}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    userName,
    isLogin,
    email,
    pict,
    userid,
    nomorpegawai,
    phone,
    token,
  } = state.profile;
  return { userName, isLogin, email, pict, userid, nomorpegawai, phone, token };
};

export default connect(
  mapStateToProps,
  { updatevariable }
)(withRouter(Profile));
