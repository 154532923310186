import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  dummyLogin,
  dummyLogout,
  login,
  getProfile,
  closeswallogin,
  closeloginsuccess,
} from "../redux/actions";
import SweetAlert from "sweetalert2-react";
import SearchInput from "../components/SearchInput";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      isLoginFailed: false,
      isSecure: true,
      formActive: "login",
    };
  }

  componentDidMount() {
    document.title = "Avinet | Login";
    const script = document.createElement("script");
    script.setAttribute("id", "forselect2");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.setAttribute("id", "geser");
    script2.src = "/js/maingeser.js";
    script2.async = true;
    document.body.appendChild(script2);
  }

  componentWillUnmount() {
    var todelete = document.getElementById("forselect2");
    todelete.remove();

    var todelete2 = document.getElementById("geser");
    todelete2.remove();
  }

  handleLogin = e => {
    e.preventDefault();
    // alert(this.state.email+" dan "+this.state.password)
    if (this.state.email !== "" && this.state.password !== "") {
      this.props.login(this.state.email, this.state.password);
    } else {
      this.setState({ isLoginFailed: true });
    }
  };

  handleSee = () => {
    this.setState({ isSecure: !this.state.isSecure });
  };

  handleSelectLogin = () => {
    this.setState({ formActive: "login" });
  };
  handleSelectRegister = () => {
    this.setState({ formActive: "register" });
  };

  render() {
    return (
      <div className="limiter">
        <div
          className="container-login100"
          style={{
            // backgroundImage: `url(/img/bg-01.jpg)`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display:'flex',
            flexDirection:'column'
          }}
        >
          <img
            className="img-responsive"
            style={{ height: 80,marginTop:-30 }}
            src="/img/avinet/logo.png"
            alt="logo"
          />
          <div className="privacy-policy-text">
          <p style={{
            //color:'#a5b7c6'
            color:'white',
            textAlign:'center'
            }}
            className="frutigerFont">Downloading from this website, you accept Terms and our Privacy policy</p></div>
          <div className="wrap-login100  p-b-50">
            <div
              style={{
                display: "flex",
                //justifyContent: "center",
                //alignItems: "center",
                //marginBottom: 8,
                // backgroundColor:'red',
                height: 100,
                flexDirection: "row",
              }}
            >
              {/* <img
                classNameName="img-responsive"
                style={{ height: 50, marginTop: -40 }}
                src="/img/avinet/logo.png"
                alt="logo"
              /> */}
              <div
                className="col-md-6 col-xs-6"
                onClick={this.handleSelectRegister}
                style={{
                  //flex:1,
                  display: "flex",
                  boxShadow:
                    this.state.formActive == "register"
                      ? ""
                      : "inset 0 0 30px rgba(34,34,34,.25)",
                  backgroundColor:
                    this.state.formActive == "register"
                      ? ""
                      : "rgba(248,250,251,.5)",
                  height: 55,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#89C453",
                    marginTop: 15,
                  }}
                  className="frutigerFont"
                >
                  Sign Up
                </p>
              </div>
              <div
                className="col-md-6 col-xs-6"
                onClick={this.handleSelectLogin}
                style={{
                  // flex:1,
                  cursor: "pointer",
                  display: "flex",
                  boxShadow:
                    this.state.formActive == "login"
                      ? ""
                      : "inset 0 0 30px rgba(34,34,34,.25)",
                  backgroundColor:
                    this.state.formActive == "login"
                      ? ""
                      : "rgba(248,250,251,.5)",

                  height: 55,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#89C453",
                    marginTop: 15,
                  }}
                  className="frutigerFont"
                >
                  Sign In
                </p>
              </div>
            </div>
            {this.state.formActive == "login" ? (
              <form className="login100-form validate-form">
                {/* <span className="login100-form-title p-b-33">Avinet</span> */}

                {/* <div className="wrap-input100 validate-input"> */}
                <div style={{ border: "solid #bac8d3 1px" }}>
                  <div
                    style={{
                      position: "absolute",
                      marginTop: 10,
                      marginLeft: 14,
                    }}
                  >
                    <i className="glyphicon glyphicon-user" />
                  </div>
                  <input
                  className="frutigerFont"
                    value={this.state.email}
                    onChange={e =>
                      this.setState(
                        { email: e.target.value, isLoginFailed: false },
                        this.props.closeswallogin()
                      )
                    }
                    style={{
                      marginLeft: 10,
                      height: 45,
                      marginLeft: 40,
                      borderLeft: "solid #bac8d3 1px",
                      paddingLeft: 5,
                    }}
                    // className="input100"
                    type="text"
                    name="email"
                    placeholder="No Pegawai"
                  />
                  {/* <span className="focus-input100-1" />
                <span className="focus-input100-2" /> */}
                </div>
                <div style={{ margin: 10 }} />
                {/* <div className="wrap-input100 rs1 validate-input"> */}
                <div
                  style={{
                    border: "solid #bac8d3 1px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      marginTop: 10,
                      marginLeft: 14,
                    }}
                  >
                    <i className="glyphicon glyphicon-lock" />
                  </div>
                  <input
                  className="frutigerFont"
                    value={this.state.password}
                    onChange={e =>
                      this.setState(
                        { password: e.target.value, isLoginFailed: false },
                        this.props.closeswallogin()
                      )
                    }
                    style={{
                      marginLeft: 10,
                      height: 45,
                      marginLeft: 40,
                      borderLeft: "solid #bac8d3 1px",
                      paddingLeft: 5,
                    }}
                    // className="input100"
                    type={this.state.isSecure ? "password" : "text"}
                    name="pass"
                    placeholder="Password"
                  />

                  {/* <span className="focus-input100-1" />
                <span className="focus-input100-2" /> */}
                  <span
                    onClick={this.handleSee}
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingRight: 30,
                    }}
                  >
                    <i
                      style={{
                        color: this.state.isSecure ? "black" : "grey",
                        cursor: "pointer",
                        fontSize: 20,
                      }}
                      className={
                        this.state.isSecure ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                    />
                  </span>
                </div>
                <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',marginTop:10}}>
                  <div className="col-md-12 col-xs-6" style={{display:'flex', flexDirection:'row'}}>
                    
                      <input type="checkbox" style={{marginRight:8,marginTop:5}}/>
                  
                    <span className="frutigerFont">Remember me</span>
                    </div>
                  <div className="col-md-12 col-xs-6"><span style={{cursor:'pointer'}} className="frutigerFont">Forgot your password</span></div>
                </div>
                {this.props.showSwalLoginFailed && (
                  <div
                    style={{
                      backgroundColor: "#FD9A8F",
                      borderRadius: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <p style={{ color: "white", marginTop: 10 }} className="frutigerFont">
                      Login Gagal, No Pegawai / Password Salah
                    </p>
                  </div>
                )}

                {this.state.isLoginFailed && (
                  <div
                    style={{
                      backgroundColor: "#FD9A8F",
                      borderRadius: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <p className="frutigerFont" style={{ color: "white", marginTop: 10 }}>
                      Data Harus Di isi Semua
                    </p>
                  </div>
                )}

                <div className="container-login100-form-btn m-t-20">
                  <button
                  className="frutigerFont"
                    onClick={this.handleLogin}
                    className="login100-form-btn"
                  >
                    <span className="frutigerFont">Login</span>
                  </button>
                </div>

                {/* <div className="text-center p-t-45 p-b-4">
              <span className="txt1">
                Forgot
              </span>
  
              <a href="#" className="txt2 hov1">
                Username / Password?
              </a>
            </div> */}

                {/* <div className="text-center">
              <span className="txt1">
                Create an account?
              </span>
  
              <a href="#" className="txt2 hov1">
                Sign up
              </a>
            </div> */}
              </form>
            ) : (
              <div style={{ height: 180,padding:30 }}>
                <form className="login100-form validate-form" />
                <p
                  className="frutigerFont"
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    color: "#89C453",
                    marginTop: 15,
                  }}
                >
                  Untuk dapat mengakses Avinet Media Library silahkan menghubungi Bagian Humas I Divisi Sekretariat Perusahaan Bank Rakyat Indonesia
                </p>
              </div>
            )}
          </div>
        </div>
        {/* <SweetAlert
          show={this.state.isLoginFailed}
          title="Login Gagal"
          confirmButtonText="Close"
          type="warning"
          text="Data Harus Disii Semua"
          showCancelButton={false}
          onConfirm={() => this.setState({ isLoginFailed: false })}
        />

        <SweetAlert
          show={this.props.showSwalLoginFailed}
          title="Login Gagal"
          confirmButtonText="Close"
          type="error"
          text="Nomor Pegawai / Password Salah"
          showCancelButton={false}
          onConfirm={() => this.props.closeswallogin()}
        /> */}
      </div>
    );
  }
} // this.props.showSwalRegisterFailed //style="background-image: url('images/bg-01.jpg');"
const mapStateToProps = state => {
  const { userName, isLogin } = state.profile;
  const {
    fnama,
    fnomorpegawai,
    fnomorhandphone,
    femail,
    fpassword,
    showSwalRegisterFailed,
    showSwalRegisterFailed2,
    showSwalLoginFailed,
  } = state.freg;
  return {
    userName,
    isLogin,
    fnama,
    fnomorpegawai,
    fnomorhandphone,
    femail,
    fpassword,
    showSwalRegisterFailed,
    showSwalRegisterFailed2,
    showSwalLoginFailed,
  };
};

export default connect(
  mapStateToProps,
  {
    dummyLogin,
    dummyLogout,
    login,
    getProfile,
    closeswallogin,
    closeloginsuccess,
  }
)(withRouter(Login));
// export default withRouter(Detail);
// export default withRouter(Register)
/*
 

 <div classNameName='limiter'>
        <div classNameName='container-login100'>
          <div classNameName='wrap-login100'>
            <form classNameName='login100-form validate-form'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: -50
                }}
              >
                <img
                  classNameName='img-responsive'
                  style={{ height: 50 }}
                  src='/img/avinet/logo.png'
                  alt='logo'
                />
              </div>

              <span classNameName='login100-form-title p-b-43' style={{color:'#8bc34a'}}>
                Silahkan Login
              </span>

              <div
                classNameName='wrap-input100 validate-input'
                data-validate='Valid email is required: ex@abc.xyz'
              >
                <input
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  classNameName='input100'
                  type='text'
                  name='email'
                />
                <span classNameName='focus-input100' />
                <span classNameName='label-input100'>No Pegawai</span>
              </div>

              <div
                classNameName='wrap-input100 validate-input'
                data-validate='Password is required'
              >
                <input
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  classNameName='input100'
                  type='password'
                  name='pass'
                />
                <span classNameName='focus-input100' />
                <span classNameName='label-input100'>Password</span>
              </div>

              <div classNameName='flex-sb-m w-full p-t-3 p-b-32'>
                {/* <div classNameName="contact100-form-checkbox">
							<input classNameName="input-checkbox100" id="ckb1" type="checkbox" name="remember-me"/>
							<label classNameName="label-checkbox100" htmlFor="ckb1">
								Remember me
							</label>
						</div> 

            <div>
             <a href="#" classNameName="txt1">
          Forgot Password?
        </a> 
          </div>
        </div>

        <div classNameName='container-login100-form-btn'>
          <button
            classNameName='login100-form-btn'
            onClick={this.handleLogin}
          >
            Login
          </button>
        </div>

         <div classNameName="text-center p-t-46 p-b-20">
      <span classNameName="txt2">
        or sign up using
      </span>
    </div> 

        {/* <div classNameName="login100-form-social flex-c-m">
      <a href="#" classNameName="login100-form-social-item flex-c-m bg1 m-r-5">
        <i classNameName="fa fa-facebook-f" aria-hidden="true"></i>
      </a>

      <a href="#" classNameName="login100-form-social-item flex-c-m bg2 m-r-5">
        <i classNameName="fa fa-twitter" aria-hidden="true"></i>
      </a>
    </div> 
      </form>

      <div
        classNameName='login100-more'
        style={{ backgroundImage: `url(/img/bg-01.jpg)` }}
      >
        <div classNameName='inner-banner-wrapper' style={{marginTop:200}}>
          <h3 style={{color:'white'}}>Download what you want, cancel when you want</h3>
        </div>
      </div>
    </div>
  </div>

  <SweetAlert
          show={this.state.isLoginFailed}
          title="Login Gagal"
          confirmButtonText="Close"
          type="warning"
          text='Data Harus Disii Semua'
          showCancelButton={false}
          onConfirm={()=>this.setState({isLoginFailed:false})}/>

  <SweetAlert
          show={this.props.showSwalLoginFailed}
          title="Login Gagal"
          confirmButtonText="Close"
          type="error"
          text='Nomor Pegawai / Password Salah'
          showCancelButton={false}
          onConfirm={()=>this.props.closeswallogin()}/>

</div>


*/
